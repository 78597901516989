import React, { useState, useContext } from "react";
import "./loantype.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@apollo/client";
//Components
import AddLoanType from "../Component/LoanType/AddLoanType/AddLoanType";
import LoanTypeAction from "../Component/LoanType/LoanTypeAction/LoanTypeAction";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
//Schema
import { GET_LOANTYPE_WITH_PAGINATION } from "../Schema/Loantype";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

export default function LoanType() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_LOANTYPE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: "",
      pagination: true,
    },
    onCompleted: ({ getLoanTypeByPagination }) => {
      setTableData(getLoanTypeByPagination);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("tableData::=>", tableData);

  return (
    <div className="loantype-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("loan type")}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row">
          <Stack direction="column" justifyContent="center" spacing={2}>
            <Button
              onClick={handleOpen}
              className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
              startIcon={<AddIcon className="icon-add" />}
            >
              {t("create")}
            </Button>
          </Stack>

          <AddLoanType
            open={open}
            handleClose={handleClose}
            setRefetch={refetch}
            dialogTitle="Create"
          />
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="left"
              >
                {t("loantype-name")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="left"
              >
                {t("note")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="left"
              ></TableCell>
            </TableHead>

            {loading ? (
              <TableHead className="header-row">
                <TableRow>
                  <TableCell
                    colSpan={5}
                    className={
                      language === "kh" ? "header-titleKh" : "header-titleEn"
                    }
                  >
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <>
                {tableData?.data?.length === 0 ? (
                  <TableHead className="header-row">
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        className={
                          language === "kh"
                            ? "header-titleKh"
                            : "header-titleEn"
                        }
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          height={260}
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableBody className="body">
                    {tableData?.data?.map((row, index) => (
                      <TableRow className="body-row" key={index}>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cell-startKh"
                              : "body-cell-startEn"
                          }
                        >
                          {index + tableData?.paginator?.slNo}-
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="left"
                        >
                          {row?.loan_type_name}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="left"
                        >
                          {row?.remark}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <LoanTypeAction editData={row} setRefetch={refetch} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
