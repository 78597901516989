import React, { useState, useEffect } from "react";
import "./loaninfo.scss";
import {
  Stack,
  Box,
  TableHead,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//Component
import LoadingPage from "../../Include/LoadingPage";
import EmptyData from "../../Include/EmptyData";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import LoanInfoRow from "./LoanInfoRow";

export default function LoanInfo({ tableData, loading }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <Box className="table-container">
      <TableContainer>
        <Table className="table" aria-label="simple table">
          <TableHead className="header-row">
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
              width="3%"
            >
              {t("no")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("date-released")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("amount")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("loan plan")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("payment-method")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("adjustment")}
            </TableCell>
            <TableCell
              className={
                language === "kh" ? "header-titleKh" : "header-titleEn"
              }
            >
              {t("loan-status")}
            </TableCell>
          </TableHead>

          {loading ? (
            <TableHead className="header-row">
              <TableRow>
                <TableCell
                  colSpan={8}
                  className={
                    language === "kh" ? "header-titleKh" : "header-titleEn"
                  }
                >
                  <LoadingPage />
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <>
              {tableData?.historyLoan?.length === 0 ? (
                <TableHead className="header-row">
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className={
                        language === "kh" ? "header-titleKh" : "header-titleEn"
                      }
                    >
                      <Stack
                        direction="column"
                        justifyContent="center"
                        height={260}
                      >
                        <EmptyData />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <TableBody component={Paper} className="body">
                  {tableData?.historyLoan?.map((row, index) => (
                    <TableRow key={index} className="body-row">
                      <LoanInfoRow loading={loading} row={row} index={index} />
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
