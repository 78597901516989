import React, { useState, useEffect, useContext } from "react";
import "./addloanplans.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { CREATE_LOANPLAN, UPDATE_LOANPLAN } from "../../../Schema/Loanplan";
//Components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function AddLoanPlans({
  open,
  handleClose,
  editData,
  dialogTitle,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);

  const [createLoanPlan] = useMutation(CREATE_LOANPLAN, {
    onCompleted: ({ createLoanPlan }) => {
      if (createLoanPlan?.isSuccess) {
        setAlert(true, "success", createLoanPlan?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", createLoanPlan?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const [updateLoanPlan] = useMutation(UPDATE_LOANPLAN, {
    onCompleted: ({ updateLoanPlan }) => {
      if (updateLoanPlan?.isSuccess) {
        setAlert(true, "success", updateLoanPlan?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateLoanPlan?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  //fromik
  const AddLoanPlan = Yup.object().shape({
    month: Yup.string().required("require!"),
    interest: Yup.string().required("require!"),
    over_due_penalty: Yup.string().required("require!"),
    type_of_payment_period: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      month: 0,
      interest: 0,
      over_due_penalty: 0,
      type_of_payment_period: "Month",
      remark: "",
    },

    validationSchema: AddLoanPlan,
    onSubmit: async (values) => {
      // console.log(values);
      setLoading(true);
      let newLoanPlan = {
        ...values,
      };
      if (dialogTitle === "Create") {
        createLoanPlan({
          variables: {
            input: {
              ...newLoanPlan,
            },
          },
        });
      } else {
        updateLoanPlan({
          variables: {
            updateLoanPlanId: editData?._id,
            input: {
              ...newLoanPlan,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("month", editData?.month);
      setFieldValue("interest", editData?.interest);
      setFieldValue("type_of_payment_period", editData?.type_of_payment_period);
      setFieldValue("over_due_penalty", editData?.over_due_penalty);
      setFieldValue("remark", editData?.remark);
    }
  }, [editData]);

  return (
    <Dialog
      open={open}
      className="create-laonplans"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {dialogTitle === "Create"
              ? t("create") + " " + t("loan plan")
              : t("update") + " " + t("loan plan")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("duration_type")}
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    size="small"
                    value={values?.type_of_payment_period}
                    onChange={(e) =>
                      setFieldValue("type_of_payment_period", e.target.value)
                    }
                  >
                    <MenuItem value={"Month"}>{t("months")}</MenuItem>
                    <MenuItem value={"Week"}>{t("week")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("duration")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Month"
                  {...getFieldProps("month")}
                  error={Boolean(touched.month && errors.month)}
                  helperText={touched.month && errors.month}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("interest")} {`(%)`}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="interest"
                  {...getFieldProps("interest")}
                  error={Boolean(touched.interest && errors.interest)}
                  helperText={touched.interest && errors.interest}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("over-due-penalty")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    {...getFieldProps("over_due_penalty")}
                    error={Boolean(
                      touched.over_due_penalty && errors.over_due_penalty
                    )}
                    helperText={
                      touched.over_due_penalty && errors.over_due_penalty
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("note")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="note"
                    {...getFieldProps("remark")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action">
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {dialogTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
