import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import { Box, Button, Slider, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import "./cropimagefile.scss";
import getCroppedImg from "./Crop";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
import GoglobalStoreage from "goglobalstoragenpm";

export default function CropImageFile({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setImageFile,
  setUploadHook,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  let goglobalStoreage = new GoglobalStoreage();

  React.useEffect(() => {
    goglobalStoreage.createClient(
      "63d72f0eb50e4d904c1a70cd",
      "X3FnTtFuFVlkcccJnQn0iA5reQhoxNkpZ9t4Lc2bcrC"
    );
  }, []);

  const newDate = moment(new Date()).format("MMdYYYY");

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // console.log("croppedArea", croppedArea)
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      setImageFile(file);
      setOpenCrop(false);
      //upload
      uploadImage(file);
    } catch (error) {
      console.log(error);
    }
  };

  //compress image in customer
  const uploadImage = async (file) => {
    if (!file) return;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    let newName = `${uuidv4()}${newDate}${file.name.split(".").pop()}`;
    var newFile = new File([compressedFile], `${newName}.png`, {
      type: "image/png",
    });

    goglobalStoreage.upload("loan_management", "loanImage", newFile, "");
    setUploadHook(
      `${process.env.REACT_APP_IMAGE_SERVER}fileName:${newName}.png${process.env.REACT_APP_IMAGE_URL}`
    );
  };

  return (
    <>
      <Box className="crop-image">
        <DialogContent
          dividers
          className="dialog-content"
          sx={{ minWidth: { sm: 500 } }}
        >
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
          <Box sx={{ width: "100%", mb: 1 }}>
            <Box>
              <Typography>Zoom: {zoomPercent(zoom)}</Typography>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            <Box>
              <Typography>Rotation: {rotation + "°"}</Typography>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={360}
                value={rotation}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<Cancel />}
              onClick={() => setOpenCrop(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={<CropIcon />}
              onClick={cropImage}
            >
              Crop
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </>
  );
}

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
