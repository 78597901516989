import { gql } from "@apollo/client";

export const CREATE_LOANTYPE = gql`
  mutation CreateLoanType($input: LoanTypeInput) {
    createLoanType(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_LOANTYPE_WITH_PAGINATION = gql`
  query GetLoanTypeByPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getLoanTypeByPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        loan_type_name
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_LOANTYPE = gql`
  mutation UpdateLoanType($updateLoanTypeId: ID, $input: LoanTypeInput) {
    updateLoanType(id: $updateLoanTypeId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_LOANTYPE = gql`
  mutation DeleteLoanType($deleteLoanTypeId: ID) {
    deleteLoanType(id: $deleteLoanTypeId) {
      isSuccess
      message
    }
  }
`;
