import React, { useContext, useState } from "react";
import "./loanlistaction.scss";
import { IconButton, Typography, Stack, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
//components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import FinishLoanlist from "../FinishLoanList/FinishLoanlist";
import AdjustmentLoan from "../AdjustmentLoan/AdjustmentLoan";
import SubSpendLoan from "./SupSpendLoan";
import UpdateComment from "./UpdateComment";

export default function LastLoanAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  // console.log(noneUserAccesse(["Admin"]))
  const [anchorEl, setAnchorEl] = useState(null);

  //Modal adjustment
  const [openAdjustment, setOpenAdjustment] = useState(false);
  const handleOpenAdjustment = () => setOpenAdjustment(true);
  const handleCloseAdjustment = () => setOpenAdjustment(false);

  //Modal subspend
  const [openSubSpend, setOpenSubSpend] = useState(false);
  const handleOpenSubSpend = () => setOpenSubSpend(true);
  const handleCloseSubSpend = () => setOpenSubSpend(false);

  //Modal subspend
  const [openFinish, setOpenFinish] = useState(false);
  const handleOpenFinish = () => setOpenFinish(true);
  const handleCloseFinish = () => setOpenFinish(false);

  //Modal comment
  const [openComment, setOpenComment] = useState(false);
  const handleOpenComment = () => setOpenComment(true);
  const handleCloseComment = () => setOpenComment(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("editData::", editData);

  return (
    <div>
      {editData?.active === "Suspended" ||
      editData?.active === "Completed" ||
      editData?.status === "Reject" ? null : (
        <IconButton onClick={handleClick}>
          <MoreVertIcon className="three-point" />
        </IconButton>
      )}
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        {editData?.payment_detail[0]?.paid === true &&
        editData?.adjustment?.amount_adjustment === null ? (
          <>
            <MenuItem
              onClick={() => {
                handleOpenAdjustment();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("adjustment-loan")}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOpenFinish();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <AssignmentTurnedInOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("finish-loan")}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOpenComment();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("comment")}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOpenSubSpend();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <RadioButtonCheckedOutlinedIcon className="icon-menu-delete" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("suspend")}
                </Typography>
              </Stack>
            </MenuItem>
          </>
        ) : editData?.payment_detail[0]?.paid === true &&
          editData?.adjustment?.amount_adjustment !== null ? (
          <>
            <MenuItem
              onClick={() => {
                handleOpenAdjustment();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("adjustment-loan")}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOpenFinish();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <AssignmentTurnedInOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("finish-loan")}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenComment();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("comment")}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenSubSpend();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <RadioButtonCheckedOutlinedIcon className="icon-menu-delete" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("suspend")}
                </Typography>
              </Stack>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                handleOpenComment();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="icon-menu-update" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("comment")}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenSubSpend();
                handleClose();
              }}
            >
              <Stack direction="row" spacing={1}>
                <RadioButtonCheckedOutlinedIcon className="icon-menu-delete" />
                <Typography
                  className={language === "kh" ? "text-menuKh" : "text-menuEn"}
                >
                  {t("suspend")}
                </Typography>
              </Stack>
            </MenuItem>
          </>
        )}
      </Menu>

      <SubSpendLoan
        open={openSubSpend}
        handleClose={handleCloseSubSpend}
        editData={editData}
        setRefetch={setRefetch}
      />

      <AdjustmentLoan
        open={openAdjustment}
        handleClose={handleCloseAdjustment}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UpdateComment
        open={openComment}
        handleClose={handleCloseComment}
        editData={editData}
        setRefetch={setRefetch}
      />

      <FinishLoanlist
        open={openFinish}
        handleClose={handleCloseFinish}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
