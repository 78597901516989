import { gql } from "@apollo/client";

export const GET_LOAN_TOTAL = gql`
  query GetLoanTotalByCustomer {
    getLoanTotalByCustomer {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_LOAN = gql`
  query GetTotalTypeOfLoan {
    getTotalTypeOfLoan {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_PAWN = gql`
  query GetTotalTypeOfPawn {
    getTotalTypeOfPawn {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_INSTALLMENT = gql`
  query GetTotalTypeOfInstallments {
    getTotalTypeOfInstallments {
      total_customer
      total_amount
    }
  }
`;

export const GET_REPORT_COSTMER_CHART = gql`
  query GetChartDashboard {
    getChartDashboard {
      customerChart {
        activeData
        difficultData
        lateData
      }
      loanChart {
        lateData
        activeData
        difficultData
      }
    }
  }
`;

export const GET_REPORT_CUSTOMER_PER_YEAR = gql`
  query Query($year: Int!) {
    getReportTypeOfLoanTotalCustomerByYear(year: $year)
  }
`;

export const GET_REPORT_LOAN_PER_YEAR = gql`
  query Query($year: Int!) {
    getReportTypeOfLoanByYear(year: $year)
  }
`;
