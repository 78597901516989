import React, { useState, useEffect, useRef } from "react";
import "./loanrequestdetails.scss";
import {
  Grid,
  Stack,
  Box,
  Link,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import LoanRequestAgreement from "./LoanRequestAgreement";
import LoanAgreement from "./LoanAgreement";
import EquipmentLoanAgreement from "./EquipmentLoanAgreement";
import DebtGauranteeAgreement from "./DebtGuaranteeAgreement";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function LoanRequestDetails({ tableData }) {
  const navigate = useNavigate();
  const [agreementType, setAgreementType] = useState("ពាក្យសុំខ្ចីចងការប្រាក់");

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  // console.log("tableData::", tableData?.status);

  const handleChange = (e) => {
    setAgreementType(e.target.value);
  };

  return (
    <div className="loan-request-details">
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {/* <Box className="slash" />
            <Stack direction="column" justifyContent="center" spacing={1}>
              <Link
                className="link-under"
                onClick={() => navigate("/loanlist")}
              >
                <Typography className="color">{t("loan list")}</Typography>
              </Link>
            </Stack>
            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography className="color">/ {t("loan request")}</Typography>
            </Stack> */}
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" justifyContent="right" spacing={1}>
              <Stack direction="column" justifyContent="center">
                <Typography className="filter-title">
                  {t("agreement type")} :
                </Typography>
              </Stack>
              <Box className="container-textField">
                <FormControl fullWidth className="text-field">
                  <Select
                    value={agreementType}
                    size="small"
                    onChange={handleChange}
                  >
                    <MenuItem value={"ពាក្យសុំខ្ចីចងការប្រាក់"}>
                      ពាក្យសុំខ្ចីចងការប្រាក់
                    </MenuItem>
                    <MenuItem value={"កិច្ចសន្យាខ្ចីប្រាក់"}>
                      កិច្ចសន្យាខ្ចីប្រាក់
                    </MenuItem>
                    <MenuItem value={"កិច្ចព្រមព្រៀងធានាបំណុល"}>
                      កិច្ចព្រមព្រៀងធានាបំណុល
                    </MenuItem>
                    <MenuItem value={"កិច្ចសន្យាដាក់ធានាប្លង់ដី"}>
                      កិច្ចសន្យាដាក់ធានាប្លង់ដី
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                className="btn-print"
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={handalePrint}
              >
                {t("print")}
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <div className="div-container-print">
            <div ref={componentRef}>
              {agreementType === "ពាក្យសុំខ្ចីចងការប្រាក់" ? (
                <LoanRequestAgreement tableData={tableData} />
              ) : agreementType === "កិច្ចសន្យាខ្ចីប្រាក់" ? (
                <LoanAgreement tableData={tableData} />
              ) : agreementType === "កិច្ចព្រមព្រៀងធានាបំណុល" ? (
                <DebtGauranteeAgreement tableData={tableData} />
              ) : (
                <EquipmentLoanAgreement tableData={tableData} />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
