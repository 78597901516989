import React, { useState, useEffect } from "react";
import "./customerinfo.scss";
import { Box, Grid, Typography } from "@mui/material";
//Component
import { getFormattedPhoneNum } from "../../../Function/GetKhmerNumber";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function BusinessInfo({ customerData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [companyPhone, setCompanyPhone] = useState();

  useEffect(() => {
    if (customerData?.phone_number_company) {
      setCompanyPhone(getFormattedPhoneNum(customerData?.phone_number_company));
    }
  }, [customerData?.phone_number_company]);

  return (
    <Box className="customer-info">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("position")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("monthly-income")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("salary-payment-date")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("company-name")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={"body-item"}>
                : {customerData?.positions}
              </Typography>
              <Typography className={"body-item"}>
                : $ {parseInt(customerData?.monthly_income).toFixed(2)}
              </Typography>
              <Typography className={"body-item"}>
                : ថ្ងៃទី {customerData?.salary_payment_date}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.company_name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("type-of-business")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("phone-number-company")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("address-company")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={"body-item"}>
                : {customerData?.type_of_business}
              </Typography>
              <Typography className={"body-item"}>: {companyPhone}</Typography>
              <Typography className={"body-item"}>
                : {customerData?.address_company}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
