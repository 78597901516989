import { gql } from "@apollo/client";

export const CREATE_CUSTOMERS = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_CUSTOMERS_PAGINATION = gql`
  query GetCustomerByPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getCustomerByPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        first_name
        last_name
        nickname
        latin_alphabet
        gender
        nationality
        ID_card_number
        passport_id
        family_status
        date_of_birth
        place_of_birth {
          villege
          commune
          district
          province
        }
        current_address {
          villege
          commune
          district
          province
        }
        phone_number
        facebook
        address_google
        customer_type
        image_src
        image_name
        reference_docs {
          document_name
          document_src
          key
        }
        company_name
        phone_number_company
        type_of_business
        address_company
        positions
        monthly_income
        salary_payment_date
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($updateCustomerId: ID, $input: CustomerInput) {
    updateCustomer(id: $updateCustomerId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($deleteCustomerId: ID!) {
    deleteCustomer(id: $deleteCustomerId) {
      isSuccess
      message
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query GetCustomerById($getCustomerByIdId: ID) {
    getCustomerById(id: $getCustomerByIdId) {
      _id
      first_name
      last_name
      nickname
      latin_alphabet
      gender
      nationality
      ID_card_number
      passport_id
      family_status
      date_of_birth
      place_of_birth {
        villege
        commune
        district
        province
      }
      current_address {
        villege
        commune
        district
        province
      }
      phone_number
      facebook
      address_google
      customer_type
      image_src
      image_name
      reference_docs {
        document_name
        document_src
        key
      }
      company_name
      phone_number_company
      type_of_business
      address_company
      positions
      monthly_income
      salary_payment_date
    }
  }
`;

export const GET_CUSTOMER_HISTORY = gql`
  query GetHistoryByCustomerId($getHistoryByCustomerIdId: ID) {
    getHistoryByCustomerId(id: $getHistoryByCustomerIdId) {
      costomer {
        _id
        first_name
        last_name
        nickname
        latin_alphabet
        gender
        nationality
        ID_card_number
        passport_id
        family_status
        date_of_birth
        place_of_birth {
          villege
          commune
          district
          province
        }
        current_address {
          villege
          commune
          district
          province
        }
        phone_number
        facebook
        address_google
        customer_type
        image_src
        image_name
        reference_docs {
          document_name
          document_src
          key
        }
        company_name
        phone_number_company
        type_of_business
        address_company
        positions
        monthly_income
        salary_payment_date
      }
      historyLoan {
        _id
        co_id {
          _id
          firsName
          lastName
          phone_number
          email
          role
          image_src
          image_name
          created_At
        }
        loan_id
        invoices_id
        loan_type {
          _id
          loan_type_name
          remark
        }
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        total_amount
        loan_amount_letters
        is_first_month
        last_payment_date
        first_month_date
        loan_date
        purpose
        status
        reason
        payment_method
        active
        type_of_loan
        payment_detail {
          _id
          number_no
          payment_date
          interest
          monthly_payment
          penalty
          date
          paid
          principal
          rest_of_money
          receipt_date
          late_number
          payment_status
          go_credit
          remark
          key
        }
        total_late
        total_adjustment_late
        remaining_amount
        amount_paid
        adjustment {
          payment_method
          loan_date
          is_first_month
          last_payment_date
          first_month_date
          interest_adjustment
          duration_adjustment
          amount_adjustment
          amount_adjustment_letter
          payment_term
          adjustment_payment {
            _id
            number_no
            payment_date
            interest
            monthly_payment
            penalty
            date
            paid
            principal
            rest_of_money
            receipt_date
            late_number
            payment_status
            go_credit
            remark
            key
          }
        }
        isDifficult
        purposeRejection
        installments {
          release_date
          co_id
          co_phone_number
          company_name
          product
        }
        payment_term
        comments {
          comment
          created_at
          update_at
        }
      }
    }
  }
`;
