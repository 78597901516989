import React, { useState, useEffect } from "react";
import "./customerdetail.scss";
import {
  Stack,
  Box,
  Button,
  Avatar,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
//Component
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import CustomerInfo from "./CustomerInfo";
import BusinessInfo from "./BusinessInfo";
import LoanInfo from "./LoanInfo";
import ReferenceInfo from "./ReferenceInfo";

export default function CustomerDetail({ tableData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const [tabTaype, setTabType] = useState("Loan-Info");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tableData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  let customerData = tableData?.customer_id;

  // console.log("customerData::", customerData);

  return (
    <div className="setup-customerdetails">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link
              className={language === "kh" ? "linkKh" : "linkEn"}
              onClick={() => navigate("/loanlist")}
            >
              {t("loan list")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              /
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("loanlist-details")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Stack className="profile-container" direction="row" spacing={4}>
            <Avatar
              alt="Remy Sharp"
              className="profile-image"
              src={`${customerData?.image_src}`}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className="custmer-name">
                  {customerData?.first_name + " " + customerData?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="custmer-info-text">
                  {customerData?.positions}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-text">
                  {t("nickname")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-item">
                  {customerData?.nickname}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-text">
                  {t("phone")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-item">
                  {customerData?.phone_number}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid container className="tabs-container">
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Loan-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Loan-Info")}
          >
            {t("loan-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Reference-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Reference-Info")}
          >
            {t("reference-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Personal-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Personal-Info")}
          >
            {t("personal-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Business-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Business-Info")}
          >
            {t("business-info")}
          </Button>
        </Grid>
      </Grid>

      {tabTaype === "Personal-Info" ? (
        <CustomerInfo customerData={customerData} />
      ) : tabTaype === "Business-Info" ? (
        <BusinessInfo customerData={customerData} />
      ) : tabTaype === "Reference-Info" ? (
        <ReferenceInfo customerData={customerData} />
      ) : (
        <LoanInfo tableData={tableData} loading={loading} />
      )}
    </div>
  );
}
