import React, { useEffect, useState } from "react";
import { Grid, Stack, Box } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { getKhmerNumber } from "../../Function/GetKhmerNumber";
import { getKhmerMonth, currencyFormat } from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";
import { GET_NEW_CUSTOMER_REPORT } from "../../Schema/Report";

export default function NewCustomerReport({ dateSelected }) {
  const [khmerDateString, setKhmerDateString] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_NEW_CUSTOMER_REPORT, {
    variables: {
      month: parseInt(moment(dateSelected).format("MM")),
      year: parseInt(moment(dateSelected).format("YYYY")),
    },
    onCompleted: ({ getNewCustomerReport }) => {
      setTableData(getNewCustomerReport);
      // console.log(getNewCustomerReport);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
  // console.log("dateSelected::", dateSelected);

  let totalRestOfMoney = tableData?.reduce(function (a, b) {
    return a + b.loan_amount;
  }, 0);

  // console.log(
  //   "getKhmerMonth::",
  //   getKhmerMonth(moment(dateSelected).format("M"))
  // );

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            របាយការណ៍អតិថិជនយកថ្មី
          </Box>
          <Box
            className="loan-footer-title"
            sx={{ marginTop: "10px" }}
          >{`សម្រាប់ខែ${getKhmerMonth(
            moment(dateSelected).format("M")
          )} ឆ្នាំ${getKhmerNumber(moment(dateSelected).format("YYYY"))}`}</Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th>ល.រ</th>
            <th>ឈ្មោះ</th>
            <th>លេខកូដ</th>
            <th>ប្រភេទកម្ចី</th>
            <th>ថ្ងៃកម្ចី</th>
            <th>ទឹកប្រាក់កម្ចី</th>
          </tr>

          {tableData?.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>
                {data?.first_name + " " + data?.last_name}
              </td>
              <td>{data?.loan_id}</td>
              <td>
                {data?.type_of_loan === "Loan"
                  ? "កម្ចី"
                  : data?.type_of_loan === "Pawn"
                  ? "បញ្ចាំ"
                  : "បង់រំលស់"}
              </td>
              <td>{moment(data?.loan_date).format("DD-MMM-YYYY")}</td>
              <td style={{ textAlign: "left" }}>
                {currencyFormat(data?.loan_amount)}
              </td>
            </tr>
          ))}

          <tr style={{ backgroundColor: "#eaeefa" }}>
            <td colSpan={5}>សរុប</td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              {currencyFormat(totalRestOfMoney)}
            </td>
          </tr>
        </table>
      </div>

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <div className="loan-footer-title">បានត្រួតពិនិត្យ និងឯកភាព</div>
          <div className="loan-footer-name">ជ.អ្នកគ្រប់គ្រង</div>
          <div className="loan-footer-name">អ្នកគ្រប់គ្រងសាច់ប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* ខូវ ស៊ូខេង */}
            .................................
          </div>
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>
          <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* អុន ដានី */}
            .................................
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
