import React from "react";
import { Stack, Box, Typography, Avatar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import "./emptydata.scss";


export default function EmptyData() {
    // Change Language
    const { language } = React.useContext(AuthContext);
    const { t } = translateLauguage(language);

  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Stack direction="column" justifyContent="center" height="100px">
          <Stack direction="row" justifyContent="center" sx={{mb:2}}>
            <Avatar src={ContentCopyIcon} alt="icon"/>
            {/* <Box sx={{backgroundColor:"#fff" , padding: "5px" , borderRadius:"50%"}}>
              <ContentCopyIcon />
            </Box> */}
          </Stack>
          <Typography className={language === "kh" ? "titleKh" :  "titleEn"}>{t("no-data")}</Typography>
          <Typography className={language === "kh" ? "subKh" :  "subEn"}>{t("your-collection-list-is-empty")}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
