import React, { useState } from "react";
import "./referencelist.scss";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { IconButton, TextField, Grid, Stack, Box, Button } from "@mui/material";
//upload image
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
//Src
import FileThumnail from "../../../Function/FileThumnail";
import folderImage from "../../../Assets/pdf.png";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import GoglobalStoreage from "goglobalstoragenpm";

export default function ReferenceList(props) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  let goglobalStoreage = new GoglobalStoreage();

  React.useEffect(() => {
    goglobalStoreage.createClient(
      "63d72f0eb50e4d904c1a70cd",
      "X3FnTtFuFVlkcccJnQn0iA5reQhoxNkpZ9t4Lc2bcrC"
    );
  }, []);

  const newDate = moment(new Date()).format("MMdYYYY");
  //compress image in customer
  const uploadImage = async (file, key) => {
    let newName = `${uuidv4()}${newDate}${file.name.split(" ").pop()}`;
    var newFile = new File([file], `${newName}.pdf`, {
      type: "file/pdf",
    });

    goglobalStoreage.upload("loan_management", "loanImage", newFile, "");

    props?.setUpdateDocument(
      `${process.env.REACT_APP_IMAGE_SERVER}fileName:${newName}.pdf${process.env.REACT_APP_IMAGE_URL}`,
      key
    );
  };

  const [touchedDocName, setToucheDocName] = useState(false);
  const handleTouchDocName = () => setToucheDocName(true);

  const items = props.items;
  const listItems = items?.map((item, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
        <Stack direction="column" spacing={1} className="reference-list">
          <Stack direction="row" justifyContent="center">
            <Box>
              <Stack direction="row" justifyContent="center">
                <IconButton onClick={() => props.deleteItem(item.key)}>
                  <DeleteRoundedIcon sx={{ color: "red" }} />
                </IconButton>
              </Stack>
              <Stack
                className="profile"
                direction="row"
                justifycontent="center"
              >
                <Button component="label">
                  <TextField
                    accept="application/pdf"
                    type="file"
                    id="image"
                    sx={{ display: "none" }}
                    onChange={(e) => uploadImage(e.target.files[0], item?.key)}
                  />
                  {item?.document_src ? (
                    <FileThumnail pdfFile={item?.document_src} />
                  ) : (
                    <img className="image" src={folderImage} />
                  )}
                  <input type="file" hidden />
                </Button>
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <TextField
              fullWidth
              className="text-field-name"
              id={item.key}
              size="small"
              placeholder={t("document-name")}
              value={item.qty}
              onChange={(e) =>
                props.setUpdateDocumentName(e.target.value, item.key)
              }
              onFocus={handleTouchDocName}
              error={
                (touchedDocName && item?.document_name === "") ||
                (props.checkListRequired && item?.document_name === "")
              }
              helperText={
                (touchedDocName && item?.document_name === "" && "require!") ||
                (props.checkListRequired &&
                  item?.document_name === "" &&
                  "require!")
              }
            />
          </Stack>
        </Stack>
      </Grid>
    );
  });

  return listItems;
}
