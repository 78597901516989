import React, { useEffect, useContext } from "react";
import "./updatepayment.scss";
import {
  IconButton,
  Typography,
  Stack,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "@apollo/client";
import moment from "moment";
//components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { UPDATE_LOAN_APPROVE_PAID } from "../../../Schema/Loanlist";
import { useState } from "react";

export default function UpdatePaidPayment({ tableData, editData, setRefetch }) {
  const { setAlert } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateSelected, setDateSelected] = useState(moment());

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [updateLoanApprovePaid] = useMutation(UPDATE_LOAN_APPROVE_PAID, {
    onCompleted: ({ updateLoanApprovePaid }) => {
      if (updateLoanApprovePaid?.isSuccess) {
        setAlert(true, "success", updateLoanApprovePaid?.message);
        setRefetch();
        setLoading(true);
      } else {
        setAlert(true, "error", updateLoanApprovePaid?.message);
        setLoading(true);
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
    },
  });

  const handleSubmit = () => {
    updateLoanApprovePaid({
      variables: {
        loanId: tableData?._id,
        paymentId: editData?._id,
        date: dateSelected,
      },
    });
    setOpenModal(false);
  };

  // console.log("editData::=>", editData);

  return (
    <div className="update-payment">
      {editData?.paid === true ? null : (
        <Button
          onClick={() => setOpenModal(true)}
          className="btn-paid"
          variant="contained"
        >
          Paid
        </Button>
      )}

      <Dialog open={openModal} className="loanlist-delete">
        <DialogTitle className="dialog-title">
          <Stack direction="row" spacing={2}>
            <Typography className="title">{t("paid-payment")}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => setOpenModal(false)}>
              <CloseIcon className="clear-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle1" className="simple-text">
              {t("please-select-date-to-paid")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={dateSelected}
                onChange={(e) => setDateSelected(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className="action">
                <Button className="btn-paid" fullWidth onClick={handleSubmit}>
                  {loading ? "Loading..." : "Pay now"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
