import React, { useContext, useState } from "react";
import "./chart.scss";
import { Grid, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { GET_REPORT_CUSTOMER_PER_YEAR } from "../../../Schema/Dashboard";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import moment from "moment";

export default function ChartCustomerMonth() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [tableData, setTableData] = useState([]);
  
  
  const { data } = useQuery(GET_REPORT_CUSTOMER_PER_YEAR, {
    variables: {
      year: parseInt(moment().utc().format("YYYY")),
    },
    onCompleted: ({ getReportTypeOfLoanTotalCustomerByYear }) => {
      setTableData(getReportTypeOfLoanTotalCustomerByYear);
      // console.log("getReportTypeOfLoanTotalCustomerByYear::", getReportTypeOfLoanTotalCustomerByYear);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log(tableData)

  const state = {
    series: [
      {
        name: t("loan"),
        data: tableData[0]
      },
      {
        name: t("pawn"),
        data: tableData[1],
      },
      {
        name: t("installment"),
        data: tableData[2],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          text: "(នាក់)",
        },
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <Stack className="chart">
      <Stack direction="row" spacing={2}></Stack>
      <Stack id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={300}
        />
      </Stack>
    </Stack>
  );
}
