import React, { useState, useContext, useEffect, useMemo } from "react";
import "./addcustomers.scss";
import Button from "@mui/material/Button";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Avatar,
  IconButton,
  Link,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//date picker
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMERS } from "../../../Schema/Customers";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
//upload image
import CropImageFile from "../../CropImage/CropImageFile";
import ReferenceList from "./ReferenceList";

export default function AddCustomers() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [checkListRequired, setCheckListRequried] = useState(false);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  // ============================ Set Reference List ========================
  const [currentItem, setCurrentItem] = useState({
    document_src: "",
    document_name: "",
    key: Date.now(),
  });

  const [item, setItem] = useState([]);

  const addItem = () => {
    const newItem = currentItem;
    if (newItem.product_Id !== "") {
      const items = [...item, newItem];
      setItem([...items]);
      setCurrentItem({
        document_src: "",
        document_name: "",
        key: "",
      });
    }
  };

  const hanleAddDocument = () => {
    setCurrentItem({
      document_src: "",
      document_name: "",
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentItem?.key !== "") {
      addItem();
    }
  }, [currentItem?.key]);

  useMemo(async () => {
    await hanleAddDocument();
    await addItem();
  }, []);

  const deleteItem = (key) => {
    const filteredItems = item?.filter((t) => t.key !== key);
    setItem(filteredItems);
  };

  const setUpdateDocument = (document_src, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.document_src = document_src;
      }
    });
    setItem([...items]);
  };

  const setUpdateDocumentName = (document_name, key) => {
    const items = item;
    items.map((i) => {
      if (i.key === key) {
        i.document_name = document_name;
      }
    });
    setItem([...items]);
  };

  //Create user
  const [createCustomer, { data, error }] = useMutation(CREATE_CUSTOMERS, {
    onCompleted: ({ createCustomer }) => {
      // console.log("create", createCustomer);
      if (createCustomer?.isSuccess) {
        setLoading(false);
        setAlert(true, "success", createCustomer?.message);
        resetForm();
        navigate("/customer");
      } else {
        console.log(createCustomer);
        setLoading(false);
        setAlert(true, "error", createCustomer?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
      console.log("error", error?.message);
    },
  });
  //end
  const phoneRegex = RegExp(
    /^(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  //fromik
  const AddCustomer = Yup.object().shape({
    ID_card_number: Yup.string()
      .matches(phoneRegex, "Invalid ID")
      .required("ID is required")
      .max(10),
    passport_id: Yup.string(),
    first_name: Yup.string().required("require!"),
    last_name: Yup.string().required("require!"),
    date_of_birth: Yup.date().required("require!"),
    reference_docs: Yup.string(),
    customer_type: Yup.string().required("require!"),
    family_status: Yup.string().required("require!"),
    latin_alphabet: Yup.string().required("require!"),
    nationality: Yup.string().required("require!"),
    nickname: Yup.string(),
    facebook: Yup.string(),
    gender: Yup.string().required("require!"),
    current_address: Yup.object().shape({
      commune: Yup.string().required("require commune"),
      district: Yup.string().required("require district"),
      province: Yup.string().required("require province"),
      villege: Yup.string().required("require villege"),
    }),
    place_of_birth: Yup.object().shape({
      commune: Yup.string().required("require commune"),
      district: Yup.string().required("require district"),
      province: Yup.string().required("require province"),
      villege: Yup.string().required("require villege"),
    }),
    address_company: Yup.string().required("require!"),
    address_google: Yup.string(),
    company_name: Yup.string().required("require company name!"),
    monthly_income: Yup.string()
      .required("monthly-income is required!")
      .min(0.0001, "input invalid!"),
    phone_number: Yup.string()
      .matches(phoneRegex, "Invalid phone!")
      .required("required!")
      .max(10),
    phone_number_company: Yup.string()
      .matches(phoneRegex, "Invalid phone!")
      .required("required!")
      .max(10),
    salary_payment_date: Yup.string().min(1).max(31).required("required!"),
    positions: Yup.string().required("require!"),
    type_of_business: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      ID_card_number: "",
      passport_id: "",
      address_google: "",
      current_address: {
        commune: "",
        district: "",
        province: "",
        villege: "",
      },
      customer_type: "School Customer",
      date_of_birth: Date(),
      facebook: "",
      family_status: "",
      first_name: "",
      gender: "Male",
      last_name: "",
      latin_alphabet: "",
      nationality: "",
      nickname: "",
      phone_number: "",
      place_of_birth: {
        commune: "",
        district: "",
        villege: "",
        province: "",
      },
      reference_docs: "",
      address_company: "",
      company_name: "",
      monthly_income: "",
      phone_number_company: "",
      positions: "",
      salary_payment_date: "",
      type_of_business: "",
    },

    validationSchema: AddCustomer,
    onSubmit: async (values) => {
      setLoading(true);

      // console.log(values, "value");
      if (imageFile) {
        createCustomer({
          variables: {
            input: {
              ...values,
              image_src: profileHook,
              image_name: "Profile Image",
              phone_number: values?.phone_number?.toString(),
              ID_card_number: values?.ID_card_number?.toString(),
              monthly_income: values?.monthly_income?.toString(),
              salary_payment_date: values?.salary_payment_date?.toString(),
              phone_number_company: values?.phone_number_company?.toString(),
              reference_docs: item,
              place_of_birth: {
                commune: values?.place_of_birth?.commune,
                district: values?.place_of_birth?.district,
                province: values?.place_of_birth?.province,
                villege: values?.place_of_birth?.villege,
              },
              current_address: {
                commune: values?.current_address?.commune,
                district: values?.current_address?.district,
                province: values?.current_address?.province,
                villege: values?.current_address?.villege,
              },
            },
          },
        });
      } else {
        createCustomer({
          variables: {
            input: {
              ...values,
              image_src: "",
              image_name: "",
              phone_number: values?.phone_number?.toString(),
              ID_card_number: values?.ID_card_number?.toString(),
              monthly_income: values?.monthly_income?.toString(),
              salary_payment_date: values?.salary_payment_date?.toString(),
              phone_number_company: values?.phone_number_company?.toString(),
              reference_docs: item,
              place_of_birth: {
                commune: values?.place_of_birth?.commune,
                district: values?.place_of_birth?.district,
                province: values?.place_of_birth?.province,
                villege: values?.place_of_birth?.villege,
              },
              current_address: {
                commune: values?.current_address?.commune,
                district: values?.current_address?.district,
                province: values?.current_address?.province,
                villege: values?.current_address?.villege,
              },
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <div className="setup-customer">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link
              className={language === "kh" ? "linkKh" : "linkEn"}
              onClick={() => navigate("/customer")}
            >
              {t("customer")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              /
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("create") + " " + t("customer")}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row">
          {loading ? (
            <Button
              className={language === "kh" ? "btn-create-Kh" : "btn-create-En"}
              fullWidth
            >
              Loading...
            </Button>
          ) : (
            <Button
              className={language === "kh" ? "btn-create-Kh" : "btn-create-En"}
              fullWidth
              onClick={() => {
                setCheckListRequried(true);
                handleSubmit();
              }}
            >
              {t("create")}
            </Button>
          )}
        </Stack>
      </Stack>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box className="body-container">
            <Typography
              className={language === "kh" ? "body-titleKh" : "body-titleEn"}
            >
              {t("personal-info")}
            </Typography>
            <Divider sx={{ margin: "10px 0px 20px 0px" }} />
            <Grid container spacing={4}>
              <Grid item xs={12} xl={4}>
                <Stack direction="row" justifyContent="center">
                  {!openCrop ? (
                    <Box className="title-add">
                      <Stack
                        className="profile"
                        direction="row"
                        justifycontent="center"
                      >
                        <IconButton component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : values?.image_src
                            }
                          />
                          <input type="file" hidden />
                        </IconButton>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        <Typography
                          className={language === "kh" ? "text-Kh" : "text-En"}
                        >
                          {t("profile")}
                        </Typography>
                      </Stack>
                    </Box>
                  ) : (
                    <CropImageFile
                      setImageFile={setImageFile}
                      photoURL={photoURL}
                      setOpenCrop={setOpenCrop}
                      setPhotoURL={setPhotoURL}
                      setUploadHook={setProfileHook}
                    />
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("kh-firstname")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("kh-firstname")}
                      {...getFieldProps("first_name")}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("kh-lastname")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("kh-lastname")}
                      {...getFieldProps("last_name")}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("latin-name")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("latin-name")}
                      {...getFieldProps("latin_alphabet")}
                      error={Boolean(
                        touched.latin_alphabet && errors.latin_alphabet
                      )}
                      helperText={
                        touched.latin_alphabet && errors.latin_alphabet
                      }
                    />
                  </Stack>

                </Stack>
              </Grid>

              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("nickname")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("nickname")}
                      {...getFieldProps("nickname")}
                      error={Boolean(touched.nickname && errors.nickname)}
                      helperText={touched.nickname && errors.nickname}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("gender")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <FormControl size="small" className="text-field">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values?.gender}
                        onChange={(event) => {
                          setFieldValue("gender", event.target.value);
                        }}
                        error={Boolean(touched.gender && errors.gender)}
                        helperText={touched.gender && errors.gender}
                      >
                        <MenuItem value={"Female"}>{t("female")}</MenuItem>
                        <MenuItem value={"Male"}>{t("male")}</MenuItem>
                        <MenuItem value={"Other"}>{t("other")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("phone")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("phone")}
                      {...getFieldProps("phone_number")}
                      error={Boolean(
                        touched.phone_number && errors.phone_number
                      )}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* ============================== under profile first grid  =============================== */}

              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("customer-type")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <FormControl size="small" className="text-field">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values?.customer_type}
                        onChange={(event) =>
                          setFieldValue("customer_type", event.target.value)
                        }
                        error={Boolean(
                          touched.customer_type && errors.customer_type
                        )}
                        helperText={
                          touched.customer_type && errors.customer_type
                        }
                      >
                        <MenuItem value={"School Customer"}>
                          {t("school-customer")}
                        </MenuItem>
                        <MenuItem value={"Other Customer"}>
                          {t("other-customer")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("nationality")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("nationality")}
                      {...getFieldProps("nationality")}
                      error={Boolean(touched.nationality && errors.nationality)}
                      helperText={touched.nationality && errors.nationality}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("id-card")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      type="number"
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("id-card")}
                      {...getFieldProps("ID_card_number")}
                      error={Boolean(
                        touched.ID_card_number && errors.ID_card_number
                      )}
                      helperText={
                        touched.ID_card_number && errors.ID_card_number
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("passport_number")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("passport_number")}
                      {...getFieldProps("passport_id")}
                      error={Boolean(
                        touched.passport_id && errors.passport_id
                      )}
                      helperText={
                        touched.passport_id && errors.passport_id
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("facebook")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      type="email"
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("facebook")}
                      {...getFieldProps("facebook")}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("marital-status")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("marital-status")}
                      {...getFieldProps("family_status")}
                      error={Boolean(
                        touched.family_status && errors.family_status
                      )}
                      helperText={touched.family_status && errors.family_status}
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* ============================ under profile seconde grid  ============================= */}

              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("dob")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={values?.date_of_birth}
                        onChange={(newValue) => {
                          setFieldValue("date_of_birth", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="text-field"
                            size="small"
                            fullWidth
                            error={Boolean(
                              touched.date_of_birth && errors.date_of_birth
                            )}
                            helperText={
                              touched.date_of_birth && errors.date_of_birth
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("place-of-birth") + t("village")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("place-of-birth") + t("village")}
                      {...getFieldProps("place_of_birth.villege")}
                      error={Boolean(
                        touched.place_of_birth?.villege &&
                        errors.place_of_birth?.villege
                      )}
                      helperText={
                        touched.place_of_birth?.villege &&
                        errors.place_of_birth?.villege
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("commune")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("commune")}
                      {...getFieldProps("place_of_birth.commune")}
                      error={Boolean(
                        touched.place_of_birth?.commune &&
                        errors.place_of_birth?.commune
                      )}
                      helperText={
                        touched.place_of_birth?.commune &&
                        errors.place_of_birth?.commune
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("district")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("district")}
                      {...getFieldProps("place_of_birth.district")}
                      error={Boolean(
                        touched.place_of_birth?.district &&
                        errors.place_of_birth?.district
                      )}
                      helperText={
                        touched.place_of_birth?.district &&
                        errors.place_of_birth?.district
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("province")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("province")}
                      {...getFieldProps("place_of_birth.province")}
                      error={Boolean(
                        touched.place_of_birth?.province &&
                        errors.place_of_birth?.province
                      )}
                      helperText={
                        touched.place_of_birth?.province &&
                        errors.place_of_birth?.province
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* ============================ under profile third grid  ============================= */}
              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("address-google")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("address-google")}
                      {...getFieldProps("address_google")}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("current-address") + t("village")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("current-address") + t("village")}
                      {...getFieldProps("current_address.villege")}
                      error={Boolean(
                        touched.current_address?.villege &&
                        errors.current_address?.villege
                      )}
                      helperText={
                        touched.current_address?.villege &&
                        errors.current_address?.villege
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("commune")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("commune")}
                      {...getFieldProps("current_address.commune")}
                      error={Boolean(
                        touched.current_address?.commune &&
                        errors.current_address?.commune
                      )}
                      helperText={
                        touched.current_address?.commune &&
                        errors.current_address?.commune
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("district")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("district")}
                      {...getFieldProps("current_address.district")}
                      error={Boolean(
                        touched.current_address?.district &&
                        errors.current_address?.district
                      )}
                      helperText={
                        touched.current_address?.district &&
                        errors.current_address?.district
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("province")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("province")}
                      {...getFieldProps("current_address.province")}
                      error={Boolean(
                        touched.current_address?.province &&
                        errors.current_address?.province
                      )}
                      helperText={
                        touched.current_address?.province &&
                        errors.current_address?.province
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            {/* ================================= Business Info ================================ */}
            <Typography
              className={language === "kh" ? "body-titleKh" : "body-titleEn"}
              sx={{ marginTop: "20px" }}
            >
              {t("business-info")}
            </Typography>
            <Divider sx={{ margin: "10px 0px 20px 0px" }} />
            <Grid container spacing={4}>
              {/* ============================== business info first grid  =============================== */}
              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("position")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("position")}
                      {...getFieldProps("positions")}
                      error={Boolean(touched.positions && errors.positions)}
                      helperText={touched.positions && errors.positions}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("monthly-income")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      type="number"
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("monthly-income")}
                      {...getFieldProps("monthly_income")}
                      error={Boolean(
                        touched.monthly_income && errors.monthly_income
                      )}
                      helperText={
                        touched.monthly_income && errors.monthly_income
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("salary-payment-date")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      type="number"
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("salary-payment-date")}
                      {...getFieldProps("salary_payment_date")}
                      error={Boolean(
                        touched.salary_payment_date &&
                        errors.salary_payment_date
                      )}
                      helperText={
                        touched.salary_payment_date &&
                        errors.salary_payment_date
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* ============================== business info second grid  =============================== */}
              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("company-name")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("company-name")}
                      {...getFieldProps("company_name")}
                      error={Boolean(
                        touched.company_name && errors.company_name
                      )}
                      helperText={touched.company_name && errors.company_name}
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("type-of-business")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("type-of-business")}
                      {...getFieldProps("type_of_business")}
                      error={Boolean(
                        touched.type_of_business && errors.type_of_business
                      )}
                      helperText={
                        touched.type_of_business && errors.type_of_business
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>

              {/* ============================== business info third grid  =============================== */}
              <Grid item xs={12} lg={6} xl={4}>
                <Stack direction="column" spacing={1.5}>
                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("phone-number-company")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("phone-number-company")}
                      {...getFieldProps("phone_number_company")}
                      error={Boolean(
                        touched.phone_number_company &&
                        errors.phone_number_company
                      )}
                      helperText={
                        touched.phone_number_company &&
                        errors.phone_number_company
                      }
                    />
                  </Stack>

                  <Stack direction="row">
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        className={
                          language === "kh" ? "body-textKh" : "body-textEn"
                        }
                      >
                        {t("address-company")} :
                      </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                      type="text"
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder={t("address-company")}
                      {...getFieldProps("address_company")}
                      error={Boolean(
                        touched.address_company && errors.address_company
                      )}
                      helperText={
                        touched.address_company && errors.address_company
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            {/* ================================= Reference Info ================================ */}
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ marginTop: "20px" }}
            >
              <Stack direction="column" justifyContent="center">
                <Typography
                  className={
                    language === "kh" ? "body-titleKh" : "body-titleEn"
                  }
                >
                  {t("reference-info")}
                </Typography>
              </Stack>
              <Button
                className={language === "kh" ? "btn-addKh" : "btn-addEn"}
                startIcon={<AddIcon className="icon-add" />}
                onClick={hanleAddDocument}
              >
                {t("add")}
              </Button>
            </Stack>
            <Divider sx={{ margin: "10px 0px 20px 0px" }} />
            <Grid container spacing={4}>
              <ReferenceList
                items={item}
                deleteItem={deleteItem}
                setUpdateDocument={setUpdateDocument}
                setUpdateDocumentName={setUpdateDocumentName}
                checkListRequired={checkListRequired}
              />
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
