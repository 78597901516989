import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_BY_DAY_REPORT } from "../../Schema/Report";
import { GET_CUSTOMER_INSTALLMENT_BY_DAY } from "../../Schema/Installment";
import {
  getKhmerNumber,
  getKhmerMonth,
  currencyFormat,
} from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";

export default function CustomerByDay({ dateSelected, companyType }) {
  const [khmerDateString, setKhmerDateString] = useState("");
  const [loanData, setLoanData] = useState([]);
  const [pawnData, setPawnData] = useState([]);
  const [installmentData, setInstallmentData] = useState([]);

  const { data } = useQuery(GET_CUSTOMER_BY_DAY_REPORT, {
    variables: {
      day: moment(dateSelected).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getReportCostomerByDay }) => {
      setLoanData(getReportCostomerByDay?.loans);
      setPawnData(getReportCostomerByDay?.pawns);
      // console.log("getReportCostomerByDay::=>", getReportCostomerByDay);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const { data: installment } = useQuery(GET_CUSTOMER_INSTALLMENT_BY_DAY, {
    variables: {
      day: moment(dateSelected).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getReportCostomerInstallmentsByDay }) => {
      setInstallmentData(getReportCostomerInstallmentsByDay);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("installmentData::", installmentData);

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  let allRowDate =
    loanData?.length +
    pawnData?.length +
    installmentData?.go_gredit?.length +
    installmentData?.kano_tech?.length +
    installmentData?.techno_khmer?.length +
    4;

  // console.log(
  //   "loanData:::",
  //   loanData?.length,
  //   pawnData?.length,
  //   installmentData?.go_gredit?.length,
  //   installmentData?.kano_tech?.length,
  //   installmentData?.techno_khmer?.length
  // );
  // console.log("allRowDate:::", allRowDate);

  let totalLoan = loanData.reduce(function (a, b) {
    return a + b.loan_amount;
  }, 0);

  let totalPawn = pawnData.reduce(function (a, b) {
    return a + b.loan_amount;
  }, 0);

  let totalGocredit = installmentData?.go_gredit?.reduce(function (a, b) {
    return a + b.loan_amount_USD;
  }, 0);

  let totalKanaTech = installmentData?.kano_tech?.reduce(function (a, b) {
    return a + b.loan_amount_USD;
  }, 0);

  let totalTechnoKhmer = installmentData?.techno_khmer?.reduce(function (a, b) {
    return a + b.loan_amount_USD;
  }, 0);

  // console.log(
  //   "first::",
  //   installmentData?.go_gredit?.length,
  //   installmentData?.kano_tech?.length,
  //   installmentData?.techno_khmer?.length
  // );

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            សក្ខីប័ណ្ណបញ្ចេញសាច់ប្រាក់
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", justifyContent: "right", marginTop: "100px" }}
        >
          <Stack direction="row" spacing={1}>
            <Typography className="number-title-customerbyday">លេខ៖</Typography>
            <Typography className="number-item-customerbyday">
              ...........................
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th rowSpan={2}>កាលបរិច្ឆេទ</th>
            <th colSpan={2}>បរិយាយ</th>
            <th rowSpan={2}>លេខកូដ</th>
            <th rowSpan={2}>រយៈពេលខ្ចី</th>
            <th colSpan={2}>ទឹកប្រាក់</th>
          </tr>
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th>ល.រ</th>
            <th>ឈ្មោះ</th>
            <th>រៀល</th>
            <th>ដុល្លា</th>
          </tr>
          {loanData?.length === 0 &&
          pawnData?.length === 0 &&
          installmentData?.go_gredit?.length === 0 &&
          installmentData?.kano_tech?.length === 0 &&
          installmentData?.techno_khmer?.length === 0 ? null : (
            <tr>
              <td className="first-col" rowSpan={allRowDate}>
                {`${getKhmerNumber(
                  moment(dateSelected).format("DD")
                )}  ${getKhmerMonth(
                  moment(dateSelected).format("M")
                )} ${getKhmerNumber(moment(dateSelected).format("YY"))}`}
              </td>
              {loanData?.length === 0 ? null : (
                <td className="first-col" colSpan={6}>
                  ការបញ្ចេញសាច់ប្រាក់ជូនអតិថិជនកម្ចី
                </td>
              )}
            </tr>
          )}
          {loanData.map((row, index) => (
            <tr key={index}>
              <td>{getKhmerNumber(index + 1)}</td>
              <td style={{ textAlign: "left" }}>
                {row?.customer_id?.first_name +
                  " " +
                  row?.customer_id?.last_name}
              </td>
              <td>{row?.loan_id}</td>
              <td>{getKhmerNumber(row?.loan_plan?.month)} ខែ</td>
              <td></td>
              <td>{currencyFormat(row?.loan_amount)}</td>
            </tr>
          ))}
          {pawnData?.length === 0 ? null : (
            <>
              <tr>
                <td className="first-col" colSpan={7}>
                  ការបញ្ចេញសាច់ប្រាក់ជូនអតិថិជនបញ្ចាំ
                </td>
              </tr>

              {pawnData.map((row, index) => (
                <tr key={index}>
                  <td>{getKhmerNumber(index + 1)}</td>
                  <td style={{ textAlign: "left" }}>
                    {row?.customer_id?.first_name +
                      " " +
                      row?.customer_id?.last_name}
                  </td>
                  <td>{row?.loan_id}</td>
                  <td>{getKhmerNumber(row?.loan_plan?.month)} ខែ</td>
                  <td></td>
                  <td>{currencyFormat(row?.loan_amount)}</td>
                </tr>
              ))}
            </>
          )}

          {companyType === "go-credit" ? (
            <>
              {installmentData?.go_gredit?.length === 0 ? null : (
                <tr>
                  <td className="first-col" colSpan={7}>
                    ការបញ្ចេញសាច់ប្រាក់ជូនអតិថិជនបង់រំលស់
                  </td>
                </tr>
              )}

              {installmentData?.go_gredit?.map((row, index) => (
                <tr key={index}>
                  <td>{getKhmerNumber(index + 1)}</td>
                  <td style={{ textAlign: "left" }}>{row?.costomer_name}</td>
                  <td>{row?.loan_id}</td>
                  <td>{getKhmerNumber(row?.loan_plan)} ខែ</td>
                  <td></td>
                  <td>{currencyFormat(row?.loan_amount_USD)}</td>
                </tr>
              ))}
            </>
          ) : companyType === "kano-tech" ? (
            <>
              {installmentData?.kano_tech?.length === 0 ? null : (
                <tr>
                  <td className="first-col" colSpan={7}>
                    ការបញ្ចេញសាច់ប្រាក់ជូនអតិថិជនបង់រំលស់
                  </td>
                </tr>
              )}
              {installmentData?.kano_tech?.map((row, index) => (
                <tr key={index}>
                  <td>{getKhmerNumber(index + 1)}</td>
                  <td style={{ textAlign: "left" }}>{row?.costomer_name}</td>
                  <td>{row?.loan_id}</td>
                  <td>{getKhmerNumber(row?.loan_plan)} ខែ</td>
                  <td></td>
                  <td>{currencyFormat(row?.loan_amount_USD)}</td>
                </tr>
              ))}
            </>
          ) : (
            <>
              {installmentData?.techno_khmer?.length === 0 ? null : (
                <tr>
                  <td className="first-col" colSpan={7}>
                    ការបញ្ចេញសាច់ប្រាក់ជូនអតិថិជនបង់រំលស់
                  </td>
                </tr>
              )}
              {installmentData?.techno_khmer?.map((row, index) => (
                <tr key={index}>
                  <td>{getKhmerNumber(index + 1)}</td>
                  <td style={{ textAlign: "left" }}>{row?.costomer_name}</td>
                  <td>{row?.loan_id}</td>
                  <td>{getKhmerNumber(row?.loan_plan)} ខែ</td>
                  <td></td>
                  <td>{currencyFormat(row?.loan_amount_USD)}</td>
                </tr>
              ))}
            </>
          )}
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th colSpan={allRowDate === 4 ? 5 : 4}>សរុបទឹកប្រាក់</th>
            <th style={{ color: "red" }}>០ ៛</th>
            <th style={{ color: "red" }}>
              {companyType === "go-credit"
                ? currencyFormat(totalLoan + totalPawn + totalGocredit)
                : companyType === "kano-tech"
                ? currencyFormat(totalLoan + totalPawn + totalKanaTech)
                : currencyFormat(totalLoan + totalPawn + totalTechnoKhmer)}
            </th>
          </tr>
        </table>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3.5}>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>
          <div className="loan-footer-name">អ្នកទទួលប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div>
        </Grid>

        <Grid item xs={2.5} sx={{ marginTop: "40px" }}>
          <div className="loan-footer-name">អ្នកបញ្ចេញប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div>
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>

          <Stack direction="row" spacing={6}>
            <Box>
              <div className="loan-footer-name">អ្នកអនុម័ត</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
            <Box>
              <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
