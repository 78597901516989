import React, { useEffect, useState } from "react";
import { Grid, Stack, Box } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { getKhmerNumber, currencyFormat } from "../../Function/GetKhmerNumber";
import { getKhmerMonth } from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";
import { GET_DAILY_CUSTOMER } from "../../Schema/Report";

export default function DifficultCustomerReport({ dateSelected }) {
  const [khmerDateString, setKhmerDateString] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_DAILY_CUSTOMER, {
    variables: {
      paymentStatus: "difficult",
      date: moment(dateSelected).utc().format("YYYY-MM-DD"),
    },
    onCompleted: ({ getReportCostomerPayment }) => {
      setTableData(getReportCostomerPayment);
      // console.log("getReportCostomerPayment::=>", getReportCostomerPayment);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("filterData::", filterData)

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            របាយការណ៍អតិថិជនពិបាក
          </Box>
          <Box
            className="loan-footer-title"
            sx={{ marginTop: "10px" }}
          >{`សម្រាប់ខែ${getKhmerMonth(
            moment().format("M")
          )} ឆ្នាំ${getKhmerNumber(moment().format("YYYY"))}`}</Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th>ល.រ</th>
            <th>ឈ្មោះ</th>
            <th>លេខកូដ</th>
            <th>ថ្ងៃបង់ចុងក្រោយ</th>
            <th>ប្រាក់ដើម</th>
            <th>ការប្រាក់</th>
            <th>ប្រាក់ត្រូវបង់</th>
            <th>ផ្សេងៗ</th>
          </tr>

          {tableData?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>{row?.name}</td>
              <td>{row?.code}</td>
              <td>
                {moment(row?.last_payment_day).utc().format("DD-MMM-YYYY")}
              </td>
              <td>{currencyFormat(row?.last_principal_for_late_difficult)}</td>
              <td>{currencyFormat(row?.last_interest_for_late_difficult)}</td>
              <td>{currencyFormat(row?.total_payment)}</td>
              <td></td>
            </tr>
          ))}
        </table>
      </div>

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <div className="loan-footer-title">បានត្រួតពិនិត្យ និងឯកភាព</div>
          <div className="loan-footer-name">ជ.អ្នកគ្រប់គ្រង</div>
          <div className="loan-footer-name">អ្នកគ្រប់គ្រងសាច់ប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* ខូវ ស៊ូខេង */}
            .................................
          </div>
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>
          <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* អុន ដានី */}
            .................................
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
