import React, { useState, useEffect, useRef } from "react";
import "./loanrequestdetails.scss";
import { Grid, Stack, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { getKhmerNumber } from "../../../Function/GetKhmerNumber";
import { getKhmerMonth } from "../../../Function/GetKhmerNumber";
import { getKhmerDay } from "../../../Function/GetKhmerNumber";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";

export default function LoanRequestAgreement({ tableData }) {
  const [loading, setLoading] = useState(false);

  const [khmerDateString, setKhmerDateString] = useState("");

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("tableData::", tableData);

  function getFormattedPhoneNum(input) {
    let output = "";
    input.replace(
      /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
      function (match, g1, g2, g3) {
        if (g1.length) {
          output += g1;
          if (g1.length == 3) {
            output += "";
            if (g2.length) {
              output += " " + g2;
              if (g2.length == 3) {
                output += " ";
                if (g3.length) {
                  output += g3;
                }
              }
            }
          }
        }
      }
    );
    return output;
  }

  return (
    <>
      <div
        className="print-loan-request-container"
        style={{ marginTop: "30px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box className="logo-container">
              <img src={logoCompany} className="logo-company" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center">
              <Box>
                <div className="body-title-loant-request">
                  ព្រះរាជាណាចក្រកម្ពុជា
                </div>
                <div className="body-title-loant-request">
                  ជាតិ សាសនា ព្រះមហាក្សត្រ
                </div>
                <div className="loan-request-font-tackteng">
                  <img
                    alt="Image"
                    src={wingdingImage}
                    className="loan-request-image-tackteng"
                  />
                </div>
              </Box>
            </Stack>
            <Box
              className="body-title-loant-request"
              sx={{ marginTop: "60px" }}
            >
              ពាក្យសុំចងការប្រាក់
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>

        <Box className="loan-request-borrower-title">១. ព័ត៌មានអ្នកខ្ចី</Box>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.first_name +
              " " +
              tableData?.customer_id?.last_name}
          </Box>
          <Box className="loan-request-title">{`(ឈ្មោះហៅក្រៅ ${tableData?.customer_id?.nickname})`}</Box>
          <Box className="loan-request-title">អក្សរឡាតាំង</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.latin_alphabet}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ភេទ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.gender}
          </Box>
          <Box className="loan-request-title">សញ្ជាតិ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.nationality}
          </Box>
          <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(tableData?.customer_id?.ID_card_number)}
          </Box>
          <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-item">
            {`${getKhmerNumber(
              moment(tableData?.customer_id?.date_of_birth).format("DD")
            )} ${getKhmerMonth(
              moment(tableData?.customer_id?.date_of_birth).format("M")
            )} ${getKhmerNumber(
              moment(tableData?.customer_id?.date_of_birth).format("YYYY")
            )}`}
          </Box>
          <Box className="loan-request-title">ស្ថានភាពគ្រួសារ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.family_status}
          </Box>
          <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
          <Box className="loan-request-item">{`ភូមិ ${tableData?.customer_id?.current_address?.villege}, ឃុំ ${tableData?.customer_id?.current_address?.commune}`}</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-item">{` ស្រុក ${tableData?.customer_id?.current_address?.district}, ខេត្ត ${tableData?.customer_id?.current_address?.province}`}</Box>
          <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(
              getFormattedPhoneNum(tableData?.customer_id?.phone_number)
            )}
          </Box>
          <Box className="loan-request-title">Facebook</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.facebook} ។
          </Box>
        </Stack>
        <Box className="loan-request-borrower-title">២. ព័ត៌មានអាជីវកម្ម</Box>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ឈ្មោះក្រុមហ៊ុន</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.company_name}
          </Box>
          <Box className="loan-request-title">លេខទូរស័ព្ទក្រុមហ៊ុន</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(
              getFormattedPhoneNum(tableData?.customer_id?.phone_number_company)
            )}
          </Box>
          <Box className="loan-request-title">ប្រភេទអាជីវកម្ម</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.type_of_business}
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">អាសយដ្ឋានក្រុមហ៊ុន</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.address_company}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">មុខតំណែង</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.positions}
          </Box>
          <Box className="loan-request-title">ប្រាក់ចំណូលប្រចាំខែ</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(tableData?.customer_id?.monthly_income)} ដុល្លា
          </Box>
          <Box className="loan-request-title">កាលបរិច្ឆេទបើកប្រាក់បៀវត្ស</Box>
          <Box className="loan-request-item">
            ថ្ងៃទី {tableData?.customer_id?.salary_payment_date}
          </Box>
        </Stack>

        {tableData?.borrower_together?.borrower_together_id === null ? (
          <>
            <Box className="loan-request-borrower-title">
              ៣. ព័ត៌មានអ្នករួមខ្ចី
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">អក្សរឡាតាំង</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ភេទ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">សញ្ជាតិ</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ស្ថានភាពគ្រួសារ</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ត្រូវជា</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">អ្នកខ្ចីប្រាក់។</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">លេខទូរស័ព្ទក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ប្រភេទអាជីវកម្ម</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Box className="loan-request-title">អាសយដ្ឋានក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">មុខតំណែង</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ប្រាក់ចំណូលប្រចាំខែ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">
                កាលបរិច្ឆេទបើកប្រាក់បៀវត្ស
              </Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
          </>
        ) : (
          <>
            <Box className="loan-request-borrower-title">
              ៣. ព័ត៌មានអ្នករួមខ្ចី
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះ</Box>
              <Box className="loan-request-item">
                {tableData?.borrower_together?.borrower_together_id
                  ?.first_name +
                  " " +
                  tableData?.borrower_together?.borrower_together_id?.last_name}
              </Box>
              <Box className="loan-request-title">អក្សរឡាតាំង</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.latin_alphabet
                }
              </Box>
              <Box className="loan-request-title">ភេទ</Box>
              <Box className="loan-request-item">
                {tableData?.borrower_together?.borrower_together_id?.gender}
              </Box>
              <Box className="loan-request-title">សញ្ជាតិ</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.nationality
                }
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  tableData?.borrower_together?.borrower_together_id
                    ?.ID_card_number
                )}
              </Box>
              <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
              <Box className="loan-request-item">
                {`${getKhmerNumber(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.date_of_birth
                  ).format("DD")
                )} ${getKhmerMonth(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.date_of_birth
                  ).format("M")
                )} ${getKhmerNumber(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.date_of_birth
                  ).format("YYYY")
                )}`}
              </Box>
              <Box className="loan-request-title">ស្ថានភាពគ្រួសារ</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.family_status
                }
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
              <Box className="loan-request-item">
                {`ភូមិ ${tableData?.borrower_together?.borrower_together_id?.current_address?.villege}`}
              </Box>
              <Box className="loan-request-item">
                {`ឃុំ ${tableData?.borrower_together?.borrower_together_id?.current_address?.commune}`}
              </Box>
              <Box className="loan-request-item">
                {`ស្រុក ${tableData?.borrower_together?.borrower_together_id?.current_address?.district}`}
              </Box>
              <Box className="loan-request-item">
                {`ខេត្ត ${tableData?.borrower_together?.borrower_together_id?.current_address?.province}`}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  getFormattedPhoneNum(
                    tableData?.borrower_together?.borrower_together_id
                      ?.phone_number
                  )
                )}
              </Box>
              <Box className="loan-request-title">ត្រូវជា</Box>
              <Box className="loan-request-item">
                {tableData?.borrower_together?.borrower_connection}
              </Box>
              <Box className="loan-request-title">អ្នកខ្ចីប្រាក់។</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.company_name
                }
              </Box>
              <Box className="loan-request-title">លេខទូរស័ព្ទក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  getFormattedPhoneNum(
                    tableData?.borrower_together?.borrower_together_id
                      ?.phone_number_company
                  )
                )}
              </Box>
              <Box className="loan-request-title">ប្រភេទអាជីវកម្ម</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.type_of_business
                }
              </Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Box className="loan-request-title">អាសយដ្ឋានក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {
                  tableData?.borrower_together?.borrower_together_id
                    ?.address_company
                }
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">មុខតំណែង</Box>
              <Box className="loan-request-item">
                {tableData?.borrower_together?.borrower_together_id?.positions}
              </Box>
              <Box className="loan-request-title">ប្រាក់ចំណូលប្រចាំខែ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  tableData?.borrower_together?.borrower_together_id
                    ?.monthly_income
                )}{" "}
                ដុល្លា
              </Box>
              <Box className="loan-request-title">
                កាលបរិច្ឆេទបើកប្រាក់បៀវត្ស
              </Box>
              <Box className="loan-request-item">
                {`${getKhmerNumber(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.salary_payment_date
                  ).format("DD")
                )} ${getKhmerMonth(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.salary_payment_date
                  ).format("M")
                )} ${getKhmerNumber(
                  moment(
                    tableData?.borrower_together?.borrower_together_id
                      ?.salary_payment_date
                  ).format("YYYY")
                )}`}
              </Box>
            </Stack>
          </>
        )}

        <Box className="body-title-loant-request" sx={{ marginTop: "8px" }}>
          សូមគោរពជូន
        </Box>
        <Box className="body-title-loant-request">អ្នកគ្រប់គ្រងហ្គោក្រេឌីត</Box>
        <Box sx={{ margin: "10px 0px 0px 40px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Box className="loan-request-title">
              <span className="loan-request-title-bold">កម្មវត្ថុ៖</span>{" "}
              សំណើសុំខ្ចីចងការប្រាក់ចំនួន
            </Box>
            <Box className="loan-request-item">
              {getKhmerNumber(tableData?.loan_amount)} ដុល្លា
            </Box>
            <Box className="loan-request-title">ជាអក្សរ</Box>
            <Box className="loan-request-item">
              {tableData?.loan_amount_letters}
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            sx={{ marginLeft: "30px" }}
          >
            <Box className="loan-request-title">រយៈពេល</Box>
            <Box className="loan-request-item">
              {getKhmerNumber(tableData?.loan_plan?.month)}
            </Box>
            <Box className="loan-request-title">ខែ អត្រាការប្រាក់</Box>
            <Box className="loan-request-item">
              {`${getKhmerNumber(tableData?.loan_plan?.interest)} % `}{" "}
            </Box>
            <Box className="loan-request-item">ក្នុងមួយខែ។</Box>
            <Box className="loan-request-title-bold">គោលបំណង៖</Box>
            <Box className="loan-request-item">{tableData?.purpose}</Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box className="loan-request-title-bold">ប្រភេទកម្ចី៖</Box>
            <Box className="loan-request-title">
              {tableData?.type_of_loan === "Loan" ? (
                <>
                  <span>&#9745;</span>
                  កម្ចី
                </>
              ) : tableData?.type_of_loan === "Pawn" ? (
                <>
                  <span>&#9745;</span>
                  បញ្ចាំ
                </>
              ) : (
                <>
                  <span>&#9745;</span>
                  បង់រំលស់
                </>
              )}
            </Box>
            <Box className="loan-request-title">
              {`(`}
              {tableData?.is_first_month === true ? (
                <>
                  <span>&#9745;</span>
                  បង់ដំបូង
                </>
              ) : (
                <>
                  <span>&#9745;</span>
                  មិនបង់ដំបូង
                </>
              )}
              {`)`}។
              <span className="loan-request-title-bold">
                កាលបរិច្ឆេទបង់ដំបូង៖
              </span>
            </Box>

            <Box className="loan-request-item">
              {`${getKhmerNumber(
                moment(tableData?.first_month_date).format("DD")
              )} ${getKhmerMonth(
                moment(tableData?.first_month_date).format("M")
              )} ${getKhmerNumber(
                moment(tableData?.first_month_date).format("YYYY")
              )}`}
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box className="loan-request-title-bold">អតិថិជនមាន៖</Box>
            <Box className="loan-request-item">
              <span>&#9745;</span>រូបថតបុគ្គល
            </Box>
            <Box className="loan-request-item">
              <span>&#9745;</span>រូបថតផ្ទះ ។
            </Box>
            <Box className="loan-request-title-bold">
              លេខទីតាំងក្នុង google៖
            </Box>
            <Box className="loan-request-item">...</Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <span className="loan-request-title-bold">
                1. <span>&#9744;</span> ផលិតផល
              </span>{" "}
              ...................................................................................................................
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <span className="loan-request-title-bold">
                2. <span>&#9744;</span> ទ្រព្យដាក់ធានា/បញ្ចាំ
              </span>{" "}
              ......................................................................................................
            </Box>
          </Stack>
          <Box className="loan-request-item" sx={{ textAlign: "left" }}>
            ..................................................................................................................................................
          </Box>
          <Box className="loan-request-item" sx={{ textAlign: "left" }}>
            ..................................................................................................................................................
          </Box>
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ក្រោយពីបានយល់នូវសេចក្តីណែនាំពីគោលការណ៍ និងលក្ខខណ្ឌរួមស្តីពី
          </Box>
          <Box className="loan-request-title-bold">“ការឱ្យខ្ចីចងការប្រាក់”</Box>
          <Box className="loan-request-title">យើង(ខ្ញុំ)</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">យល់ព្រមឱ្យ</Box>
          <Box className="loan-request-title-bold">ហោ្គក្រេឌីត ភីអិលស៊ី</Box>
          <Box className="loan-request-title">
            ប្រមូលព័ត៌មានដែលពាក់ព័ន្ធ និងពាក្យស្នើសុំខ្ចីចងការនេះ ព្រមទាំងបើកចំហ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ព័ត៌មានទាំងអស់ឱ្យទៅ</Box>
          <Box className="loan-request-title-bold">ហោ្គក្រេឌីត ភីអិលស៊ី</Box>
          <Box className="loan-request-title">
            យើង(ខ្ញុំ)សន្យាថាបំពេញបន្ថែមតាមតម្រូវការរបស់ក្រុមហ៊ុន និង
          </Box>
        </Stack>

        <Box className="loan-request-title">
          យល់ព្រមទទួលខុសត្រូវទាំងស្រុងចំពោះការខាតបង់ដែលបណ្តាលមកពីការធ្វេសប្រហែសក្នុងការផ្តល់ព័ត៌មានផ្ទុយពី
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ការពិត ។
            យើង(ខ្ញុំ)សូមសន្យាថានឹងបំពេញបែបបទបន្ថែមទៀតដើម្បីបំពេញតម្រូវការរបស់
          </Box>
          <Box className="loan-request-title-bold">ហោ្គក្រេឌីត ភីអិលស៊ី</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ហើយនិងផ្តល់នូវរាល់ការប្រែប្រួលទាក់ទងនឹងគ្រួសារទ្រព្យសម្បត្តិ
          </Box>
          <Box className="loan-request-title">
            និងរាល់ព្រឹត្តិការណ៍ដទៃទៀតដែលអាចនាំឱ្យខូច
          </Box>
        </Stack>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box className="loan-request-divider-footer"></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
      <div className="print-loan-request-container">
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: "30px" }}
        >
          <Box className="loan-request-title">ប្រយោជន៍ក្រុមហ៊ុន ។</Box>
          <Box className="loan-request-title">អាស្រ័យដូចបានជម្រាបជូនខាងលើ</Box>
          <Box className="loan-request-title">
            សូមមេត្តាអនុញ្ញាតឱ្យយើង(ខ្ញុំ)បានខ្ចីចងការប្រាក់ពី
          </Box>
        </Stack>

        <Box className="loan-request-title">
          ហោ្គក្រេឌីត
          ភីអិលស៊ីតាមការស្នើសុំដូចសេចក្តីដែលមានចែងក្នុងកម្មវត្ថុខាងលើដោយក្តីអនុគ្រោះ​
          ។
        </Box>
        {/* ======================================================= footer ====================================================== */}
        <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
          សូមអ្នកគ្រប់គ្រង មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីយើងខ្ញុំ ។
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className="body-title-loant-request">
                ស្នាមមេដៃអ្នករួមខ្ចីប្រាក់
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: "100px" }}
                justifyContent="center"
              >
                <Box className="loan-request-title">ឈ្មោះ</Box>
                <Box className="loan-request-item">
                  ........................................
                </Box>
              </Stack>
              <Box className="loan-request-title">
                <span>&#11162;</span> យោបល់ និងការអនុម័តឱ្យខ្ចីចងការ
              </Box>
              <Stack direction="row" spacing={4}>
                <Box className="loan-request-title">អគ្គនាយក</Box>
                <Box className="loan-request-item">អ្នកគ្រប់គ្រង</Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">ប្រធានមន្ត្រីឥណទាន</Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  អនុម័ត៖ ដោយពិនិត្យឃើញថា អតិថិជនមានចរិតល្អ{" "}
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  មុខរបរត្រឹមត្រូវ លទ្ធភាពសងគ្រប់គ្រាន់ និងមានទ្រព្យ
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  ដាក់ធានា ដូចោ្នះអាចផ្តល់កម្ចីបានតាមការស្នើសុំ។
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Box className="loan-request-title">
                  ថ្ងៃទី..................ខែ.......................ឆ្នាំ២០២២
                </Box>
              </Stack>
              <Box
                className="body-title-loant-request"
                sx={{ marginTop: "4px" }}
              >
                ហត្ថលេខាអ្នកអនុម័ត
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: "80px" }}
                justifyContent="center"
              >
                <Box className="loan-request-title">ឈ្មោះ</Box>
                <Box className="loan-request-item">
                  ....................................
                </Box>
              </Stack>
            </Grid>
            {/* ==================================================== footer right ================================================= */}
            <Grid item xs={6}>
              <Box className="body-title-loant-request">
                ស្នាមមេដៃអ្នកខ្ចីប្រាក់
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: "100px" }}
                justifyContent="center"
              >
                <Box className="loan-request-title">ឈ្មោះ</Box>
                <Box className="loan-request-item">
                  ........................................
                </Box>
              </Stack>
              <Box className="loan-request-title">
                <span>&#11162;</span> យោបល់ និងការស្នើសុំរបស់មន្ត្រីឥណទាន
              </Box>
              <Stack direction="row" spacing={4}>
                <Box className="loan-request-title">បានពិនិត្យឃើញថា</Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  ............................................................
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  ............................................................
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  ............................................................
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box className="loan-request-title">
                  ............................................................
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Box className="loan-request-title">
                  ថ្ងៃទី..................ខែ.......................ឆ្នាំ២០២២
                </Box>
              </Stack>
              <Box
                className="body-title-loant-request"
                sx={{ marginTop: "4px" }}
              >
                ហត្ថលេខាមន្ត្រីឥណទាន
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginTop: "80px" }}
                justifyContent="center"
              >
                <Box className="loan-request-title">ឈ្មោះ</Box>
                <Box className="loan-request-item">
                  ....................................
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "420px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
    </>
  );
}
