import { gql } from "@apollo/client";

export const CREATE_USERS = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UpdateUser($userId: ID, $input: UserInput) {
    updateUser(user_Id: $userId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_USERS = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(user_id: $userId) {
      isSuccess
      message
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUser {
    getAllUser {
      _id
      firsName
      lastName
      phone_number
      email
      role
      image_src
      image_name
      created_At
    }
  }
`;

export const GET_USER_WITH_PAGINATION = gql`
  query GetUserByPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getUserByPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        firsName
        lastName
        phone_number
        email
        role
        image_src
        image_name
        created_At
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      _id
      firsName
      lastName
      phone_number
      email
      role
      image_src
      image_name
      created_At
    }
  }
`;
