import React, { useContext, useState } from "react";
import "./loanlistaction.scss";
import {
  IconButton,
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
//components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { UPDATE_LOAN_STATUS_REJECT } from "../../../Schema/Loanlist";

export default function RejectLoan({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState("");
  const { setAlert } = useContext(AuthContext);

  const [updatePurposeRejection] = useMutation(UPDATE_LOAN_STATUS_REJECT, {
    onCompleted: ({ updatePurposeRejection }) => {
      if (updatePurposeRejection?.isSuccess) {
        setAlert(true, "success", updatePurposeRejection?.message);
        setRefetch();
        handleClose();
      } else {
        setAlert(true, "error", updatePurposeRejection?.message);
        handleClose();
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
    },
  });
  const handleReject = () => {
    // console.log("purpose::", purpose, editData?._id);
    updatePurposeRejection({
      variables: {
        updatePurposeRejectionId: editData?._id,
        purpose: purpose,
      },
    });
  };
  return (
    <Dialog open={open} onClose={handleClose} className="loanlist-action">
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("reject-loan")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="subtitle1"
          className={language === "kh" ? "simple-textKh" : "simple-textEn"}
        >
          {t("rejection-title")}
        </Typography>
        <TextField
          fullWidth
          multiline
          size="small"
          placeholder="reason"
          onChange={(e) => setPurpose(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              <Button
                className="btn"
                variant="outlined"
                fullWidth
                disabled={purpose === "" ? true : false}
                onClick={handleReject}
                sx={{ backgroundColor: purpose === "" ? "gray" : "red" }}
              >
                {loading ? (
                  "Loading..."
                ) : (
                  <Typography
                    className={language === "kh" ? "suspendKh" : "suspendEn"}
                  >
                    {t("reject-now")}
                  </Typography>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
