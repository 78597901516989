import * as React from "react";
import { Avatar, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import Profile from "./Profile";
import Cambodia from "../../Assets/Cambodia.png";
import UnitState from "../../Assets/United_Kingdom.png";
import { useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

export default function TopNavbar({
  prefersDarkMode,
  setPrefersDarkMode,
  handleDrawerOpen,
  open,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const getUserLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const theme = useTheme();
  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.localStorage.removeItem("users");
        window.localStorage.removeItem("userLogin");
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  // ================= Change Language =======================
  const { changeLanguage } = React.useContext(AuthContext);

  const [langauge, setLanguage] = useState("en");
  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    setLanguage(lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    let getLaung = window.localStorage.getItem("language");
    if (getLaung) {
      setLanguage(getLaung);
      changeLanguage(getLaung);
      return;
    } else {
      setLanguage("en");
      changeLanguage("en");
      window.localStorage.setItem("language", "en");
      return;
    }
  }, []);

  return (
    <>
      <Toolbar
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "#fff",
          borderBottom: theme.palette.mode === "dark" ? "1px solid #fff" : null,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon
              sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#0969A0" }}
            />
          </IconButton>

          <Stack
            direction="column"
            justifyContent="center"
            spacing={1}
            // display={getUserLogin?.role !== "employee" ? "none" : "flex"}
          >
            {langauge === "en" ? (
              <Stack direction="row">
                <IconButton onClick={() => handleChangeLanguage("kh")}>
                  <Avatar
                    src={UnitState}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography className="laguage-title">
                    {t("english")}
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
            {langauge === "kh" ? (
              <Stack direction="row">
                <IconButton onClick={() => handleChangeLanguage("en")}>
                  <Avatar
                    src={Cambodia}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography className="laguage-title">Khmer</Typography>
                </Stack>
              </Stack>
            ) : null}
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" spacing={1} justifyContent="right">
            {/* <IconButton onClick={() => setPrefersDarkMode(!prefersDarkMode)} color="inherit">
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon sx={{ color: "#0969A0" }}/>}
                        </IconButton>                            
                            */}
            {/* <IconButton>
              <NotificationsActiveOutlinedIcon
                sx={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#38B14A",
                }}
              />
            </IconButton> */}

            <Profile handleSignOut={handleSignOut} />
          </Stack>
        </Stack>
      </Toolbar>
    </>
  );
}
