import { gql } from "@apollo/client";

export const CREATE_LOANPLAN = gql`
  mutation CreateLoanPlan($input: LoanPlanInput) {
    createLoanPlan(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_LOANPLAN_WITH_PAGINATION = gql`
query GetLoanPlanByPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean, $typeOfPaymentPeriod: String) {
  getLoanPlanByPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination, type_of_payment_period: $typeOfPaymentPeriod) {
    data {
      _id
      month
      interest
      type_of_payment_period
      over_due_penalty
      remark
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const GET_LOANPLAN_BY_ID = gql`
  query GetLoanPlanById($getLoanPlanByIdId: ID) {
    getLoanPlanById(id: $getLoanPlanByIdId) {
      _id
      month
      interest
      type_of_payment_period
      over_due_penalty
      remark
    }
  }
`;

export const UPDATE_LOANPLAN = gql`
  mutation UpdateLoanPlan($updateLoanPlanId: ID, $input: LoanPlanInput) {
    updateLoanPlan(id: $updateLoanPlanId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_LOANPLAN = gql`
  mutation DeleteLoanPlan($deleteLoanPlanId: ID) {
    deleteLoanPlan(id: $deleteLoanPlanId) {
      isSuccess
      message
    }
  }
`;
