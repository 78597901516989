import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//components
import "./forgotpassword.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../context/AuthContext";
import app from "../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);

  const [iconReload, setIconReload] = useState(false);

  const auth = getAuth(app);
  // console.log("auth::", auth)

  const forgotSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email!").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          setAlert(true,"success", "Please check message in your email!");
          setTimeout(() => {
            setIconReload(false);
            navigate("/");
          }, 1200);
        })
        .catch((error) => {
          setAlert(true, "error", "Invalid Email/Password!");
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="forgot-page">
            <Box className="background-image" />
            <Stack direction="row" sx={{ ml: 10 }} className="back-btn">
              <Stack direction="column" justifyContent="center">
                <IconButton id="back-button" onClick={() => navigate("/login")}>
                  <ArrowBackIcon sx={{ color: "#000" }} />
                </IconButton>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                className="text-back"
              >
                <label for="back-button">
                  <Typography className="title-back" align="center">
                    Back
                  </Typography>
                </label>
              </Stack>
            </Stack>
            <Box className="container">

              <Box className="box-text" sx={{ mt: 1 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  spacing={1}
                  className="title-login"
                >
                  <Typography className="title">
                    Welcome to Go Credit Loan
                  </Typography>
                  <Typography
                    className="sub-title"
                    variant="body2"
                    align="center"
                  >
                    Please input your email to recieve a link in order to change
                    password!
                  </Typography>
                </Stack>
              </Box>

              <Box className="box-login" sx={{ mt: 3 }}>
                <Stack direction="column" justifyContent="center" spacing={2}>
                  <TextField
                    className="text-field"
                    size="small"
                    placeholder="example@company.com"
                    fullWidth
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon className="icon" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    className="btb-sign-in"
                    type="submit"
                    endIcon={
                      iconReload ? (
                        <CircularProgress
                          sx={{ color: "#0f81c2" }}
                          size="20px"
                        />
                      ) : null
                    }
                  >
                    send
                  </Button>
                </Stack>
              </Box>
            </Box>
            <Typography
              variant="body2"
              align="center"
              color="#000"
              sx={{  letterSpacing: "2px" }}
            >
              @Copyright 2022, Go Credit Loan
            </Typography>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
