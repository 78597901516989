import React, { useState, useEffect, useRef } from "react";
import "./loanrequestdetails.scss";
import { Grid, Stack, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { getKhmerNumber } from "../../../Function/GetKhmerNumber";
import { getKhmerMonth } from "../../../Function/GetKhmerNumber";
import { getKhmerDay } from "../../../Function/GetKhmerNumber";
import { getFormattedPhoneNum } from "../../../Function/GetKhmerNumber";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";

export default function DebtGuaranteeAgreement({ tableData }) {
  const [loading, setLoading] = useState(false);

  const [khmerDateString, setKhmerDateString] = useState("");

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("tableData::", tableData);

  return (
    <React.Fragment>
      <div
        className="print-loan-request-container"
        style={{ marginTop: "30px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box className="logo-container">
              <img src={logoCompany} className="logo-company" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center">
              <Box>
                <div className="body-title-loant-request">
                  ព្រះរាជាណាចក្រកម្ពុជា
                </div>
                <div className="body-title-loant-request">
                  ជាតិ សាសនា ព្រះមហាក្សត្រ
                </div>
                <div className="loan-request-font-tackteng">
                  <img
                    alt="Image"
                    src={wingdingImage}
                    className="loan-request-image-tackteng"
                  />
                </div>
              </Box>
            </Stack>
            <Box
              className="body-title-loant-request"
              sx={{ marginTop: "60px" }}
            >
              កិច្ចព្រមព្រៀងធានាបំណុល
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>

        {tableData?.loan_guarantor?.loan_guarantor_id === null ? (
          <React.Fragment>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title" ml="40px">
                ឈ្មោះ
              </Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">អក្សរឡាតាំង</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ភេទ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">សញ្ជាតិ</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ស្ថានភាពគ្រួសារ</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
              <Box className="loan-request-item">{`ភូមិ ..........`}</Box>
              <Box className="loan-request-item">{`ឃុំ ..........`}</Box>
              <Box className="loan-request-item">{`ស្រុក ..........`}</Box>
              <Box className="loan-request-item">{`ខេត្ត ..........`}</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ត្រូវជា</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">អ្នកខ្ចីប្រាក់ ។</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">លេខទូរស័ព្ទក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ប្រភេទអាជីវកម្ម</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Box className="loan-request-title">អាសយដ្ឋានក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">មុខតំណែង</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">ប្រាក់ចំណូលប្រចាំខែ</Box>
              <Box className="loan-request-item">..........</Box>
              <Box className="loan-request-title">
                កាលបរិច្ឆេទបើកប្រាក់បៀវត្ស
              </Box>
              <Box className="loan-request-item">..........</Box>
            </Stack>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title" ml="40px">
                ឈ្មោះ
              </Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.first_name +
                  " " +
                  tableData?.loan_guarantor?.loan_guarantor_id?.last_name}
              </Box>
              <Box className="loan-request-title">អក្សរឡាតាំង</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.latin_alphabet}
              </Box>
              <Box className="loan-request-title">ភេទ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.gender}
              </Box>
              <Box className="loan-request-title">សញ្ជាតិ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.nationality}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  tableData?.loan_guarantor?.loan_guarantor_id?.ID_card_number
                )}
              </Box>
              <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
              <Box className="loan-request-item">
                {`${getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("DD")
                )} ${getKhmerMonth(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("M")
                )} ${getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("YYYY")
                )}`}
              </Box>
              <Box className="loan-request-title">ស្ថានភាពគ្រួសារ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.family_status}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
              <Box className="loan-request-item">{`ភូមិ ${tableData?.loan_guarantor?.loan_guarantor_id?.current_address?.villege}`}</Box>
              <Box className="loan-request-item">{`ឃុំ ${tableData?.loan_guarantor?.loan_guarantor_id?.current_address?.commune}`}</Box>
              <Box className="loan-request-item">{`ស្រុក ${tableData?.loan_guarantor?.loan_guarantor_id?.current_address?.district}`}</Box>
              <Box className="loan-request-item">{`ខេត្ត ${tableData?.loan_guarantor?.loan_guarantor_id?.current_address?.province}`}</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  getFormattedPhoneNum(
                    tableData?.loan_guarantor?.loan_guarantor_id?.phone_number
                  )
                )}
              </Box>
              <Box className="loan-request-title">ត្រូវជា</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.borrower_connection}
              </Box>
              <Box className="loan-request-title">អ្នកខ្ចីប្រាក់ ។</Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">ឈ្មោះក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.company_name}
              </Box>
              <Box className="loan-request-title">លេខទូរស័ព្ទក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  getFormattedPhoneNum(
                    tableData?.loan_guarantor?.loan_guarantor_id
                      ?.phone_number_company
                  )
                )}
              </Box>
              <Box className="loan-request-title">ប្រភេទអាជីវកម្ម</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.type_of_business}
              </Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Box className="loan-request-title">អាសយដ្ឋានក្រុមហ៊ុន</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.address_company}
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box className="loan-request-title">មុខតំណែង</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.positions}
              </Box>
              <Box className="loan-request-title">ប្រាក់ចំណូលប្រចាំខែ</Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  tableData?.loan_guarantor?.loan_guarantor_id?.monthly_income
                )}{" "}
                ដុល្លា
              </Box>
              <Box className="loan-request-title">
                កាលបរិច្ឆេទបើកប្រាក់បៀវត្ស
              </Box>
              <Box className="loan-request-item">
                {`${getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id
                      ?.salary_payment_date
                  ).format("DD")
                )} ${getKhmerMonth(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id
                      ?.salary_payment_date
                  ).format("M")
                )} ${getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id
                      ?.salary_payment_date
                  ).format("YYYY")
                )}`}
              </Box>
            </Stack>
          </React.Fragment>
        )}

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">និងឈ្មោះ</Box>
          <Box className="loan-request-item">
            ....................................
          </Box>
          <Box className="loan-request-title">ភេទ</Box>
          <Box className="loan-request-item">.........</Box>
          <Box className="loan-request-title">សញ្ជាតិ</Box>
          <Box className="loan-request-item">............</Box>
          <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
          <Box className="loan-request-item">
            .................................
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
          <Box className="loan-request-item">
            ................................
          </Box>
          <Box className="loan-request-title">លេខទូរស័ព្ទ</Box>
          <Box className="loan-request-item">
            ................................
          </Box>
          <Box className="loan-request-title">ត្រូវជា</Box>
          <Box className="loan-request-item">..........................</Box>
          <Box className="loan-request-title">អ្នកខ្ចីប្រាក់</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">មុខរបរ</Box>
          <Box className="loan-request-item">
            ........................................
          </Box>
          <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
          <Box className="loan-request-item">
            ..............................................................................
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" ml="40px">
            ក្រោយពីបានអានកិច្ចសន្យាខ្ចីចងការប្រាក់ចុះថ្ងៃទីខែឆ្នាំ ៖
          </Box>
          <Box className="loan-request-item">........................</Box>
          <Box className="loan-request-title">របស់ឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.first_name +
              " " +
              tableData?.customer_id?.last_name}
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">និងឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.borrower_together?.borrower_together_id === null
              ? "............................"
              : tableData?.borrower_together?.borrower_together_id?.first_name +
                " " +
                tableData?.borrower_together?.borrower_together_id?.last_name}
          </Box>
          <Box className="loan-request-title">
            ជាភាគី <span className="loan-request-title-bold">“ខ”</span>{" "}
            ដែលជាកូនបំណុលរបស់{" "}
            <span className="loan-request-title-bold">
              ហោ្គក្រេឌីត ភីអិលស៊ី
            </span>{" "}
            ជាភាគី <span className="loan-request-title-bold">“ក”</span> យើង
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ខ្ញុំបានយល់ច្បាស់ពីខ្លឹមសារគ្រប់ចំណុចទាំងអស់ដែលមានចែងក្នុងកិច្ចសន្យាខ្ចីចងការប្រាក់ហើយបានព្រមព្រៀងគ្នា
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ធានាបំណុលទាំងស្រុងរបស់ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            ដោយយល់ព្រមចុះកិច្ចព្រមព្រៀងធានាបំណុល
          </Box>
          <Box className="loan-request-title">និងអនុវត្តតាមរាល់ប្រការដូច</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ខាងក្រោម ៖</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title-bold" sx={{ marginLeft: "40px" }}>
            ប្រការ១៖
          </Box>
          <Box className="loan-request-title">យើងខ្ញុំ</Box>
          <Box className="loan-request-title">
            សូមធានាអះអាងលើបំណុលនេះយ៉ាងពិតប្រាកដថា
          </Box>
          <Box className="loan-request-title">ប្រសិនបើភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">មិនបានអនុវត្តឱ្យ</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            បានត្រឹមត្រូវតាមកិច្ចសន្យាខ្ចីចងការប្រាក់ដោយប្រការណាមួយនោះ
          </Box>
          <Box className="loan-request-title">
            យើងខ្ញុំសូមធានាចេញសងបំណុលនេះទាំងស្រុង
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ដោយគ្មានលក្ខខណ្ឌ។</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title-bold" sx={{ marginLeft: "40px" }}>
            ប្រការ២៖
          </Box>
          <Box className="loan-request-title">
            ដើម្បីជាភស្តុតាងបញ្ជាក់លើកិច្ចព្រមព្រៀងធានាបំណុល
          </Box>
          <Box className="loan-request-title">
            យើងខ្ញុំសូមដាក់ធានានូវទ្រព្យសម្បត្តិដូច
          </Box>
        </Stack>
        <Box className="loan-request-title">ខាងក្រោម ៖</Box>

        <Stack direction="row" justifyContent="center">
          <div className="loan-table-container">
            <table className="loan-table-agreement">
              <tr style={{ backgroundColor: "#eaeefa" }}>
                <th className="interest-loan">អំពីទ្រព្យដាក់ធានា</th>
                <th className="totalpayment-loan">ប្រភេទទី១</th>
                <th className="restofmoney-loan">ប្រភេទទី២</th>
              </tr>
              <tr>
                <td className="loan-table-agreement-row">
                  ប្រភេទទ្រព្យដាក់ធានា
                </td>
                <td className="loan-table-agreement-row"></td>
                <td className="loan-table-agreement-row"></td>
              </tr>
              <tr>
                <td className="loan-table-agreement-row">ទំហំ</td>
                <td className="loan-table-agreement-row"></td>
                <td className="loan-table-agreement-row"></td>
              </tr>
            </table>
          </div>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            បើភាគី
          </Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            ខាងលើមិនអនុវត្តកាតព្វកិច្ចសងបំណុលតាមកិច្ចសន្យា
            យើងខ្ញុំសុខចិត្តលក់ទ្រព្យដាក់ធានាខាង
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">លើ</Box>
          <Box className="loan-request-title">ឬ</Box>
          <Box className="loan-request-title">
            យល់ព្រមប្រគល់ទ្រព្យដាក់ធានានេះជូនភាគី
          </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">
            តែមួយគត់ដើម្បីចាត់ចែងលក់ឡាយឡុងយកប្រាក់មកសង
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">បំណុលរបស់</Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            ដែលជំពាក់ទាំងស្រុង រួមទាំងប្រាក់ដើមប្រាក់ការ និងប្រាក់ពិន័យផ្សេងទៀត
            ។ ក្រោយពីការ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទូទាត់បំណុលជូនភាគី</Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">
            ទាំងស្រុងហើយថវិកាដែលនៅសល់ត្រូវប្រគល់ជូនអ្នកធានាបំណុលខាងលើ ។
            បញ្ជាក់ថា
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ភាគីដទៃទៀតរួមទាំងម្ចាស់បំណុលផ្សេងទៀតពុំមានសិទ្ធិរារាំងភាគី
          </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">
            ក្នុងការលក់ឡាយឡុងទ្រព្យដាក់ធានាដូចមាន
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">រៀបរាប់ខាងលើនេះទេ</Box>
          <Box className="loan-request-title">។</Box>
          <Box className="loan-request-title">
            ក្នុងករណីមិនទាន់សងគ្រប់ចំនួនយើងខ្ញុំត្រូវបន្តកាតព្វកិច្ចសងបំណុលដែលនៅសល់បន្ថែម
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទៀតរហូតទាល់តែគ្រប់ចំនួន។</Box>
        </Stack>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "20px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
      <div className="print-loan-request-container">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: "40px" }}
        >
          <Box className="loan-request-title">
            ប្រសិនបើយើងខ្ញុំប្រព្រឹត្តប្រាសចាកពីកិច្ចព្រមព្រៀងធានាបំណុលខាងលើ
          </Box>
          <Box className="loan-request-title">
            យើងខ្ញុំសូមទទួលខុសត្រូវចំពោះមុខច្បាប់
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទាំងស្រុង ។</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            យើងខ្ញុំ សូមផ្តិតមេដៃស្តាំទុកជាភស្តុតាង តាមផ្លូវច្បាប់ ។
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            កិច្ចព្រមព្រៀងនេះធ្វើឡើងជាភាសាខ្មែរចំនួន
          </Box>
          <Box className="loan-request-title">
            0២ច្បាប់ដើមមានតម្លៃស្មើគ្នាចំពោះមុខច្បាប់
          </Box>
          <Box className="loan-request-title">និងរក្សាទុកនៅ</Box>
        </Stack>
        <Box className="loan-request-title">
          ភាគី <span className="loan-request-title-bold">“ក”</span>{" "}
          0១(មួយ)ច្បាប់ និងភាគី{" "}
          <span className="loan-request-title-bold">“ខ”</span> 0១(មួយ) ច្បាប់ ។
        </Box>
        {/* ======================================================= footer ====================================================== */}
        <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
          សូមអ្នកគ្រប់គ្រង មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីយើងខ្ញុំ ។
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={9}>
              <Stack direction="row" justifyContent="center">
                <Box className="loan-request-title">
                  សៀមរាប
                  ថ្ងៃទី..................ខែ.......................ឆ្នាំ២០២
                </Box>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    className="body-title-loant-request"
                    sx={{ marginTop: "4px" }}
                  >
                    ស្នាមមេដៃអ្នករួមធានា
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginTop: "120px" }}
                    justifyContent="center"
                  >
                    <Box className="loan-request-title">ឈ្មោះ</Box>
                    <Box className="loan-request-item">
                      ....................................
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    className="body-title-loant-request"
                    sx={{ marginTop: "4px" }}
                  >
                    ស្នាមមេដៃអ្នកធានា
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginTop: "120px" }}
                    justifyContent="center"
                  >
                    <Box className="loan-request-title">ឈ្មោះ</Box>
                    <Box className="loan-request-item">
                      ....................................
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "580px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
    </React.Fragment>
  );
}
