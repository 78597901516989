import React from "react";
import Groups2Icon from "@mui/icons-material/Groups2";

import { Grid, Stack, Box, Avatar, Typography } from "@mui/material";

import "./loancard.scss";

import flower from "../../../Assets/icons-01.png";

// Icon
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBill1Wave } from "react-icons/fa6";

import SavingsIcon from '@mui/icons-material/Savings';
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";

export default function CardDasboard() {
  const cardData = [
    {
      Title: "Total",
      Amount: "10000$",
      People: "100",
      Icon: <Groups2Icon />,
      BgColor: "#3c64f6",
    },
  ];
  return (
    <Box className="card-loan-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Stack
            direction={`row`}
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Stack direction={`row`} spacing={1.5} alignItems={`center`}>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
              >
                <img
                src={flower}
                alt="flower"
                style={{ width: "32px", height: "32px" }}
              />
              </Stack>
              <Typography className="loan-title">Loan</Typography>
            </Stack>
            <Typography className="people-txt"> 57 People</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-principal"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Principal</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <PointOfSaleIcon className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Total in Cash</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-out"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Cash Out</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <FaMoneyBillWave className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Total in Cash</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-in"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Cash In</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <FaMoneyBill1Wave className="ics-in" />
              </Stack>
            </Stack>
            <Stack
              direction={`row`}
              justifyContent={`space-between`}
              alignItems={`center`}
              width={`100%`}
            >
              <Stack direction={`column`} width={`50%`}>
                <Typography className="sub-txt">Principal</Typography>
                <Typography className="amount-txt">$ 36,059.00</Typography>
              </Stack>
              <Stack direction={`column`} width={`50%`}>
                <Typography className="sub-txt">Interest</Typography>
                <Typography className="amount-txt">$ 36,059.00</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-current-total"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Current Total Principal</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <SavingsIcon className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Total in Cash</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-out-td"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Cash Out Today</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <GiReceiveMoney className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Total in Cash</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-in-td"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Cash In Today</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <GiPayMoney className="ics-in" />
              </Stack>
            </Stack>
            <Stack
              direction={`row`}
              justifyContent={`space-between`}
              alignItems={`center`}
              width={`100%`}
            >
              <Stack direction={`column`} width={`50%`}>
                <Typography className="sub-txt">Principal</Typography>
                <Typography className="amount-txt">$ 36,059.00</Typography>
              </Stack>
              <Stack direction={`column`} width={`50%`}>
                <Typography className="sub-txt">Interest</Typography>
                <Typography className="amount-txt">$ 36,059.00</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
