import React from "react";
import Groups2Icon from "@mui/icons-material/Groups2";

import { Grid, Stack, Box, Avatar, Typography } from "@mui/material";

import "./pawncard.scss";

import pot from "../../../Assets/icons-02.png";

// Icon
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

export default function PawnCard() {
  const cardData = [
    {
      Title: "Total",
      Amount: "10000$",
      People: "100",
      Icon: <Groups2Icon />,
      BgColor: "#3c64f6",
    },
  ];
  return (
    <Box className="card-pawn-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Stack
            direction={`row`}
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Stack direction={`row`} spacing={1.5} alignItems={`center`}>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
              >
                <img
                  src={pot}
                  alt="pot"
                  style={{ width: "32px", height: "32px" }}
                />
                {/* <Groups2Icon className="ics" /> */}
              </Stack>
              <Typography className="pawn-title">Mortgage</Typography>
            </Stack>
            <Typography className="people-txt"> 57 People</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Stack
            direction={`column`}
            className="card-box-total-paw"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Total Pawn</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <PointOfSaleIcon className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Total in Cash</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Stack
            direction={`column`}
            className="card-box-total-paw-out"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Current Pawn</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <PointOfSaleIcon className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Cash Out</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Stack
            direction={`column`}
            className="card-box-total-paw-in"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography className="title-txt">Current Paid</Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                <PointOfSaleIcon className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography className="sub-txt">Cash In</Typography>
              <Typography className="amount-txt">$ 36,059.00</Typography>
            </Stack>
          </Stack>
        </Grid>
        
      </Grid>
    </Box>
  );
}
