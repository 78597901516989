import * as React from "react";
import generatePdfThumbnails from "pdf-thumbnails-generator";

export default function FileThumnail({ pdfFile }) {
  const [thumnail, setThumnail] = React.useState("");

  async function generateThumbnails(pdf) {
    try {
      // const file = await urlToObject(pdfFile)
      let thumbnails = await generatePdfThumbnails(pdf, 200);
      // console.log("thumbnails::", thumbnails);
      setThumnail(thumbnails[0].thumbnail);
    } catch (err) {
      console.error(err, "err document");
    }
  }

  React.useEffect(() => {
    if (pdfFile) {
      generateThumbnails(pdfFile);
    }
  }, [pdfFile]);

  return (
    <img
      src={thumnail}
      alt="document"
      style={{ width: "100%" }}
      className="thumbnail-img"
    />
  );
}
