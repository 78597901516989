import React, { useContext, useState } from "react";
import "./loanlistaction.scss";
import { IconButton, Typography, Stack, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
//components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import UpdateLoanlist from "../UpdateLoanlist/UpdateLoanlist";
import UpdateInstallment from "../UpdateInstallment/UpdateInstallment";
import DeleteLoanlist from "../DeleteLoanlist/DeleteLoanlist";
import RejectLoan from "./RejectLoan";
import FirstApproveLoan from "./ApproveLoan";

export default function FirstLoanAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  // console.log(noneUserAccesse(["Admin"]))
  const [anchorEl, setAnchorEl] = useState(null);

  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal reject
  const [openReject, setOpenReject] = useState(false);
  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);

  //Modal First Approve
  const [openFirstApprove, setOpenFirstApprove] = useState(false);
  const handleOpenFirstApprove = () => setOpenFirstApprove(true);
  const handleCloseFirstApprove = () => setOpenFirstApprove(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("editData::", editData?.status);

  return (
    <div>
      {editData?.active === "Suspended" ||
      editData?.active === "Completed" ||
      editData?.status === "Reject" ? null : (
        <IconButton onClick={handleClick}>
          <MoreVertIcon className="three-point" />
        </IconButton>
      )}
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        {editData?.status === "Request" ? (
          <MenuItem
            onClick={() => {
              handleOpenEdit();
              handleClose();
            }}
          >
            <Stack direction="row" spacing={1}>
              <BorderColorOutlinedIcon className="icon-menu-update" />
              <Typography
                className={language === "kh" ? "text-menuKh" : "text-menuEn"}
              >
                {t("update")}
              </Typography>
            </Stack>
          </MenuItem>
        ) : null}

        {editData?.status === "Request" ? (
          <MenuItem
            onClick={() => {
              handleOpenDel();
              handleClose();
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteForeverOutlinedIcon className="icon-menu-delete" />
              <Typography
                className={language === "kh" ? "text-menuKh" : "text-menuEn"}
              >
                {t("delete")}
              </Typography>
            </Stack>
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            handleOpenFirstApprove();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <RadioButtonCheckedOutlinedIcon className="icon-menu-update" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {t("approve")}
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenReject();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <RadioButtonCheckedOutlinedIcon className="icon-menu-delete" />
            <Typography
              className={language === "kh" ? "text-menuKh" : "text-menuEn"}
            >
              {t("reject")}
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      {editData?.type_of_loan === "Installments" ? (
        <UpdateInstallment
          open={openEdit}
          handleClose={handleCloseEdit}
          editData={editData}
          setRefetch={setRefetch}
        />
      ) : (
        <UpdateLoanlist
          open={openEdit}
          handleClose={handleCloseEdit}
          editData={editData}
          setRefetch={setRefetch}
        />
      )}

      <DeleteLoanlist
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />

      <FirstApproveLoan
        open={openFirstApprove}
        handleClose={handleCloseFirstApprove}
        editData={editData}
        setRefetch={setRefetch}
      />

      <RejectLoan
        open={openReject}
        handleClose={handleCloseReject}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
