import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Avatar, Typography } from "@mui/material";

import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

import LoanCard from "../../Component/NewDashboard/LoanCard/LoanCard";
import PawnCard from "../../Component/NewDashboard/PawnCard/PawnCard";
import Installment from "../../Component/NewDashboard/Installment/Installment";

export default function NewDashboard() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <div className="dashboard-page">
      <Stack
        direction="row"
        spacing={2}
        sx={{ height: "30px" }}
        alignItems={`center`}
      >
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("dashboard")}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <Grid container spacing={2} className="container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <LoanCard />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <PawnCard />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Installment />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
          <Box sx={{ bgcolor: "white", height: "100%" }}>
            <Typography>Table Here</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
