import React, { useContext, useState } from "react";
import "./chart.scss";
import { Stack } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { GET_REPORT_COSTMER_CHART } from "../../../Schema/Dashboard";

export default function Chart() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_REPORT_COSTMER_CHART, {
    onCompleted: ({ getChartDashboard }) => {
      setTableData(getChartDashboard);
      // console.log(getChartDashboard);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("lateData::", typeof tableData?.loanChart?.lateData[0]);

  const state = {
    series: [
      {
        name: t("late"),
        data: [
          tableData?.loanChart?.lateData[0].toFixed(2),
          tableData?.loanChart?.lateData[1].toFixed(2),
          tableData?.loanChart?.lateData[2].toFixed(2),
        ],
      },
      {
        name: t("active"),
        data: [
          tableData?.loanChart?.activeData[0].toFixed(2),
          tableData?.loanChart?.activeData[1].toFixed(2),
          tableData?.loanChart?.activeData[2].toFixed(2),
        ],
      },
      {
        name: t("difficult"),
        data: [
          tableData?.loanChart?.difficultData[0].toFixed(2),
          tableData?.loanChart?.difficultData[1].toFixed(2),
          tableData?.loanChart?.difficultData[2].toFixed(2),
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [t("loan"), t("pawn"), t("installment")],
      },
      yaxis: {
        title: {
          text: "($)",
        },
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <Stack className="chart">
      <Stack direction="row" spacing={2}></Stack>
      <Stack id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={300}
        />
      </Stack>
    </Stack>
  );
}
