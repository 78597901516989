import React, { useState, useEffect } from "react";
import "./referenceinfo.scss";
import { Box, Grid, Typography, Stack, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
//Component
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import FileThumnail from "../../../Function/FileThumnail";
import ViewFilePreview from "../../../Function/ViewFilePreview";

export default function ReferenceInfo({ customerData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [pdfData, setPdfData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = (data) => {
    setPdfData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="reference-info">
      {/* <Stack direction="row" justifyContent="center">
        <Typography>Documents</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          className={language === "kh" ? "btn-addKh" : "btn-addEn"}
          startIcon={<AddIcon className="icon-add" />}
        >
          {t("add")}
        </Button>
      </Stack> */}
      {/* <Divider /> */}

      <Grid container spacing={4}>
        {customerData?.reference_docs?.map((data, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <Stack direction="column" spacing={1} className="reference-list">
                <Stack
                  className="profile"
                  direction="row"
                  justifycontent="center"
                >
                  <Button onClick={() => handleOpen(data)}>
                    <FileThumnail pdfFile={data?.document_src} />
                  </Button>
                </Stack>
                <Stack direction="row" justifyContent="center">
                  <Typography>
                    {data?.document_name === "" ? "file name" : data?.document_name}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>

      <ViewFilePreview
        readPdf={pdfData}
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
}
