import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from '@mui/material/DialogContentText';
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Document, Page, pdfjs } from "react-pdf";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import "./viewfilepreview.scss";

export default function ViewFilePreview({ open, handleClose, readPdf }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      className="preview-pdf"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "KhmerOsSiemreap" }}
            >
              {readPdf?.document_name}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <a href={readPdf?.document_src} target="_blank">
              <CloudDownloadOutlinedIcon sx={{ color: "#38b14a", mt: 1 }} />
            </a>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={handleClose} sx={{ color: "red" }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Box className="containter">
          <Document
            file={readPdf?.document_src}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
          </Document>
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction="column" justifyContent="center" width="100%">
          <Typography textAlign="center">
            Page {pageNumber} of {numPages}
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {pageNumber > 1 && (
              <Button
                variant="outlined"
                onClick={changePageBack}
                sx={{ color: "#007654", border: "1px solid #fff" }}
              >
                Previous Page
              </Button>
            )}

            {pageNumber < numPages && (
              <Button
                sx={{ ml: 1, color: "#007654", border: "1px solid #fff" }}
                variant="outlined"
                onClick={changePageNext}
              >
                Next Page
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
