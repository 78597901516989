import React, { useState } from "react";
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  Avatar,
  MenuItem,
  Typography,
  Skeleton,
  Stack,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function Profile({ handleSignOut }) {
  const [dataLogin, setDataLogin] = useState(
    JSON.parse(window.localStorage.getItem("userLogin"))
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <IconButton>
          <Avatar
            sx={{ width: 40, height: 40 }}
            src={`${dataLogin?.image_src}`}
            alt={`${dataLogin?.firsName + " " + dataLogin?.lastName}`}
          />
        </IconButton>

        <Stack direction="column" justifyContent="center">
          <Button
            onClick={handleClick}
            sx={{ padding: "5px 20px" }}
            endIcon={
              open ? (
                <ArrowDropUpIcon sx={{ color: "#38b14a" }} />
              ) : (
                <ArrowDropDownIcon sx={{ color: "#38b14a" }} />
              )
            }
          >
            {dataLogin?.lastName === undefined ? (
              <Typography sx={{ width: "100px" }}>
                <Skeleton width="100%" />
              </Typography>
            ) : (
              <Typography className="username-login">
                {dataLogin?.firsName + " " + dataLogin?.lastName}
              </Typography>
            )}
          </Button>
        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
