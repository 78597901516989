import React, { useContext, useMemo } from "react";
import "./App.css";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AlertMessage from "./Component/AlertMessage/AlertMessage";
import { setContext } from "@apollo/client/link/context";
import { AuthContext } from "./context/AuthContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";

function App() {
  const { state } = useContext(AuthContext);
  const { user } = state;

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
          background: {
            default: "#F4F6F6",
          },
        },
        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    []
  );

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_END_POINT,
    // uri: "http://192.168.2.18:3100/graphql",
  });

  // console.log("user::=>", user);
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: user ? user?.token : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routers>
          <Router />
        </Routers>
        <CssBaseline />
      </ThemeProvider>
      <AlertMessage />
    </ApolloProvider>
  );
}

export default App;
