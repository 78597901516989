import React, { useState, useEffect, useContext } from "react";
import "./adjustmentloan.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  FormHelperText,
  Switch,
  Divider,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import moment from "moment";
//date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//Src
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { UPDATE_ADJUSTMENT_LOAN } from "../../../Schema/Loanlist";

export default function AdjustmentLoan({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const [totalPayable, setTotalPayable] = useState(0);
  const [checkTotal, setCheckTotal] = useState(0);

  const { setAlert } = useContext(AuthContext);

  // ===================================== setEditData into field ===========================================
  // console.log("editData::=>", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue(
        "interest_adjustment",
        editData?.adjustment?.interest_adjustment !== null
          ? editData?.adjustment?.interest_adjustment * 100
          : 0
      );
      setFieldValue(
        "duration_adjustment",
        editData?.adjustment?.duration_adjustment !== null
          ? editData?.adjustment?.duration_adjustment
          : 0
      );
      setFieldValue(
        "amount_adjustment_letter",
        editData?.adjustment?.amount_adjustment_letter !== null
          ? editData?.adjustment?.amount_adjustment_letter
          : ""
      );
      setFieldValue(
        "loan_date",
        editData?.adjustment?.loan_date !== null
          ? moment(editData?.adjustment?.loan_date)
          : moment()
      );
      setFieldValue(
        "payment_method",
        editData?.adjustment?.payment_method !== null
          ? editData?.adjustment?.payment_method
          : "Fixed"
      );
      setFieldValue(
        "payment_term",
        editData?.adjustment?.payment_term !== null
          ? editData?.adjustment?.payment_term
          : "Month"
      );
      setFieldValue(
        "is_first_month",
        editData?.adjustment?.is_first_month !== null
          ? editData?.adjustment?.is_first_month
          : true
      );
      setFieldValue(
        "first_month_date",
        editData?.adjustment?.first_month_date !== null
          ? moment(editData?.adjustment?.first_month_date)
          : moment()
      );
    }
  }, [editData]);

  // console.log("totalPayable::=>", totalPayable);

  // ============================== Mutation update loan ===========================================

  const [updateLoanAdjustment] = useMutation(UPDATE_ADJUSTMENT_LOAN, {
    onCompleted: ({ updateLoanAdjustment }) => {
      if (updateLoanAdjustment?.isSuccess) {
        setAlert(true, "success", updateLoanAdjustment?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateLoanAdjustment?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  // ============================== Formik ==============================================
  const AddLoanList = Yup.object().shape({
    interest_adjustment: Yup.string().required("require!"),
    duration_adjustment: Yup.string().required("require!"),
    amount_adjustment_letter: Yup.string().required("require!"),
    loan_date: Yup.date(),
    payment_method: Yup.string().required("require!"),
    payment_term: Yup.string().required("require!"),
    is_first_month: Yup.string(),
    first_month_date: Yup.date(),
  });

  const formik = useFormik({
    initialValues: {
      interest_adjustment: 0,
      duration_adjustment: 0,
      amount_adjustment_letter: "",
      loan_date: moment(),
      payment_method: "Fixed",
      payment_term: "Month",
      is_first_month: true,
      first_month_date: moment(),
    },

    validationSchema: AddLoanList,
    onSubmit: async (values) => {
      let newLoanValues = {
        interest_adjustment: values?.interest_adjustment,
        duration_adjustment: values?.duration_adjustment,
        amount_adjustment_letter: values?.amount_adjustment_letter,
        loan_date: values?.loan_date,
        payment_method:
          values?.payment_method === "Fixed" ? values?.payment_method : false,
        payment_term: values?.payment_term,
        is_first_month: values?.is_first_month,
        first_month_date:
          values?.is_first_month === true && values?.payment_method === "Fixed"
            ? values?.first_month_date
            : "",
      };

      // console.log("newLoanValues:::", newLoanValues);

      updateLoanAdjustment({
        variables: {
          updateLoanAdjustmentId: editData?._id,
          input: {
            adjustment: newLoanValues,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  // ================================== Calculate function ===========================================

  const handleCalculate = () => {
    setTotalPayable(
      editData?.remaining_amount / values?.duration_adjustment +
        (editData?.remaining_amount * values?.interest_adjustment) / 100
    );
    setCheckTotal(
      editData?.remaining_amount / values?.duration_adjustment +
        (editData?.remaining_amount * values?.interest_adjustment) / 100
    );
  };

  useEffect(() => {
    if (
      editData?.remaining_amount / values?.duration_adjustment +
        (editData?.remaining_amount * values?.interest_adjustment) / 100 !==
        checkTotal &&
      checkTotal !== 0 &&
      editData?.remaining_amount !== ""
    ) {
      setTotalPayable(
        editData?.remaining_amount / values?.duration_adjustment +
          (editData?.remaining_amount * values?.interest_adjustment) / 100
      );
    } else if (
      editData?.remaining_amount / values?.duration_adjustment +
        (editData?.remaining_amount * values?.interest_adjustment) / 100 ===
        checkTotal &&
      checkTotal !== 0 &&
      editData?.remaining_amount !== ""
    ) {
      setTotalPayable(checkTotal);
    } else {
      setTotalPayable(0);
    }
  }, [
    editData?.remaining_amount,
    values?.duration_adjustment,
    values?.interest_adjustment,
  ]);

  return (
    <Dialog
      open={open}
      className="adjustment-loan"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("adjustment-loan")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-date")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values?.loan_date}
                    onChange={(newValue) => {
                      setFieldValue("loan_date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="text-field"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("payment-method")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.payment_method}
                    onChange={(event) =>
                      setFieldValue("payment_method", event.target.value)
                    }
                  >
                    <MenuItem value={"Decrease"}>Decrease</MenuItem>
                    <MenuItem value={"Fixed"}>Fixed</MenuItem>
                  </Select>
                </FormControl>
                {!!errors.payment_method && touched.payment_method && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.payment_method}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("payment-term")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    {...getFieldProps("payment_term")}
                    error={Boolean(touched.payment_term && errors.payment_term)}
                    helperText={touched.payment_term && errors.payment_term}
                  >
                    <MenuItem value={"Month"}>Month</MenuItem>
                    <MenuItem value={"Week"}>Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={values?.is_first_month === true ? 6 : 12}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("is-first-month")}
                </Typography>
                <Switch
                  checked={values?.is_first_month}
                  onChange={(e) =>
                    setFieldValue("is_first_month", e?.target.checked)
                  }
                />
              </Grid>
              {values?.is_first_month === true ? (
                <Grid item xs={6}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("first-month-date")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values?.first_month_date}
                      onChange={(newValue) => {
                        setFieldValue("first_month_date", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className="text-field"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-amount")}
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="loan amount"
                  value={editData?.remaining_amount.toFixed(3)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("amount-letters")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="amount letters"
                  {...getFieldProps("amount_adjustment_letter")}
                  error={Boolean(
                    touched.amount_adjustment_letter &&
                      errors.amount_adjustment_letter
                  )}
                  helperText={
                    touched.amount_adjustment_letter &&
                    errors.amount_adjustment_letter
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("month-num")}
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="duration adjustment"
                  {...getFieldProps("duration_adjustment")}
                  error={Boolean(
                    touched.duration_adjustment && errors.duration_adjustment
                  )}
                  helperText={
                    touched.duration_adjustment && errors.duration_adjustment
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("interest")}
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="interest"
                  {...getFieldProps("interest_adjustment")}
                  error={Boolean(
                    touched.interest_adjustment && errors.interest_adjustment
                  )}
                  helperText={
                    touched.interest_adjustment && errors.interest_adjustment
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className={
                    language === "kh" ? "btn-calculateKh" : "btn-calculateEn"
                  }
                  onClick={handleCalculate}
                  disabled={
                    values?.duration_adjustment === 0 ||
                    values?.interest_adjustment === 0 ||
                    editData?.remaining_amount === 0
                      ? true
                      : false
                  }
                >
                  {t("calculate")}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${(totalPayable * values?.duration_adjustment).toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("monthly-total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${totalPayable.toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Box className="box-action">
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                  disabled={totalPayable === 0 ? true : false}
                  onClick={handleSubmit}
                >
                  {t("create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
