import React, { useState, useEffect } from "react";
import "./customerinfo.scss";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
//Component
import { getFormattedPhoneNum } from "../../../Function/GetKhmerNumber";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function CustomerInfo({ customerData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [phoneNum, setPhoneNum] = useState();

  useEffect(() => {
    if (customerData?.phone_number) {
      setPhoneNum(getFormattedPhoneNum(customerData?.phone_number));
    }
  }, [customerData?.phone_number]);

  // console.log("customerData::", customerData);

  return (
    <Box className="customer-info">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("full-khmer-name")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("nickname")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("latin-name")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("gender")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("nationality")}
              </Typography>

              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("dob")}
              </Typography>

              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("place-of-birth") + t("village")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("commune")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("district")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("province")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={"body-item"}>
                : {customerData?.first_name + " " + customerData?.last_name}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.nickname}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.latin_alphabet}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.gender}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.nationality}
              </Typography>

              <Typography className={"body-item"}>
                :{" "}
                {moment(customerData?.date_of_birth)
                  .utc()
                  .format("DD-MMM-YYYY")}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.place_of_birth?.villege}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.place_of_birth?.commune}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.place_of_birth?.district}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.place_of_birth?.province}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("id-card")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("address-google")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("phone")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("marital-status")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("customer-type")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("facebook")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("current-address") + t("village")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("commune")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("district")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("province")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={"body-item"}>
                : {customerData?.ID_card_number}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.address_google}
              </Typography>
              <Typography className={"body-item"}>: {phoneNum}</Typography>
              <Typography className={"body-item"}>
                : {customerData?.family_status}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.customer_type}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.facebook}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.current_address?.villege}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.current_address?.commune}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.current_address?.district}
              </Typography>
              <Typography className={"body-item"}>
                : {customerData?.current_address?.province}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
