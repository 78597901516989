import { gql } from "@apollo/client";

export const GET_LAON_ON_CUSTOMERHAND = gql`
  query GetReportPrincipal($month: Int, $year: Int) {
    getReportPrincipal(month: $month, year: $year) {
      totalPrincipal {
        number
        total_amount
        type
      }
      type_of_loan
    }
  }
`;

export const GET_LAONSTATUS_ON_CUSTOMERHAND = gql`
  query GetReportStatusCostomer($month: Int, $year: Int) {
    getReportStatusCostomer(month: $month, year: $year) {
      type
      totalStatus {
        status
        number
        amount
      }
    }
  }
`;

export const GET_DAILY_CUSTOMER = gql`
  query GetReportCostomerPayment($paymentStatus: String, $date: Date) {
    getReportCostomerPayment(payment_status: $paymentStatus, date: $date) {
      name
      code
      interest
      payment
      total_payment
      late_month
      payment_date
      late_day
      phone
      last_payment_day
      last_principal_for_late_difficult
      last_interest_for_late_difficult
      type_of_loan
    }
  }
`;

export const GET_NEW_CUSTOMER_REPORT = gql`
  query GetNewCustomerReport($month: Int, $year: Int) {
    getNewCustomerReport(month: $month, year: $year) {
      loan_id
      first_name
      last_name
      type_of_loan
      loan_date
      loan_amount
    }
  }
`;

export const GET_REST_OF_MONEY_REPORT = gql`
  query GetReportRestOfMoney($year: Int, $month: Int) {
    getReportRestOfMoney(year: $year, month: $month) {
      loan_id
      customer_name
      loan_date
      type_of_loan
      rest_of_money
    }
  }
`;

export const GET_CUSTOMER_BY_DAY_REPORT = gql`
  query GetReportCostomerByDay($day: Date) {
    getReportCostomerByDay(day: $day) {
      loans {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
      pawns {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
      installments {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
    }
  }
`;
