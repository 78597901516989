import React, { useState, useEffect, useRef } from "react";
import "./loanrequestdetails.scss";
import { Grid, Stack, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";
import {
  getKhmerDay,
  getKhmerMonth,
  getKhmerNumber,
} from "../../../Function/GetKhmerNumber";

export default function LoanAgreement({ tableData }) {
  const [loading, setLoading] = useState(false);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("tableData::", tableData);

  return (
    <>
      <div
        className="print-loan-request-container"
        style={{ marginTop: "30px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box className="logo-container">
              <img src={logoCompany} className="logo-company" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center">
              <Box>
                <div className="body-title-loant-request">
                  ព្រះរាជាណាចក្រកម្ពុជា
                </div>
                <div className="body-title-loant-request">
                  ជាតិ សាសនា ព្រះមហាក្សត្រ
                </div>
                <div className="loan-request-font-tackteng">
                  <img
                    alt="Image"
                    src={wingdingImage}
                    className="loan-request-image-tackteng"
                  />
                </div>
              </Box>
            </Stack>
            <Box
              className="body-title-loant-request"
              sx={{ marginTop: "60px" }}
            >
              កិច្ចសន្យាខ្ចីចងការប្រាក់
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginLeft: "40px" }}>
            <div className="loan-request-title">
              សៀមរាប​ ថ្ងៃទី{" "}
              <b className="loan-request-title-bold">
                {getKhmerNumber(
                  moment(tableData?.customer_id?.date).format("DD")
                )}{" "}
              </b>
              ខែ
              <span className="loan-request-title-bold">
                {" "}
                {getKhmerMonth(
                  moment(tableData?.customer_id?.date).format("M")
                )}{" "}
              </span>
              ឆ្នាំ{" "}
              {getKhmerNumber(
                moment(tableData?.customer_id?.date).format("YYYY")
              )}
            </div>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ភាគីអោយខ្ចីប្រាក់៖
          </Box>
          <span className="loan-request-title-bold">
            ហោ្គក្រេឌីត ភីអិលស៊ី (Go Credit)
          </span>
          <Box className="loan-request-title">
            មានទីតាំងស្ថិតនៅភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុង
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            សៀមរាប ខេត្ត សៀមរាប។ អ្នកតំណាងស្របច្បាប់របស់ក្រុមហ៊ុន ហ្គោក្រេឌីត
            ភីអិលស៊ី គឺ លោក
            <b>ឡុក ឡងឌី</b>
          </Box>
          <Box className="loan-request-title">មាន</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ភាគីស្នើសុំខ្ចីប្រាក់៖ ឈ្មោះ
          </Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.first_name +
              " " +
              tableData?.customer_id?.last_name}
          </Box>
          <Box className="loan-request-title">ភេទ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.gender === "Male"
              ? "ប្រុស"
              : null || tableData?.customer_id?.gender === "Female"
              ? "ស្រី"
              : null}
          </Box>
          <Box className="loan-request-title">សញ្ជាតិ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.nationality}
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(
              moment(tableData?.customer_id?.date_of_birth).format("DD")
            ) +
              " " +
              getKhmerMonth(
                moment(tableData?.customer_id?.date_of_birth).format("M")
              ) +
              " " +
              getKhmerNumber(
                moment(tableData?.customer_id?.date_of_birth).format("YYYY")
              )}
          </Box>
          <Box className="loan-request-title">ទីកន្លែងកំណើតភូមិ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.place_of_birth?.villege}
          </Box>
          <Box className="loan-request-title">ឃុំ/សង្កាត់</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.place_of_birth?.commune}
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ស្រុក/ខណ្ឌ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.place_of_birth?.district}
          </Box>
          <Box className="loan-request-title">ខេត្ត</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.place_of_birth?.province}។
          </Box>
          <Box className="loan-request-title">
            អត្តសញ្ញាណប័ណ្ណសញ្ជាតិខ្មែរលេខ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-item">
            {getKhmerNumber(tableData?.customer_id?.ID_card_number)}
          </Box>
          <Box className="loan-request-title">ចុះថ្ងៃទី</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(moment(tableData?.customer_id?.date).format("DD")) +
              " " +
              getKhmerMonth(moment(tableData?.customer_id?.date).format("M")) +
              " " +
              getKhmerNumber(
                moment(tableData?.customer_id?.date).format("YYYY")
              )}
          </Box>
          <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន ភូមិ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.current_address?.villege}
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ឃុំ/សង្កាត់</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.current_address?.commune}
          </Box>
          <Box className="loan-request-title">ស្រុក/ខណ្ឌ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.current_address?.district}
          </Box>
          <Box className="loan-request-title">ខេត្ត</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.current_address?.province}
          </Box>
        </Stack>

        <Box className="loan-request-title">
          ដែលតទៅនេះហៅថា
          <span className="loan-request-title-bold">ភាគី “ខ” </span>។
        </Box>

        <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
          <span className="loan-request-title-bold">
            គូភាគីនៃកិច្ចសន្យាបានព្រមព្រៀងគ្នាចុះកិច្ចសន្យាដោយអនុវត្តតាមរាល់ប្រការដូចខាងក្រោម៖
          </span>
        </Box>

        <Box className="loan-request-title">
          <span className="loan-request-title-bold">
            ប្រការ១. ការព្រមព្រៀង៖
          </span>
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">យោងតាមការស្នើសុំខ្ចីប្រាក់</Box>
          <Box className="loan-request-title">សម្រង់ព័ត៌មាន</Box>
          <Box className="loan-request-title">និងការចុះមើលអាជីវកម្ម</Box>
          <Box className="loan-request-title">ភាគី “ក”</Box>
          <Box className="loan-request-title">បានយល់ព្រមអោយភាគី “ខ”</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ខ្ចីប្រាក់</Box>
          <Box className="loan-request-title">ហើយភាគី “ខ”</Box>
          <Box className="loan-request-title">
            បានសន្យាយ៉ាងជាក់ច្បាស់ជាមួយភាគី “ក”
          </Box>
          <Box className="loan-request-title">ថានឹងសងប្រាក់កម្ចីនេះ </Box>
          <Box className="loan-request-title">តាមចំនួន</Box>
          <Box className="loan-request-title">និង គោរព</Box>
        </Stack>

        <Box className="loan-request-title">
          តាមរាល់លក្ខខណ្ឌដែលបានព្រមព្រៀងគ្នាដូចខាងក្រោម៖
        </Box>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            <span className="loan-request-title-bold">១.</span>ប្រាក់កម្ចីដែល
          </Box>
          <Box className="loan-request-title">
            <span className="loan-request-title-bold">ភាគី “ខ”</span>
          </Box>
          <Box className="loan-request-title">បានទទួលចំនួនជាលេខ៖</Box>
          <Box className="loan-request-item">
            {getKhmerNumber(tableData?.loan_amount)}
          </Box>
          <Box className="loan-request-title">ចំនួនជាអក្សរ</Box>
          <Box className="loan-request-item">
            {tableData?.loan_amount_letters}។
          </Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            <span className="loan-request-title-bold">២.</span>អត្រាការប្រាក់
            និង រយៈពេលខ្ចី៖
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Stack direction="column" sx={{ marginLeft: "60px" }} spacing={1}>
            {/* <Box className="loan-request-title">
              <span>&#9744;</span> បង់ជាថ្ងៃ
            </Box>
            <Box className="loan-request-title">
              <span>&#9744;</span> បង់ជាសប្តាហ៍
            </Box>
            <Box className="loan-request-title">
              <span>&#9744;</span> បង់ជាកន្លះខែ
            </Box> */}
            <Box className="loan-request-title">
              <span>&#9745;</span> បង់ជាខែ
            </Box>
          </Stack>

          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-title">អត្រាការប្រាក់</Box>
            <Box className="loan-request-title">អត្រាការប្រាក់</Box>
            <Box className="loan-request-title">អត្រាការប្រាក់</Box> */}

            <Box className="loan-request-title">អត្រាការប្រាក់</Box>
          </Stack>
          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-item">......</Box>
            <Box className="loan-request-item">......</Box>
            <Box className="loan-request-item">......</Box> */}

            <Box className="loan-request-item">
              {getKhmerNumber(
                moment(tableData?.loan_plan?.interest).format("d")
              )}
            </Box>
          </Stack>
          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-title">ភាគរយ</Box>
            <Box className="loan-request-title">ភាគរយ</Box>
            <Box className="loan-request-title">ភាគរយ</Box> */}
            <Box className="loan-request-title">ភាគរយ</Box>
          </Stack>

          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-title">រយៈពេលខ្ចី</Box>
            <Box className="loan-request-title">រយៈពេលខ្ចី</Box>
            <Box className="loan-request-title">រយៈពេលខ្ចី</Box> */}
            <Box className="loan-request-title">រយៈពេលខ្ចី</Box>
          </Stack>

          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-item">......</Box>
            <Box className="loan-request-item">......</Box>
            <Box className="loan-request-item">......</Box> */}
            <Box className="loan-request-item">
              {getKhmerNumber(moment(tableData?.loan_plan?.month).format("d"))}
            </Box>
          </Stack>

          <Stack direction="column" spacing={1}>
            {/* <Box className="loan-request-title" justifyContent="left">
              ថ្ងៃ។
            </Box>
            <Box className="loan-request-title">សប្តាហ៍។</Box>
            <Box className="loan-request-title">កន្លះខែ។</Box> */}
            <Box className="loan-request-title">ខែ។</Box>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            <span className="loan-request-title-bold">៣.</span>ការសងប្រាក់ដើម
            និងការប្រាក់៖ ត្រូវអនុវត្តតាមតារាងបង់ប្រាក់ដែលបានភ្ជាប់ជូន។
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box
            className="loan-request-title"
            sx={{ marginLeft: "40px", justifyContent: "space-evenly" }}
          >
            <span className="loan-request-title-bold">៤.</span>ទីកន្លែងបង់ប្រាក់
            <span className="loan-request-title-bold">ភាគី “ខ”</span>
          </Box>

          <Box className="loan-request-title">
            យល់ព្រមមកទទួលប្រាក់កម្ចីនៅការិយាល័យរបស់ហ្គោក្រេឌីត
          </Box>

          <Box className="loan-request-title">ហើយនៅពេល</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">សងត្រលប់មកវិញ</Box>
          <Box className="loan-request-title-bold">ភាគី “ខ”</Box>
          <Box className="loan-request-title">
            ត្រូវបង់ប្រាក់ទៅអោយបុគ្គលិកដែលមានភ្ជាប់តារាងបង់ប្រាក់
          </Box>
          <Box className="loan-request-title">ដែលចុះទៅប្រមូលប្រាក់</Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">
            ដល់ទីកន្លែងអាជីវកម្មរបស់បងប្អូន
          </Box>
          <Box className="loan-request-title">
            ឬត្រូវមកបង់នៅការិយាល័យក្រុមហ៊ុន ។
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            <span className="loan-request-title-bold">៥.</span>ក្នុងករណីដែល
          </Box>
          <Box className="loan-request-title-bold">ភាគី “ខ”</Box>
          <Box className="loan-request-title">មិនបានអនុវត្តសងប្រាក់ដើម</Box>
          <Box className="loan-request-title">
            និងការប្រាក់តាមតារាងបង់ប្រាក់
          </Box>
          <span className="loan-request-title-bold">ភាគី “ខ”</span>
          <Box className="loan-request-title">សុខចិត្ត</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">បង់ប្រាក់ពិន័យ។ </Box>
          <Box className="loan-request-title">ប្រាក់ពិន័យនេះ </Box>
          <Box className="loan-request-title">
            ត្រូវបានគណនាទៅតាមចំនួនថ្ងៃដែលអតិថិជនបង់ប្រាក់យឺតយ៉ាវជាក់ស្តែងដូចខាង
          </Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">ក្រោម ៖ </Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            - ចំពោះការបង់ប្រាក់រយៈពេលយឺត១ថ្ងៃត្រូវផាកចំនួន៤០០០៛
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            - ចំពោះករណីអតិថិជនបានជូនដំណឹងមុនមកក្រុមហ៊ុនពីការបង់យឺតយ៉ាវ
          </Box>
          <Box className="loan-request-title">នោះក្រុមហ៊ុននឹងអនុគ្រោះការ</Box>
        </Stack>

        <Box className="loan-request-title" sx={{ marginLeft: "55px" }}>
          ផាកពិន័យបានរយៈពេល៣ថ្ងៃ ។
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            <span className="loan-request-title-bold">៦.</span>
            ក្នុងករណីមានការខកខានមិនបានសងប្រាក់តាមចំណុច{" "}
          </Box>
          <Box className="loan-request-title"> (៣) និង</Box>
          <Box className="loan-request-title"> ព្យាយាមគេចវេសមិនដោះស្រាយ</Box>
          <Box className="loan-request-title-bold">ភាគី</Box>
        </Stack>

        <Box className="loan-request-title">
          <span className="loan-request-title-bold">“ខ”</span>
          សុខចិត្តអោយ
          <span className="loan-request-title-bold">ភាគី “ក”</span>
          ប្តឹងទៅអាជ្ញាធរដែនដីដើម្បីចូលរួមដោះស្រាយ ។
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box
              className="loan-request-title-bold"
              sx={{ marginLeft: "40px" }}
            >
              ៧.
            </Box>
            <Box className="loan-request-title"> ប្រសិនបើ</Box>
          </Stack>
          <Box className="loan-request-title-bold">ភាគី “ខ”</Box>
          <Box className="loan-request-title">
            មិនមានលទ្ធភាពសងប្រាក់ត្រឡប់នូវប្រាក់កម្ចីដែលនៅសល់ទាំងអស់ភ្លាមនោះទេ
          </Box>
          <span className="loan-request-title-bold">ភាគី</span>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            សុខចិត្តលក់ទ្រព្យសម្បត្តិរបស់ខ្លួនដើម្បីយកប្រាក់មកសងកាត់ប្រាក់ជំពាក់ទាំងអស់ដែលជំពាក់
          </Box>
          <Box className="loan-request-title-bold">ភាគី “ក”</Box>
          <Box className="loan-request-title">ដែលនេះ</Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">គឺបណ្តាលមកពីកំហុសរបស់</Box>
          <Box className="loan-request-title-bold">ភាគី“ខ”</Box>
          <Box className="loan-request-title">។</Box>
        </Stack>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "20px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>

      <div className="print-loan-request-container">
        <Stack direction="row" sx={{ marginTop: "30px" }}>
          <Box className="loan-request-title-bold" sx={{ marginLeft: "40px" }}>
            ៨.
          </Box>
          <Box className="loan-request-title">
            {" "}
            រាល់សោហ៊ុយចំណាយទាក់ទងនឹងការដោះស្រាយវិវាទជាបន្ទុកចំណាយរបស់ភាគីដែលល្មើសបំពានកិច្ច
          </Box>
        </Stack>

        <Box className="loan-request-title">សន្យា។</Box>
        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">
            <span className="loan-request-title-bold">
              ប្រការ២. ការបញ្ជាក់អះអាងក្នុងការអនុវត្តកិច្ចសន្យា៖
            </span>
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title-bold">ភាគី “ក”</Box>
          <Box className="loan-request-title">និង</Box>
          <Box className="loan-request-title-bold">ភាគី “ខ”</Box>
          <Box className="loan-request-title">
            សន្យាគោរពតាម រាល់ប្រការនៃកិច្ចសន្យាខាងលើ ។
          </Box>
          <Box className="loan-request-title">
            នៅក្នុងករណីមានការអនុវត្តផ្ទុយ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title">ឬរំលោភលើប្រការ</Box>
          <Box className="loan-request-title">ឬចំណុចណាមួយនៃកិច្ចសន្យានេះ</Box>
          <Box className="loan-request-title">
            ភាគីដែលល្មើសត្រូវទទួលខុសត្រូវចំពោះមុខច្បាប់ជាធរមាន។
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            កិច្ចសន្យានេះធ្វើឡើងដោយមានការព្រមព្រៀងពិតប្រាកដ
          </Box>
          <Box className="loan-request-title"> និងដោយសេរី</Box>
          <Box className="loan-request-title">
            គ្មានការបង្ខិតបង្ខំពីភាគីណាមួយ
          </Box>
        </Stack>

        <Box className="loan-request-title">ឡើយ ។</Box>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">
            <span className="loan-request-title-bold">
              ប្រការ៣. ចំនួនកិច្ចសន្យា៖
            </span>
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            កិច្ចសន្យានេះត្រូវបានធ្វើឡើងជាពីរច្បាប់
          </Box>
          <Box className="loan-request-title">
            មួយច្បាប់រក្សាទុកនៅនឹង
            <span className="loan-request-title-bold">ភាគី“ក”</span>
          </Box>
          <Box className="loan-request-title">និងមួយច្បាប់ទៀតរក្សាទុកនៅនឹង</Box>
        </Stack>
        <Box className="loan-request-title">
          <span className="loan-request-title-bold">ភាគី “ខ”។</span>
        </Box>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title">
            <span className="loan-request-title-bold">ប្រការ៤. </span>
            សុពលភាពនៃកិច្ចសន្យា៖
          </Box>
          <Box className="loan-request-title">
            កិច្ចសន្យានេះមានប្រសិទ្ធភាពអនុវត្តចាប់ពីថ្ងៃចុះហត្ថលេខា
          </Box>
          <Box className="loan-request-title">និងផ្តិតមេដៃតទៅ។</Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box className="body-title-loant-request" mt={5}>
              ស្នាមមេដៃអ្នករួមខ្ចី
            </Box>
            <Stack direction="row" justifyContent="center" mt={20} spacing={1}>
              <Box className="loan-request-title">ឈ្មោះ</Box>
              <Box className="loan-request-item">
                ....................................
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Box className="body-title-loant-request" mt={5}>
              ស្នាមមេដៃភាគី “ខ”
            </Box>

            <Stack direction="row" justifyContent="center" mt={20} spacing={1}>
              <Box className="loan-request-title">ឈ្មោះ</Box>
              <Box className="loan-request-item">
                ....................................
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Box className="body-title-loant-request" mt={5}>
              ហត្ថលេខា និងត្រាភាគី “ក”
            </Box>
            <Stack direction="row" justifyContent="center" mt={20} spacing={1}>
              <Box className="loan-request-title">ឈ្មោះ</Box>
              <Box className="loan-request-item">
                ....................................
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "450px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
    </>
  );
}
