import React, { useState } from "react";
import "./customers.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Pagination,
  TableHead,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
// import UsersAction from '../Component/Users/UsersAction/UsersAction';
import { GET_CUSTOMERS_PAGINATION } from "../Schema/Customers";
import { useQuery } from "@apollo/client";
import CustomerAction from "../Component/Customers/CustomerAction/CustomerAction";
import monent from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

export default function Customers() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");

  const { refetch } = useQuery(GET_CUSTOMERS_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
    },

    onCompleted: ({ getCustomerByPagination }) => {
      setTableData(getCustomerByPagination);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  return (
    <div className="customer-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("customer")}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Box className="box-marker">
          <Stack direction="column" sx={{ width: "300px" }}>
            <TextField
              className="text-field"
              fullWidth
              id="input-with-sx"
              placeholder={t("search")}
              size="small"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>

        <Stack direction="column" justifyContent="center">
          <Button
            onClick={() => navigate("/customer/create-customer")}
            className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
            startIcon={<AddIcon className="icon-add" />}
          >
            {t("add")}
          </Button>
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("username")}{" "}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("date-of-birth")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("phone")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("family-status")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("customer")}
              </TableCell>
            </TableHead>
            <TableBody className="body">
              {tableData?.data?.map((row, index) => {
                return (
                  <TableRow className="body-row" key={index}>
                    <TableCell
                      className={
                        language === "kh"
                          ? "body-cell-startKh"
                          : "body-cell-startEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {index + tableData?.paginator?.slNo}-
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      <Stack direction="row" spacing={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src={`${row?.image_src}`}
                          sx={{ width: 45, height: 45 }}
                        />
                        <Stack direction="column" justifyContent="center">
                          <Typography className="body-cellKh">
                            {row?.first_name + " " + row?.last_name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {monent(row?.date_of_birth).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {row?.phone_number}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {row?.family_status}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {row?.customer_type}
                    </TableCell>
                    <TableCell className="body-cell-end" align="right">
                      <CustomerAction editRow={row} setRefetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
