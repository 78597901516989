import { gql } from "@apollo/client";

export const CREATE_LOAN = gql`
  mutation CreateLoan($input: LoanInput) {
    createLoan(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_LOAN_WITH_PAGINATION = gql`
  query GetLoanByPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $status: String
    $active: String
    $typeOfLoan: String
  ) {
    getLoanByPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      status: $status
      active: $active
      typeOfLoan: $typeOfLoan
    ) {
      data {
        _id
        customer_id {
          _id
          first_name
          last_name
          nickname
          latin_alphabet
          gender
          nationality
          ID_card_number
          family_status
          date_of_birth
          place_of_birth {
            villege
            commune
            district
            province
          }
          current_address {
            villege
            commune
            district
            province
          }
          phone_number
          facebook
          address_google
          customer_type
          image_src
          image_name
          reference_docs {
            document_name
            document_src
            key
          }
          company_name
          phone_number_company
          type_of_business
          address_company
          positions
          monthly_income
          salary_payment_date
        }
        co_id {
          _id
          firsName
          lastName
          email
          role
          image_src
          image_name
          created_At
        }
        loan_id
        invoices_id
        loan_type {
          _id
          loan_type_name
          remark
        }
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_amount_letters
        total_amount
        is_first_month
        first_month_date
        last_payment_date
        loan_date
        purpose
        status
        reason
        payment_method
        payment_term
        active
        type_of_loan
        total_late
        total_adjustment_late
        remaining_amount
        amount_paid
        comments {
          comment
          created_at
          update_at
        }
        adjustment {
          payment_method
          loan_date
          is_first_month
          last_payment_date
          first_month_date
          interest_adjustment
          duration_adjustment
          amount_adjustment
          amount_adjustment_letter
          adjustment_payment {
            _id
            number_no
            payment_date
            interest
            monthly_payment
            penalty
            date
            paid
            principal
            rest_of_money
            receipt_date
            late_number
            payment_status
            go_credit
            remark
            key
          }
        }
        isDifficult
        purposeRejection
        installments {
          release_date
          co_id
          co_phone_number
          company_name
          product
        }
        payment_detail {
          _id
          number_no
          payment_date
          interest
          monthly_payment
          penalty
          date
          paid
          principal
          rest_of_money
          receipt_date
          late_number
          payment_status
          go_credit
          remark
          key
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_LOAN = gql`
  mutation UpdateLoan($updateLoanId: ID, $input: LoanUpdate) {
    updateLoan(id: $updateLoanId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_LOAN_STATUS = gql`
  mutation UpdateLoanStatus(
    $updateLoanStatusId: ID
    $status: String
    $reason: String
  ) {
    updateLoanStatus(
      id: $updateLoanStatusId
      status: $status
      reason: $reason
    ) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_LOAN_STATUS_REJECT = gql`
  mutation UpdatePurposeRejection(
    $updatePurposeRejectionId: ID
    $purpose: String
  ) {
    updatePurposeRejection(id: $updatePurposeRejectionId, purpose: $purpose) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_LOAN_APPROVE_PAID = gql`
  mutation UpdateLoanApprovePaid($loanId: ID, $paymentId: ID, $date: Date) {
    updateLoanApprovePaid(loanId: $loanId, paymentId: $paymentId, date: $date) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_LOAN_STATUS_SUBSPEND = gql`
  mutation UpdateLoanStatusSuspended(
    $updateLoanStatusSuspendedId: ID
    $reason: String
  ) {
    updateLoanStatusSuspended(
      id: $updateLoanStatusSuspendedId
      reason: $reason
    ) {
      isSuccess
      message
    }
  }
`;

export const DELETE_LOAN = gql`
  mutation DeleteLoan($deleteLoanId: ID) {
    deleteLoan(id: $deleteLoanId) {
      isSuccess
      message
    }
  }
`;

export const GET_LOAN_BY_ID = gql`
  query GetLoanById($getLoanByIdId: ID) {
    getLoanById(id: $getLoanByIdId) {
      _id
      customer_id {
        _id
        first_name
        last_name
        nickname
        latin_alphabet
        gender
        nationality
        ID_card_number
        family_status
        date_of_birth
        place_of_birth {
          villege
          commune
          district
          province
        }
        current_address {
          villege
          commune
          district
          province
        }
        phone_number
        facebook
        address_google
        customer_type
        image_src
        image_name
        reference_docs {
          document_name
          document_src
          key
        }
        company_name
        phone_number_company
        type_of_business
        address_company
        positions
        monthly_income
        salary_payment_date
      }
      co_id {
        _id
        firsName
        lastName
        phone_number
        email
        role
        image_src
        image_name
        created_At
      }

      loan_id
      invoices_id
      loan_guarantor {
        loan_guarantor_id {
          _id
          first_name
          last_name
          nickname
          latin_alphabet
          gender
          nationality
          ID_card_number
          family_status
          date_of_birth
          place_of_birth {
            villege
            commune
            district
            province
          }
          current_address {
            villege
            commune
            district
            province
          }
          phone_number
          facebook
          address_google
          customer_type
          image_src
          image_name
          reference_docs {
            document_name
            document_src
            key
          }
          company_name
          phone_number_company
          type_of_business
          address_company
          positions
          monthly_income
          salary_payment_date
        }
        borrower_connection
        note
      }
      borrower_together {
        borrower_together_id {
          _id
          first_name
          last_name
          nickname
          latin_alphabet
          gender
          nationality
          ID_card_number
          family_status
          date_of_birth
          place_of_birth {
            villege
            commune
            district
            province
          }
          current_address {
            villege
            commune
            district
            province
          }
          phone_number
          facebook
          address_google
          customer_type
          image_src
          image_name
          reference_docs {
            document_name
            document_src
            key
          }
          company_name
          phone_number_company
          type_of_business
          address_company
          positions
          monthly_income
          salary_payment_date
        }
        borrower_connection
        note
      }
      loan_type {
        _id
        loan_type_name
        remark
      }
      loan_plan {
        _id
        month
        interest
        over_due_penalty
        remark
      }
      loan_amount
      loan_amount_letters
      total_amount
      is_first_month
      first_month_date
      last_payment_date
      loan_date
      purpose
      status
      reason
      payment_method
      active
      type_of_loan
      payment_detail {
        _id
        number_no
        payment_date
        interest
        monthly_payment
        penalty
        date
        paid
        principal
        rest_of_money
        receipt_date
        late_number
        payment_status
        go_credit
        remark
        key
      }
      total_late
      total_adjustment_late
      remaining_amount
      amount_paid
      adjustment {
        payment_method
        loan_date
        is_first_month
        last_payment_date
        first_month_date
        interest_adjustment
        duration_adjustment
        amount_adjustment
        amount_adjustment_letter
        payment_term
        adjustment_payment {
          _id
          number_no
          payment_date
          interest
          monthly_payment
          penalty
          date
          paid
          principal
          rest_of_money
          receipt_date
          late_number
          payment_status
          go_credit
          remark
          key
        }
      }
      isDifficult
      purposeRejection
      installments {
        release_date
        co_id
        co_phone_number
        company_name
        product
      }
      payment_term
      comments {
        comment
        created_at
        update_at
      }
    }
  }
`;

export const UPDATE_ADJUSTMENT_LOAN = gql`
  mutation UpdateLoanAdjustment(
    $updateLoanAdjustmentId: ID
    $input: AdjustmentsInput
  ) {
    updateLoanAdjustment(id: $updateLoanAdjustmentId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_ADJUSTMENT_PAID_DETAILS = gql`
  mutation UpdateAdjustmentPaid($loanId: ID, $paymentId: ID, $date: Date) {
    updateAdjustmentPaid(loanId: $loanId, paymentId: $paymentId, date: $date) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_FINISH_PAYMENT = gql`
  mutation UpdateFinishPayment($updateFinishPaymentId: ID) {
    updateFinishPayment(id: $updateFinishPaymentId) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_COMMENT_BY_LOAN = gql`
  mutation UpdateCommentByLoan($comment: [CommentsInput], $idLoan: ID) {
    updateCommentByLoan(comment: $comment, idLoan: $idLoan) {
      isSuccess
      message
    }
  }
`;
