import React, { useState, useEffect, useContext } from "react";
import "./addloantype.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { CREATE_LOANTYPE, UPDATE_LOANTYPE } from "../../../Schema/Loantype";
//Components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function AddLoanType({
  open,
  handleClose,
  editData,
  dialogTitle,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);

  const [createLoanType] = useMutation(CREATE_LOANTYPE, {
    onCompleted: ({ createLoanType }) => {
      if (createLoanType?.isSuccess) {
        setAlert(true, "success", createLoanType?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", createLoanType?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const [updateLoanType] = useMutation(UPDATE_LOANTYPE, {
    onCompleted: ({ updateLoanType }) => {
      if (updateLoanType?.isSuccess) {
        setAlert(true, "success", updateLoanType?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateLoanType?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  //fromik
  const AddLoanType = Yup.object().shape({
    loan_type_name: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      loan_type_name: "",
      remark: "",
    },

    validationSchema: AddLoanType,
    onSubmit: async (values) => {
      // console.log(values);
      setLoading(true);
      let newLoanType = {
        loan_type_name: values?.loan_type_name,
        remark: values?.remark,
      };
      if (dialogTitle === "Create") {
        createLoanType({
          variables: {
            input: {
              ...newLoanType,
            },
          },
        });
      } else {
        updateLoanType({
          variables: {
            updateLoanTypeId: editData?._id,
            input: {
              ...newLoanType,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("loan_type_name", editData?.loan_type_name);
      setFieldValue("remark", editData?.remark);
    }
  }, [editData]);

  return (
    <Dialog
      open={open}
      className="create-loantype"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {dialogTitle === "Create"
              ? t("create") + t("loan type")
              : t("update") + t("loan type")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("loantype-name")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="loantype name"
                    {...getFieldProps("loan_type_name")}
                    error={Boolean(
                      touched.loan_type_name && errors.loan_type_name
                    )}
                    helperText={touched.loan_type_name && errors.loan_type_name}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("note")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="note"
                    {...getFieldProps("remark")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action">
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {dialogTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
