import React, { useContext, useState } from "react";
import "./viewcustomerdetails.scss";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  TableContainer,
  Table,
  Stack,
  Box,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
} from "@mui/material";
import LoadingPage from "../../Include/LoadingPage";
import EmptyData from "../../Include/EmptyData";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function ViewCustomerDetails({
  open,
  handleClose,
  editData,
  loading,
}) {
  // console.log("editData:::", editData);
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="view-customer"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("view-payment-details")}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <TableContainer className="constainer">
            <Table className="table">
              <TableHead className="table-head">
                <TableRow className="table-row">
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("no")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("payment-date")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("principal")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("interest")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("total-payment")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("rest-of-money")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("received-date")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("paid-status")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("paid-type")}
                  </TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <TableHead className="header-row">
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className={
                        language === "kh" ? "table-cellKh" : "table-cellEn"
                      }
                    >
                      <LoadingPage />
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <>
                  {editData?.payment_detail?.length === 0 &&
                  editData?.adjustment?.adjustment_payment?.length === 0 ? (
                    <TableHead className="header-row">
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          className={
                            language === "kh" ? "table-cellKh" : "table-cellEn"
                          }
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            height={260}
                          >
                            <EmptyData />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <>
                      {editData?.adjustment?.adjustment_payment?.length ===
                      0 ? (
                        <TableBody className="table-body">
                          {editData?.payment_detail?.map((row, index) => (
                            <TableRow className="body-row" key={index}>
                              <TableCell className="body-cell-start">
                                {index + 1}-
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {moment(row?.payment_date)
                                  .utc()
                                  .format("DD-MMM-YYYY")}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                ${row?.principal.toFixed(2)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                ${row?.interest?.toFixed(2)}
                              </TableCell>

                              <TableCell className="body-cell" align="left">
                                $
                                {row?.monthly_payment === null
                                  ? "$0.00"
                                  : row?.monthly_payment.toFixed(2)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                $
                                {row?.rest_of_money === null
                                  ? "$0.00"
                                  : row?.rest_of_money.toFixed(2)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {row?.receipt_date !== null
                                  ? moment(row?.receipt_date)
                                      .utc()
                                      .format("DD-MMM-YYYY")
                                  : "---:---"}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {row?.paid === true ? "paid" : "pending"}
                              </TableCell>
                              <TableCell
                                className="body-cell-end"
                                align="left"
                                sx={{
                                  color:
                                    row?.payment_status === "Active"
                                      ? "#38b14a"
                                      : row?.payment_status === "Late"
                                      ? "orange"
                                      : "red",
                                }}
                              >
                                {row?.payment_status}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody className="table-body">
                          {editData?.adjustment?.adjustment_payment?.map(
                            (row, index) => (
                              <TableRow className="body-row" key={index}>
                                <TableCell className="body-cell-start">
                                  {index + 1}-
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {moment(row?.payment_date)
                                    .utc()
                                    .format("DD-MMM-YYYY")}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  ${row?.principal.toFixed(2)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  ${row?.interest?.toFixed(2)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  $
                                  {row?.monthly_payment === null
                                    ? "$0.00"
                                    : row?.monthly_payment.toFixed(2)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  $
                                  {row?.rest_of_money === null
                                    ? "$0.00"
                                    : row?.rest_of_money.toFixed(2)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.receipt_date !== null
                                    ? moment(row?.receipt_date)
                                        .utc()
                                        .format("DD-MMM-YYYY")
                                    : "---:---"}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.paid === true ? "paid" : "pending"}
                                </TableCell>
                                <TableCell
                                  className="body-cell-end"
                                  align="left"
                                  sx={{
                                    color:
                                      row?.payment_status === "Active"
                                        ? "#38b14a"
                                        : row?.payment_status === "Late"
                                        ? "orange"
                                        : "red",
                                  }}
                                >
                                  {row?.payment_status}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      )}
                    </>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
