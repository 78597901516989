import React, { useContext, useState } from "react";
import "./loanlistaction.scss";
import {
  IconButton,
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "@apollo/client";
//components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { UPDATE_LOAN_STATUS_SUBSPEND } from "../../../Schema/Loanlist";

export default function LoanPlansAction({
  editData,
  setRefetch,
  open,
  handleClose,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [updateLoanStatusSuspended] = useMutation(UPDATE_LOAN_STATUS_SUBSPEND, {
    onCompleted: ({ updateLoanStatusSuspended }) => {
      // console.log("updateLoanStatusSuspende::=>", updateLoanStatusSuspended);
      if (updateLoanStatusSuspended?.isSuccess) {
        setAlert(true, "success", updateLoanStatusSuspended?.message);
        setRefetch();
        setLoading(false);
        handleClose();
      } else {
        setAlert(true, "error", updateLoanStatusSuspended?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const handleSubspend = () => {
    updateLoanStatusSuspended({
      variables: {
        updateLoanStatusSuspendedId: editData?._id,
        reason: reason,
      },
    });
  };

  // console.log("editData::=>", editData);

  return (
    <Dialog open={open} onClose={handleClose} className="loanlist-action">
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("suspend-loan-list")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography
            variant="subtitle1"
            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
          >
            {t("subspend-title")}
          </Typography>
          <TextField
            fullWidth
            size="small"
            multiline
            placeholder="reason"
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              <Button
                className="btn"
                variant="outlined"
                fullWidth
                disabled={reason === "" ? true : false}
                onClick={handleSubspend}
                sx={{ backgroundColor: reason === "" ? "gray" : "red" }}
              >
                {loading ? (
                  "Loading..."
                ) : (
                  <Typography
                    className={language === "kh" ? "suspendKh" : "suspendEn"}
                  >
                    {t("suspend-now")}
                  </Typography>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
