import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Avatar, Typography } from "@mui/material";
import "./dashboard.scss";
import Groups2Icon from "@mui/icons-material/Groups2";
import GroupIcon from "@mui/icons-material/Group";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";
//Component
import ChartCustomer from "../Component/Dashboard/ChartCustomer/Chart";
import ChartMoney from "../Component/Dashboard/ChartMoney/Chart";
import ChartCustomerMonth from "../Component/Dashboard/ChartCustomerMonth/ChartCustomerMonth";
import ChartMoneyMonth from "../Component/Dashboard/ChartMoneyMonth/ChartMoneyMonth";

import {
  GET_LOAN_TOTAL,
  GET_TOTAL_TYPE_LOAN,
  GET_TOTAL_TYPE_PAWN,
  GET_TOTAL_TYPE_INSTALLMENT,
} from "../Schema/Dashboard";
import moment from "moment";

export default function Dashboard() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loanTotalData, setLoanTotalData] = useState();
  const [totalTypeLoan, setTotalTypeLoan] = useState();
  const [totalTypePawn, setTotalTypePawn] = useState();
  const [totalTypeInstallment, setTotalTypeInstallment] = useState();

  const { data: totalData } = useQuery(GET_LOAN_TOTAL, {
    onCompleted: ({ getLoanTotalByCustomer }) => {
      setLoanTotalData(getLoanTotalByCustomer);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const { data: totalLoan } = useQuery(GET_TOTAL_TYPE_LOAN, {
    onCompleted: ({ getTotalTypeOfLoan }) => {
      setTotalTypeLoan(getTotalTypeOfLoan);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const { data: totalPawn } = useQuery(GET_TOTAL_TYPE_PAWN, {
    onCompleted: ({ getTotalTypeOfPawn }) => {
      setTotalTypePawn(getTotalTypeOfPawn);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const { data: totalInstallment } = useQuery(GET_TOTAL_TYPE_INSTALLMENT, {
    onCompleted: ({ getTotalTypeOfInstallments }) => {
      setTotalTypeInstallment(getTotalTypeOfInstallments);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  function currencyFormat(num) {
    return (
      "$" + " " + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  return (
    <div className="dashboard-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("dashboard")}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <Grid container spacing={3} className="container">
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
          <CardDasboard />
        </Grid> */}

        <Grid item xs={12} md={6} lg={3}>
          <Box className="box-style" sx={{ backgroundColor: "#3c64f6" }}>
            <Stack direction="row" spacing={2}>
              <Groups2Icon className="icon" />
              <Box sx={{ flexGrow: 1 }} />
              <Typography className="num-customer">
                {loanTotalData?.total_customer} People
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography className={language === "kh" ? "textKh" : "textEn"}>
                {t("total")}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography className="tage-amount">
                {currencyFormat(loanTotalData?.total_amount)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Box className="box-style" sx={{ backgroundColor: "#38b14a" }}>
            <Stack direction="row" spacing={2}>
              <Diversity3Icon className="icon-loan" />
              <Box sx={{ flexGrow: 1 }} />
              <Typography className="num-customer">
                {totalTypeLoan?.total_customer} People
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography className={language === "kh" ? "textKh" : "textEn"}>
                {t("loan")}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography className="tage-amount">
                {currencyFormat(totalTypeLoan?.total_amount)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          {/* <Box className="box-style" sx={{ backgroundColor: "#ECCF45" }}> */}
          <Box className="box-style" sx={{ backgroundColor: "#E2BB00" }}>
            <Stack direction="row" spacing={2}>
              <GroupIcon className="icon-pawn" />
              <Box sx={{ flexGrow: 1 }} />
              <Typography className="num-customer">
                {totalTypePawn?.total_customer} People
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography className={language === "kh" ? "textKh" : "textEn"}>
                {t("pawn")}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography className="tage-amount">
                {currencyFormat(totalTypePawn?.total_amount)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Box className="box-style" sx={{ backgroundColor: "#8569EB" }}>
            <Stack direction="row" spacing={2}>
              <Diversity3Icon className="icon-install" />
              <Box sx={{ flexGrow: 1 }} />
              <Typography className="num-customer">
                {totalTypeInstallment?.total_customer} People
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography className={language === "kh" ? "textKh" : "textEn"}>
                {t("installment")}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography className="tage-amount">
                {currencyFormat(totalTypeInstallment?.total_amount)}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Box className="content-loanlist">
            <Typography className="title-graph">
              Customer in {moment().utc().format("MMMM")}
            </Typography>
            <ChartCustomer />
          </Box>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Box className="content-loanlist">
            <Typography className="title-graph">
              Loan in {moment().utc().format("MMMM")}
            </Typography>
            <ChartMoney />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="content-loanlist">
            <Typography className="title-graph">
              Customer in {moment().utc().format("YYYY")}
            </Typography>
            <ChartCustomerMonth />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="content-loanlist">
            <Typography className="title-graph">
              Loan in {moment().utc().format("YYYY")}
            </Typography>
            <ChartMoneyMonth />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
