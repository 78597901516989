import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
//graphql
import { GET_CUSTOMERS_PAGINATION } from "../Schema/Customers";
import { GET_LOANPLAN_WITH_PAGINATION } from "../Schema/Loanplan";
import { GET_LOANTYPE_WITH_PAGINATION } from "../Schema/Loantype";

export function SelectCustomer({
  setIdValue,
  customerId,
  coBorrowerId,
  disabled,
  selectedValue,
}) {
  const [data, setData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [CoBorrowerOptions, setCoBorrowerOptions] = useState([]);
  const [guarantorOptions, setGuarantorOptions] = useState([]);
  const { data: customerData } = useQuery(GET_CUSTOMERS_PAGINATION, {
    variables: {
      page: 1,
      keyword: "",
      pagination: false,
    },
    onCompleted: ({ getCustomerByPagination }) => {
      setData(getCustomerByPagination?.data);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.first_name + " " + d?.last_name,
        };
        rows.push(allRows);
        setCustomerOptions(rows);
      });
    }
  }, [data]);

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data
        ?.filter((d) => d?._id !== customerId)
        .map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.first_name + " " + d?.last_name,
          };
          rows.push(allRows);
          setCoBorrowerOptions(rows);
        });
    }
  }, [data, customerId]);

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data
        ?.filter((d) => d?._id !== customerId && d?._id !== coBorrowerId)
        .map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.first_name + " " + d?.last_name,
          };
          rows.push(allRows);
          setGuarantorOptions(rows);
        });
    }
  }, [data, customerId, coBorrowerId]);

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  // console.log("selectedValue::", selectedValue)

  return (
    <Autocomplete
      value={selectedValue}
      options={
        customerId && coBorrowerId
          ? guarantorOptions
          : customerId
          ? CoBorrowerOptions
          : customerOptions
      }
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select value" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select value" />
      )}
    />
  );
}

export function SelectLoanType({ setIdValue, disabled, selectedValue }) {
  const [data, setData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const { data: loanTypeData } = useQuery(GET_LOANTYPE_WITH_PAGINATION, {
    variables: {
      page: 1,
      keyword: "",
      pagination: false,
    },
    onCompleted: ({ getLoanTypeByPagination }) => {
      setData(getLoanTypeByPagination?.data);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.loan_type_name,
        };
        rows.push(allRows);
        setSelectOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  // console.log("selectedValue::", selectedValue);

  return (
    <Autocomplete
      value={selectedValue}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select loanType" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select loanType" />
      )}
    />
  );
}

export function SelectLoanPlan({
  setIdValue,
  disabled,
  selectedValue,
  typeOfPaymentPeriod,
}) {
  const [data, setData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const { data: LoanPlanData } = useQuery(GET_LOANPLAN_WITH_PAGINATION, {
    variables: {
      page: 1,
      limit: 10000,
      keyword: "",
      pagination: false,
      typeOfPaymentPeriod: typeOfPaymentPeriod,
    },
    onCompleted: ({ getLoanPlanByPagination }) => {
      setData(getLoanPlanByPagination?.data);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title:
            typeOfPaymentPeriod === "Month"
              ? `${d?.month} month [${d?.interest}%, ${d?.over_due_penalty}]`
              : `${d?.month} week [${d?.interest}%, ${d?.over_due_penalty}]`,
        };
        rows.push(allRows);
        setSelectOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setIdValue(newVal);
  };

  return (
    <Autocomplete
      value={selectedValue}
      options={selectOptions}
      onChange={(event, newVal) => handleSelect(newVal)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "select loanplan" : option.title
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="select loanplan" />
      )}
    />
  );
}
