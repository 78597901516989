import React, { useState, useEffect, useContext } from "react";
import "./updateloanlist.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  FormHelperText,
  Switch,
  Divider,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
//date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthContext } from "../../../context/AuthContext";
//Schema
import { GET_LOANPLAN_BY_ID } from "../../../Schema/Loanplan";
import { UPDATE_LOAN } from "../../../Schema/Loanlist";
import { translateLauguage } from "../../../Function/Translate";
//funtion owncoponents
import {
  SelectCustomer,
  SelectLoanPlan,
  SelectLoanType,
} from "../../../Function/OwnComponents";

export default function AddLoanlist({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);

  //select function
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [coBorrowerVal, setCoBorrowerVal] = useState({ id: "", title: "" });
  const [guarantorVal, setGuarantorVal] = useState({ id: "", title: "" });
  const [loanTypeVal, setLoanTypeVal] = useState({ id: "", title: "" });
  const [loanPlanVal, setLoanPlanVal] = useState({ id: "", title: "" });

  //calculate function
  const [totalPayable, setTotalPayable] = useState(
    (editData?.loan_plan?.interest / 100) *
      editData?.loan_plan?.month *
      editData?.loan_amount +
      editData?.loan_amount
  );
  const [checkTotal, setCheckTotal] = useState(0);
  const [loanPlanByIdData, setLoanPlanByIdData] = useState({});

  let userLoginData = JSON.parse(window.localStorage.getItem("userLogin"));

  // ============================== Mutation update loan ===========================================

  const [updateLoan] = useMutation(UPDATE_LOAN, {
    onCompleted: ({ updateLoan }) => {
      if (updateLoan?.isSuccess) {
        setAlert(true, "success", updateLoan?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateLoan?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  // ============================== Formik ==============================================
  const AddLoanList = Yup.object().shape({
    loan_id: Yup.string(),
    customer_id: Yup.string().required("require!"),
    loan_guarantor_id: Yup.string(),
    borrower_connection: Yup.string(),
    borrower_together_id: Yup.string(),
    borrower_connection_together: Yup.string(),
    loan_type: Yup.string(),
    loan_amount: Yup.string().required("require!"),
    loan_amount_letters: Yup.string().required("require!"),
    loan_plan: Yup.string().required("require!"),
    loan_date: Yup.date(),
    purpose: Yup.string(),
    payment_method: Yup.string().required("require!"),
    payment_term: Yup.string().required("require!"),
    is_first_month: Yup.string(),
    first_month_date: Yup.date(),
    type_of_loan: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      loan_id: "",
      customer_id: "",
      loan_guarantor_id: "",
      borrower_together_id: "",
      borrower_connection: "",
      borrower_connection_together: "",
      loan_type: "",
      loan_amount: 0,
      loan_amount_letters: "",
      loan_plan: "",
      loan_date: moment(),
      purpose: "",
      payment_method: "Fixed",
      payment_term: "Month",
      is_first_month: false,
      first_month_date: moment(),
      type_of_loan: "Loan",
    },

    validationSchema: AddLoanList,
    onSubmit: async (values) => {
      setLoading(true);
      let newLoanValues = {
        loan_id: values?.loan_id,
        customer_id: values?.customer_id,
        co_id: userLoginData?._id,
        loan_plan: values?.loan_plan,
        loan_type: values?.loan_type,
        loan_amount: values?.loan_amount,
        loan_amount_letters: values?.loan_amount_letters,
        is_first_month: values?.is_first_month,
        first_month_date:
          values?.is_first_month === true ? values?.first_month_date : "",
        loan_date: values?.loan_date,
        purpose: values?.purpose,
        payment_method: values?.payment_method,
        payment_term: values?.payment_term,
        type_of_loan: values?.type_of_loan,
        borrower_together: {
          borrower_together_id:
            coBorrowerVal?.id === "" || coBorrowerVal?.id === undefined
              ? null
              : coBorrowerVal?.id,
          borrower_connection: values?.borrower_connection_together,
          note: "",
        },
        loan_guarantor: {
          loan_guarantor_id:
            guarantorVal?.id === "" || guarantorVal?.id === undefined
              ? null
              : guarantorVal?.id,
          borrower_connection: values?.borrower_connection,
          note: "",
        },
      };

      // console.log("newLoanValues:::", newLoanValues);

      updateLoan({
        variables: {
          updateLoanId: editData?._id,
          input: newLoanValues,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (customerVal?.id !== "") {
      setFieldValue("customer_id", customerVal?.id);
    }
  }, [customerVal?.id]);

  useEffect(() => {
    if (loanTypeVal?.id !== "" || loanTypeVal?.id !== undefined) {
      setFieldValue("loan_type", loanTypeVal?.id);
    }
  }, [loanTypeVal?.id]);

  useEffect(() => {
    if (loanPlanVal?.id !== "" || loanTypeVal?.id !== undefined) {
      setFieldValue("loan_plan", loanPlanVal?.id);
    }
  }, [loanPlanVal?.id]);

  // ========================================= setGuarantor options ===============================================

  const { data: LoanPlanByIdData } = useQuery(GET_LOANPLAN_BY_ID, {
    variables: {
      getLoanPlanByIdId: values?.loan_plan,
    },
    onCompleted: ({ getLoanPlanById }) => {
      setLoanPlanByIdData(getLoanPlanById);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // ================================== Calculate function ===========================================

  const handleCalculate = () => {
    setTotalPayable(
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100
    );
    setCheckTotal(
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100
    );
  };

  useEffect(() => {
    if (
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100 !==
        checkTotal &&
      checkTotal !== 0 &&
      values?.loan_amount !== ""
    ) {
      setTotalPayable(
        values?.loan_amount / loanPlanByIdData?.month +
          (values?.loan_amount * loanPlanByIdData?.interest) / 100
      );
    } else if (
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100 ===
        checkTotal &&
      checkTotal !== 0 &&
      values?.loan_amount !== ""
    ) {
      setTotalPayable(checkTotal);
    } else {
      setTotalPayable(0);
    }
  }, [
    values?.loan_amount,
    loanPlanByIdData?.month,
    loanPlanByIdData?.interest,
  ]);

  // ===================================== setEditData into field ===========================================
  // console.log("editData::=>", editData);

  useEffect(() => {
    if (editData) {
      setCustomerVal({
        id: editData?.customer_id?._id,
        title:
          editData?.customer_id?.first_name +
          " " +
          editData?.customer_id?.last_name,
      });
      setCoBorrowerVal({
        id:
          editData?.borrower_together?.borrower_together_id === null
            ? ""
            : editData?.borrower_together?.borrower_together_id?._id,
        title:
          editData?.borrower_together?.borrower_together_id === null
            ? ""
            : editData?.borrower_together?.borrower_together_id?.first_name +
              " " +
              editData?.borrower_together?.borrower_together_id?.last_name,
      });
      setGuarantorVal({
        id:
          editData?.loan_guarantor?.loan_guarantor_id === null
            ? ""
            : editData?.loan_guarantor?.loan_guarantor_id?._id,
        title:
          editData?.loan_guarantor?.loan_guarantor_id === null
            ? ""
            : editData?.loan_guarantor?.loan_guarantor_id?.first_name +
              " " +
              editData?.loan_guarantor?.loan_guarantor_id?.last_name,
      });
      setLoanTypeVal({
        id: editData?.loan_type?._id,
        title: editData?.loan_type?.loan_type_name,
      });
      setLoanPlanVal({
        id: editData?.loan_plan?._id,
        title:
          editData?.payment_term === "month"
            ? `${editData?.loan_plan?.month} month [${editData?.loan_plan?.interest}%, ${editData?.loan_plan?.over_due_penalty}]`
            : `${editData?.loan_plan?.month} week [${editData?.loan_plan?.interest}%, ${editData?.loan_plan?.over_due_penalty}]`,
      });
      setFieldValue("customer_id", editData?.customer_id?._id);
      setFieldValue(
        "loan_guarantor_id",
        editData?.loan_guarantor?.loan_guarantor_id === null
          ? ""
          : editData?.loan_guarantor?.loan_guarantor_id?._id
      );
      setFieldValue(
        "borrower_together_id",
        editData?.borrower_together?.borrower_together_id === null
          ? ""
          : editData?.borrower_together?.borrower_together_id?._id
      );
      setFieldValue("loan_id", editData?.loan_id);
      setFieldValue("type_of_loan", editData?.type_of_loan);
      setFieldValue("loan_type", editData?.loan_type?._id);
      setFieldValue("loan_plan", editData?.loan_plan?._id);
      setFieldValue("loan_amount", editData?.loan_amount);
      setFieldValue("loan_date", moment(editData?.loan_date));
      setFieldValue("purpose", editData?.purpose);
      setFieldValue("payment_method", editData?.payment_method);
      setFieldValue("payment_term", editData?.payment_term);
      setFieldValue("loan_amount_letters", editData?.loan_amount_letters);
      setFieldValue(
        "borrower_connection",
        editData?.loan_guarantor?.borrower_connection
      );
      setFieldValue(
        "borrower_connection_together",
        editData?.borrower_together?.borrower_connection
      );
      setFieldValue("is_first_month", editData?.is_first_month);
      setFieldValue(
        "first_month_date",
        editData?.first_month_date === null
          ? moment()
          : moment(editData?.first_month_date)
      );
    }
  }, [editData]);

  return (
    <Dialog
      open={open}
      className="update-loanlist"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("update-loan")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower")}
                </Typography>
                <SelectCustomer
                  setIdValue={setCustomerVal}
                  selectedValue={customerVal}
                />
                {!!errors.customer_id && touched.customer_id && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.customer_id}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-id")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="loan id"
                  {...getFieldProps("loan_id")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("co-borrower")}
                </Typography>

                <SelectCustomer
                  setIdValue={setCoBorrowerVal}
                  customerId={customerVal?.id}
                  disabled={customerVal?.id === "" ? true : false}
                  selectedValue={coBorrowerVal}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower-connection")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="borrower connection"
                  disabled={customerVal?.id === "" ? true : false}
                  {...getFieldProps("borrower_connection_together")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-guarantor")}
                </Typography>
                <SelectCustomer
                  setIdValue={setGuarantorVal}
                  customerId={customerVal?.id}
                  coBorrowerId={coBorrowerVal?.id}
                  disabled={customerVal?.id === "" ? true : false}
                  selectedValue={guarantorVal}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower-connection")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="borrower connection"
                  disabled={customerVal === "" ? true : false}
                  {...getFieldProps("borrower_connection")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-date")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values?.loan_date}
                    onChange={(newValue) => {
                      setFieldValue("loan_date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="text-field"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("payment-method")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    {...getFieldProps("payment_method")}
                    error={Boolean(
                      touched.payment_method && errors.payment_method
                    )}
                    helperText={touched.payment_method && errors.payment_method}
                  >
                    <MenuItem value={"Decrease"}>Decrease</MenuItem>
                    <MenuItem value={"Fixed"}>Fixed</MenuItem>
                    <MenuItem value={"Pay_Only_Interest"}>
                      Pay only interest
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("payment-term")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    {...getFieldProps("payment_term")}
                    error={Boolean(touched.payment_term && errors.payment_term)}
                    helperText={touched.payment_term && errors.payment_term}
                  >
                    <MenuItem value={"Month"}>Month</MenuItem>
                    <MenuItem value={"Week"}>Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={values?.is_first_month === true ? 4 : 8}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("is-first-month")}
                </Typography>
                <Switch
                  checked={values?.is_first_month}
                  onChange={(e) =>
                    setFieldValue("is_first_month", e?.target.checked)
                  }
                />
              </Grid>

              {values?.is_first_month === true ? (
                <Grid item xs={4}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("first-month-date")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values?.first_month_date}
                      onChange={(newValue) => {
                        setFieldValue("first_month_date", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className="text-field"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : null}

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan plan")}
                </Typography>
                <SelectLoanPlan
                  setIdValue={setLoanPlanVal}
                  selectedValue={loanPlanVal}
                  typeOfPaymentPeriod={values?.payment_term}
                />
                {!!errors.loan_plan && touched.loan_plan && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.loan_plan}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-amount")}
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="loan amount"
                  {...getFieldProps("loan_amount")}
                  error={Boolean(touched.loan_amount && errors.loan_amount)}
                  helperText={touched.loan_amount && errors.loan_amount}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("amount-letters")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="amount letters"
                  {...getFieldProps("loan_amount_letters")}
                  error={Boolean(
                    touched.loan_amount_letters && errors.loan_amount_letters
                  )}
                  helperText={
                    touched.loan_amount_letters && errors.loan_amount_letters
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("purpose")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="purpose"
                  {...getFieldProps("purpose")}
                  error={Boolean(touched.purpose && errors.purpose)}
                  helperText={touched.purpose && errors.purpose}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className={
                    language === "kh" ? "btn-calculateKh" : "btn-calculateEn"
                  }
                  onClick={handleCalculate}
                  disabled={
                    values?.loan_plan === "" ||
                    values?.loan_amount === 0 ||
                    values?.loan_amount === ""
                      ? true
                      : false
                  }
                >
                  {t("calculate")}
                </Button>
              </Grid>

              <Grid item xs={5}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${(totalPayable * loanPlanByIdData?.month).toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={7}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("monthly-total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${totalPayable.toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Box className="box-action">
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                  disabled={totalPayable === 0 ? true : false}
                  onClick={handleSubmit}
                >
                  {t("update")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
