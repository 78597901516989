import { gql } from "@apollo/client";

export const CREATE_INSTALLMENT = gql`
  mutation CreateInstallment($input: InstallInput) {
    createInstallment(input: $input) {
      isSuccess
      message
    }
  }
`;

export const UPDATE_INSTALLMENT = gql`
  mutation UpdateInstallment($updateInstallmentId: ID, $input: InstallInput) {
    updateInstallment(id: $updateInstallmentId, input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_CUSTOMER_INSTALLMENT_BY_DAY = gql`
  query GetReportCostomerInstallmentsByDay($day: Date) {
    getReportCostomerInstallmentsByDay(day: $day) {
      go_gredit {
        costomer_name
        loan_id
        loan_plan
        loan_amount_USD
        loan_amount_Riel
        company_name
      }
      kano_tech {
        costomer_name
        loan_id
        loan_plan
        loan_amount_USD
        loan_amount_Riel
        company_name
      }
      techno_khmer {
        costomer_name
        loan_id
        loan_plan
        loan_amount_USD
        loan_amount_Riel
        company_name
      }
    }
  }
`;
