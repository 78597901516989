import React, { useContext, useState } from "react";
import "./finishloanlist.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { UPDATE_FINISH_PAYMENT } from "../../../Schema/Loanlist";
//Components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function DeleteLoanPlans({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [confirmVal, setConfirmVal] = React.useState("");
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);

  const [updateFinishPayment] = useMutation(UPDATE_FINISH_PAYMENT, {
    onCompleted: ({ updateFinishPayment }) => {
      if (updateFinishPayment?.isSuccess) {
        setAlert(true, "success", updateFinishPayment?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateFinishPayment?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  // console.log(parseInt(confirmVal), editData?.remaining_amount);

  const handleFinish = () => {
    setLoading(true);
    updateFinishPayment({
      variables: {
        updateFinishPaymentId: editData?._id,
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} className="fininsh-loanlist">
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("finish") + t("loan list")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="subtitle1"
          className={language === "kh" ? "simple-textKh" : "simple-textEn"}
        >
          {t("finish-loan-message")}
        </Typography>

        <Stack direction="row" spacing={1}>
          <Typography
            variant="subtitle1"
            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
          >
            {t("please-input")}
          </Typography>
          <Typography className="body-text" variant="subtitle1">
            {(editData?.remaining_amount).toFixed(2)}
          </Typography>
          <Typography
            variant="subtitle1"
            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
          >
            {t("to-finish")}
          </Typography>
        </Stack>
        <TextField
          fullWidth
          size="small"
          type="number"
          onChange={(e) => setConfirmVal(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              {parseFloat(confirmVal).toFixed(2) ===
              (editData?.remaining_amount).toFixed(2) ? (
                <Button
                  className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                  variant="outlined"
                  onClick={handleFinish}
                >
                  {loading ? "Loading..." : t("finish-now")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={
                    language === "kh" ? "btn-deleteKh" : "btn-deleteEn"
                  }
                  fullWidth
                >
                  {t("finish")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
