import React, { useState, useEffect } from "react";
import "./customerinfo.scss";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
//Component
import { getFormattedPhoneNum } from "../../../Function/GetKhmerNumber";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function LoanInfo({ tableData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [companyPhone, setCompanyPhone] = useState();

  useEffect(() => {
    if (tableData?.phone_number_company) {
      setCompanyPhone(getFormattedPhoneNum(tableData?.phone_number_company));
    }
  }, [tableData?.phone_number_company]);

  // console.log("tableData::", tableData);

  return (
    <Box className="customer-info">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              {tableData?.type_of_loan !== "Installments" ? null : (
                <Typography
                  className={language === "kh" ? "body-textKh" : "body-textEn"}
                >
                  {t("company-name")}
                </Typography>
              )}
              {tableData?.type_of_loan !== "Installments" ? null : (
                <Typography
                  className={language === "kh" ? "body-textKh" : "body-textEn"}
                >
                  {t("product")}
                </Typography>
              )}
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("loan-id")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("payment-term")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("loan type")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("date-released")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("type-of-loan")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {tableData?.installments?.company_name === null ? null : (
                <Typography className={"body-item"}>
                  : {tableData?.installments?.company_name}
                </Typography>
              )}
              {tableData?.installments?.company_name === null ? null : (
                <Typography className={"body-item"}>
                  : {tableData?.installments?.product}
                </Typography>
              )}
              <Typography className={"body-item"}>
                : {tableData?.loan_id}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.payment_term}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.payment_method}
              </Typography>
              <Typography className={"body-item"}>
                :{" "}
                {tableData?.type_of_loan === "Installments"
                  ? moment(tableData?.installments?.release_date).format(
                      "DD-MM-YYYY"
                    )
                  : moment(tableData?.loan_date).format("DD-MM-YYYY")}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.type_of_loan}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("loan-amount")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("amount-letters")}
              </Typography>

              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("loan plan")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("payment-method")}
              </Typography>
              <Typography
                className={language === "kh" ? "body-textKh" : "body-textEn"}
              >
                {t("purpose")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={"body-item"}>
                : $ {tableData?.loan_amount.toFixed(3)}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.loan_amount_letters}
              </Typography>
              <Typography className={"body-item"}>
                :{" "}
                {`${tableData?.loan_plan?.month} ${t("months")} [${t(
                  "interest"
                )}`}
                <span style={{ fontWeight: "bold" }}>
                  {` ${tableData?.loan_plan?.interest}%`}
                </span>
                {`]`}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.payment_method}
              </Typography>
              <Typography className={"body-item"}>
                : {tableData?.purpose}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
