import React, { useState, useEffect, useRef } from "react";
import "./loanrequestdetails.scss";
import { Grid, Stack, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import {
  getKhmerDay,
  getKhmerMonth,
  getKhmerNumber,
} from "../../../Function/GetKhmerNumber";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";

export default function EquipmentLoanAgreement({ tableData }) {
  const [loading, setLoading] = useState(false);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log("tableData::", tableData);

  return (
    <>
      <div
        className="print-loan-request-container"
        style={{ marginTop: "30px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box className="logo-container">
              <img src={logoCompany} className="logo-company" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center">
              <Box>
                <div className="body-title-loant-request">
                  ព្រះរាជាណាចក្រកម្ពុជា
                </div>
                <div className="body-title-loant-request">
                  ជាតិ សាសនា ព្រះមហាក្សត្រ
                </div>
                <div className="loan-request-font-tackteng">
                  <img
                    alt="Image"
                    src={wingdingImage}
                    className="loan-request-image-tackteng"
                  />
                </div>
              </Box>
            </Stack>
            <Box
              className="body-title-loant-request"
              sx={{ marginTop: "60px" }}
            >
              កិច្ចសន្យាដាក់ទ្រព្យធានា
            </Box>
            <Box className="body-title-loant-request">រវាង</Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ១. <u className="loan-request-title-bold"> ភាគីទទួលដាក់ធានា៖ </u>
          </Box>
          <Box className="loan-request-title">
            ក្រុមហ៊ុន​
            <span className="loan-request-title-bold">ហោ្គក្រេឌីត</span>
            <span className="loan-request-title-bold">ភី</span>
            <span className="loan-request-title-bold">អិល</span>
            <span className="loan-request-title-bold">ស៊ី</span>
          </Box>

          <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្ន</Box>
          <Box className="loan-request-title">ភូមិថ្មី</Box>
          <Box className="loan-request-title">សង្កាត់ស្វាយដង្គំ</Box>
          <Box className="loan-request-title">ក្រុង</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">សៀមរាប</Box>
          <Box className="loan-request-title">ខេត្តសៀមរាប។ </Box>
          <Box className="loan-request-title">
            អ្នកតំណាងស្របច្បាប់របស់ក្រុមហ៊ុន
          </Box>
          <Box className="loan-request-title">គឺលោក</Box>
          <Box className="loan-request-title-bold">ឡុក ឡងឌី</Box>
          <Box className="loan-request-title">មានតួនាទីជា</Box>
          <Box className="loan-request-title">អគ្គនាយក </Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">ក្រុមហ៊ុន</Box>
          <Box className="loan-request-title">ដែលតទៅហៅថាភាគី</Box>
          <Box className="loan-request-title-bold">“ក”</Box> ។
        </Stack>

        <Box className="loan-equiment-title">និង</Box>

        {/*------------------------------reference 2--------------------------------*/}

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ២. <u className="loan-request-title-bold"> ភាគីដាក់ធានា៖</u>
          </Box>
          <Box className="loan-request-title">ឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.first_name +
              " " +
              tableData?.customer_id?.last_name}
          </Box>
          <Box className="loan-request-title">ភេទ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.gender === "Male"
              ? "ប្រុស"
              : null || tableData?.customer_id?.gender === "Female"
              ? "ស្រី"
              : null}
          </Box>
          <Box className="loan-request-title">សញ្ជាតិ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.nationality}
          </Box>
          <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Stack
              direction="row"
              justifyContent="left"
              sx={{ wordSpacing: "2px" }}
              spacing={1}
            >
              <Box className="loan-request-item">
                {getKhmerNumber(
                  moment(tableData?.customer_id?.date_of_birth).format("DD")
                )}
              </Box>
              <Box className="loan-request-item">
                {getKhmerMonth(
                  moment(tableData?.customer_id?.date_of_birth).format("M")
                )}
              </Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  moment(tableData?.customer_id?.date_of_birth).format("YYYY")
                )}
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={5}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>
              <Box className="loan-request-item" sx={{ letterSpacing: "2px" }}>
                {getKhmerNumber(tableData?.customer_id?.ID_card_number)}
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack
              direction="row"
              justifyContent="right"
              sx={{ wordSpacing: "2px" }}
              spacing={1}
            >
              <Box className="loan-request-title">ចុះថ្ងៃទី</Box>

              <Box className="loan-request-item">
                {getKhmerNumber(moment(tableData?.loan_date).format("DD"))}
              </Box>
              <Box className="loan-request-item">
                {getKhmerMonth(moment(tableData?.loan_date).format("M"))}
              </Box>
              <Box className="loan-request-item">
                {getKhmerNumber(moment(tableData?.loan_date).format("YYYY"))}
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Box className="loan-request-title">និង ឈ្មោះ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id === null
                  ? ""
                  : tableData?.loan_guarantor?.loan_guarantor_id?.first_name +
                    " " +
                    tableData?.loan_guarantor?.loan_guarantor_id?.last_name}
              </Box>
              <Box className="loan-request-title">ភេទ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.gender === "Male"
                  ? "ប្រុស"
                  : null ||
                    tableData?.loan_guarantor?.loan_guarantor_id?.gender ===
                      "Female"
                  ? "ស្រី"
                  : null}
              </Box>

              <Box className="loan-request-title">សញ្ជាតិ</Box>
              <Box className="loan-request-item">
                {tableData?.loan_guarantor?.loan_guarantor_id?.nationality}
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack
              direction="row"
              justifyContent="right"
              sx={{ wordSpacing: "2px" }}
              spacing={1}
            >
              <Box className="loan-request-title">ថ្ងៃខែឆ្នាំកំណើត</Box>

              <Box className="loan-request-item">
                {getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("DD")
                )}
              </Box>
              <Box className="loan-request-item">
                {getKhmerMonth(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("M")
                )}
              </Box>
              <Box className="loan-request-item">
                {getKhmerNumber(
                  moment(
                    tableData?.loan_guarantor?.loan_guarantor_id?.date_of_birth
                  ).format("YYYY")
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Box className="loan-request-title">អត្តសញ្ញាណប័ណ្ណលេខ</Box>

          <Box className="loan-request-item" sx={{ wordSpacing: "2px" }}>
            {getKhmerNumber(
              tableData?.loan_guarantor?.loan_guarantor_id?.ID_card_number
            )}
          </Box>

          <Box className="loan-request-title">ចុះថ្ងៃទី</Box>
          <Stack direction="row" justifyContent="space-between">
            <Box className="loan-request-item">
              {getKhmerNumber(
                moment(tableData?.customer_id?.date).format("DD")
              )}
            </Box>
            <Box className="loan-request-item">
              {getKhmerMonth(moment(tableData?.customer_id?.date).format("M"))}
            </Box>
            <Box className="loan-request-item">
              {getKhmerNumber(
                moment(tableData?.customer_id?.date).format("YYYY")
              )}
            </Box>
          </Stack>

          <Box className="loan-request-title">អាសយដ្ឋានបច្ចុប្បន្នភូមិ</Box>
          <Box className="loan-request-item">
            {
              tableData?.loan_guarantor?.loan_guarantor_id?.current_address
                ?.villege
            }
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ឃុំ/សង្កាត់</Box>
          <Box className="loan-request-item">
            {
              tableData?.loan_guarantor?.loan_guarantor_id?.current_address
                ?.commune
            }
          </Box>
          <Box className="loan-request-title">ស្រុក/ខណ្ឌ</Box>
          <Box className="loan-request-item">
            {
              tableData?.loan_guarantor?.loan_guarantor_id?.current_address
                ?.district
            }
          </Box>
          <Box className="loan-request-title">ខេត្ត</Box>
          <Box className="loan-request-item">
            {
              tableData?.loan_guarantor?.loan_guarantor_id?.current_address
                ?.province
            }
          </Box>
          <Box className="loan-request-title">
            ដែលតទៅនេះហៅថា
            <span className="loan-request-title-bold">ភាគី “ខ” </span>។
          </Box>
        </Stack>

        {/*------------------------------reference end--------------------------------*/}

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title-bold" sx={{ marginLeft: "40px" }}>
            ភាគី “ក”
          </Box>
          <Box className="loan-request-title-bold">និង</Box>
          <Box className="loan-request-title-bold">ភាគី “ខ”</Box>
          <Box className="loan-request-title-bold">
            បានព្រមព្រៀងចុះកិច្ចសន្យាដាក់ធានា
          </Box>
          <Box className="loan-request-title-bold">
            ដោយអនុវត្តតាមរាល់ប្រការដូចខាងក្រោម៖
          </Box>
        </Stack>

        <Box className="loan-request-title-bold">ប្រការ១៖ អំពីលក្ខខណ្ខរួម</Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ១.១. លើមូលដ្ឋានកិច្ចសន្យាខ្ចីចងការប្រាក់ទាំងអស់
          </Box>
          <Box className="loan-request-title">រវាងភាគី</Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">និងភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">គឺ</Box>
          <Box className="loan-request-title-bold"> “ខ”</Box>
          <Box className="loan-request-title">យល់ព្រមដាក់</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ធានានូវអចលនទ្រព្យរបស់</Box>
          <Box className="loan-request-title">
            <span>&#9744;</span>ខ្លួនផ្ទាល់
          </Box>
          <Box className="loan-request-title">ឬ/និង</Box>
          <Box className="loan-request-title">
            <span>&#9744;</span>ធានាជំនួសឲ្យ
          </Box>
          <Box className="loan-request-title">ឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.customer_id?.first_name +
              " " +
              tableData?.customer_id?.last_name}
          </Box>
          <Box className="loan-request-title">និង</Box>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Box className="loan-request-title">ឈ្មោះ</Box>
          <Box className="loan-request-item">
            {tableData?.loan_guarantor?.loan_guarantor_id === null
              ? ""
              : tableData?.loan_guarantor?.loan_guarantor_id?.first_name +
                " " +
                tableData?.loan_guarantor?.loan_guarantor_id?.last_name}
          </Box>
        </Stack>
        <table className="equipment-table ">
          <tr>
            <th>ល.រ</th>
            <th>ប្រភេទប័ណ្ណកម្មសិទ្ធិ</th>
            <th>កាលបរិច្ឆេទ ចេញប័ណ្ណ</th>
            <th>ប្រភេទទ្រព្យ ដាក់ធានា</th>
            <th>ចេញដោយ</th>
            <th>ទំហំ</th>
            <th>ព្រំប្រទល់</th>
          </tr>

          <tr>
            <td>១</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <Box className="loan-request-title">ចលនវត្ថុផ្សេងៗ៖</Box>
        <Box className="loan-request-title">
          ......................................................................
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ១.២. ករណី ភាគី
          </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">
            ផ្ដល់កម្ចីជាបន្តបន្ទាប់ដល់ភាគី
          </Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">នោះភាគីទាំងពីរឯកភាព </Box>
          <Box className="loan-request-title">និងយល់ព្រមឲ្យកិច្ចសន្យាដាក់ </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ធានាមាន</Box>
          <Box className="loan-request-title">
            អានុភាពប្រតិសកម្មលើរាល់សុពលភាពនៃកិច្ចសន្យាខ្ចីចងការប្រាក់ដែលធ្វើឡើងរវាងភាគី
          </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">និង</Box>
          <Box className="loan-request-title">ភាគី</Box>
        </Stack>

        <Box className="loan-request-title-bold">
          ប្រការ២៖ អំពីលក្ខខណ្ឌពិសេស
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ២.១. ភាគី
          </Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">ធានាអះអាងថា</Box>
          <Box className="loan-request-title">ទ្រព្យសម្បត្តិ</Box>
          <Box className="loan-request-title-bold">និងឯកសារដាក់ធានានេះ</Box>
          <Box className="loan-request-title">
            គឺពិតជាកម្មសិទ្ធិស្របច្បាប់ផ្ទាល់ខ្លួន
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">
            ពិតប្រាកដមែនគឹពុំមានពាក់ព័ន្ធនឹងអ្នកដទៃ
          </Box>
          <Box className="loan-request-title">ឬមានចំណែករបស់អ្នកដទៃ</Box>
          <Box className="loan-request-title">
            ឬមានបញ្ហាអ្វីដែលបណ្ដាលឲ្យបាត់បង់សិទ្ធិ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ជាកម្មសិទ្ធិករឡើយ។</Box>
          <Box className="loan-request-title">បើពុំប្រាកដ</Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            នឹងទទួលខុសត្រូវចំពោះមុខច្បាប់ជាធរមាន។
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ២.២. ក្នុងអំឡុងពេលដាក់ធានានេះ
          </Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">មានសិទ្ធិគ្រប់គ្រង</Box>
          <Box className="loan-request-title">ថែរក្សា</Box>
          <Box className="loan-request-title">
            ប្រើប្រាស់ឲ្យបានគង់វង្សនូវទ្រព្យ
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">សម្បត្តិនេះ</Box>
          <Box className="loan-request-title">ប៉ុន្តែមិនបានសិទ្ធិ</Box>
          <Box className="loan-request-title">លក់ </Box>
          <Box className="loan-request-title">ដូរ</Box>
          <Box className="loan-request-title">ផ្លាស់ប្ដូរការគ្រប់គ្រង</Box>
          <Box className="loan-request-title">អច្ឆ័យទាន</Box>
          <Box className="loan-request-title">អំណោយ</Box>
          <Box className="loan-request-title">បញ្ចាំ</Box>
          <Box className="loan-request-title">ផ្ទេរ</Box>
          <Box className="loan-request-title">ឬ</Box>
          <Box className="loan-request-title">ប្រាតិភោគ</Box>
          <Box className="loan-request-title">ឲ្យស្ថាប័ន</Box>
        </Stack>

        <Box className="loan-request-title">ឬតតិយជនផ្សេងទៀតបានឡើយ។</Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ២.៣. ក្នុងករណីដែលគ្មានលទ្ធភាពសងបំណុលតាមកិច្ចសន្យាសងប្រាក់ទេនោះ
          </Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">យល់ព្រមប្រគល់</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទ្រព្យដាក់ធានានេះជូន</Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">តែមួយគត់</Box>
          <Box className="loan-request-title">
            ដើម្បីចាត់ចែងលក់ឡាយឡុងយកប្រាក់សងបំណុលដែលជំពាក់
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទាំងស្រុងរួមទាំងការប្រាក់</Box>
          <Box className="loan-request-title">ប្រាក់ដើម</Box>
          <Box className="loan-request-title">និង</Box>
          <Box className="loan-request-title">ប្រាក់ពិន័យ</Box>
          <Box className="loan-request-title">ផ្សេងៗទៀត</Box>
          <Box className="loan-request-title">( បើមាន ) ។</Box>
          <Box className="loan-request-title">ក្រោយពីការទូទាត់បំណុលជូនភា</Box>
        </Stack>
      </div>
      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "20px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>

      <div className="print-loan-request-container">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: "30px" }}
        >
          <Box className="loan-request-title">គី</Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">ទាំងអស់ហើយ</Box>
          <Box className="loan-request-title">
            ថរិការដែលនៅសល់ត្រូវប្រគល់ជូនភាគី
          </Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">វិញ។</Box>
          <Box className="loan-request-title">ហើយភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">សូមធានាថា</Box>
          <Box className="loan-request-title">ភាគីដទៃ</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទៀតគ្មានសិទ្ធិរារាំងភាគី</Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">
            ក្នុងការលក់ឡាយឡុងទ្រព្យដាក់ធានាដូចមានរៀបរាប់ខាងលើនេះទេ។
          </Box>
          <Box className="loan-request-title">ក្នុងករណី</Box>
          <Box className="loan-request-title">មិន</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ទាន់ទូទាត់សងគ្រប់ចំនួន</Box>
          <Box className="loan-request-title">ភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            ត្រូវបន្តកាតព្វកិច្ចសងបំណុលដែលនៅសល់បន្ថែមទៀតរហូតទាល់តែគ្រប់ចំនួន។
          </Box>
        </Stack>

        <Box className="loan-request-title-bold">ប្រការ៣៖ លក្ខខណ្ឌអវសាន្ត</Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            ភាគី
          </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">និង</Box>
          <Box className="loan-request-title-bold">“ខ”</Box>
          <Box className="loan-request-title">
            សន្យាគោរពយ៉ាងម៉ឺងម៉ាត់តាមរាល់ប្រការនៃកិច្ចសន្យាខាងលើ។
          </Box>
          <Box className="loan-request-title"> ក្នុងករណីមានការអនុវត្ត</Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ផ្ទុយ</Box>
          <Box className="loan-request-title">
            ឬដោយការរំលោភលើលក្ខខណ្ឌណាមួយនៃកិច្ចសន្យា
          </Box>
          <Box className="loan-request-title">
            ភាគីដែលល្មើសត្រូវទទួលខុសត្រូវចំពោះមុខច្បាប់ជាធរមា
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title">ន។</Box>
          <Box className="loan-request-title">
            រាល់សោហ៊ុយចំណាយក្នុងការដោះស្រាយវិវាទជាបន្ទុករបស់
          </Box>
          <Box className="loan-request-title">ភាគីរំលោភបំពានលើកិច្ចសន្យា។</Box>
          <Box className="loan-request-title">កិច្ចសន្យានេះ</Box>
        </Stack>

        <Box className="loan-request-title">
          មានប្រសិទ្ធភាពអនុវត្តចាប់ពីថ្ងៃចុះហត្ថលេខា និងផ្ដិតមេដៃស្ដាំតទៅ ។
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Box className="loan-request-title" sx={{ marginLeft: "40px" }}>
            កិច្ចសន្យានេះធ្វើឡើងជា
          </Box>
          <Box className="loan-request-title">២ច្បាប់</Box>
          <Box className="loan-request-title">( ១ច្បាប់ដើមនៅភាគី </Box>
          <Box className="loan-request-title-bold">“ក”</Box>
          <Box className="loan-request-title">១ច្បាប់ថតចំលងនៅភាគី</Box>
          <Box className="loan-request-title-bold">“ខ”</Box> ) ។
        </Stack>

        <Grid container>
          <Grid item xs={12} mt={5}>
            <Box className="loan-request-title" sx={{ textAlign: "right" }}>
              សៀមរាប ថ្ងៃទី
              <span className="loan-request-item">
                {" "}
                {getKhmerNumber(moment(tableData?.loan_date).format("DD"))}
              </span>{" "}
              ខែ
              <span className="loan-request-item">
                {" "}
                {getKhmerMonth(moment(tableData?.loan_date).format("M"))}
              </span>{" "}
              ឆ្នាំ
              <span className="loan-request-item">
                {getKhmerNumber(moment(tableData?.loan_date).format("YYYY"))}
              </span>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box className="loan-request-title">
                ស្នាមមេដៃស្ដាំភាគីដាក់ធានា
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box className="loan-request-title">តំណាងភាគីទទួលដាក់ធានា</Box>
            </Stack>
          </Grid>

          <Grid item xs={3}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box
                className="loan-request-title-bold"
                sx={{ textAlign: "center" }}
              >
                អ្នករួមធានា
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box
                className="loan-request-title-bold"
                sx={{ textAlign: "center" }}
              >
                អ្នកដាក់ធានា
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box
                className="loan-request-title-bold"
                sx={{ textAlign: "center" }}
              >
                ស្នាមមេដៃសាក្សី
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Box
                className="loan-request-title-bold"
                sx={{ textAlign: "center" }}
              >
                ហត្ថលេខា និង ត្រា
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3} mt={14}>
            <Stack direction="row" justify-content="center" spacing={1}>
              <Box className="loan-request-title">
                ឈ្មោះ...........................
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3} mt={14}>
            <Stack direction="row" justify-content="center" spacing={1}>
              <Box className="loan-request-title">
                ឈ្មោះ...........................
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3} mt={14}>
            <Stack direction="row" justify-content="center" spacing={1}>
              <Box className="loan-request-title">
                ឈ្មោះ...........................
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={3} mt={14}>
            <Stack direction="row" justify-content="center" spacing={1}>
              <Box className="loan-request-title">
                ឈ្មោះ...........................
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </div>

      {/* ==================================================== footer divider ================================================= */}
      <Box
        className="loan-request-divider-footer"
        sx={{ marginTop: "500px" }}
      ></Box>
      <Box className="loan-request-contact-footer" sx={{ marginTop: "4px" }}>
        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem Reap, Cambodia.
      </Box>
      <Box className="loan-request-contact-footer">
        ទូរស័ព្ទៈ 017 875 268, 010 537 694 / E-mail gocredit168@gmail.com
      </Box>
    </>
  );
}
